/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import { ApplicationState } from '/store'
import { setModalSlides } from '/store/layout'

import GalleryArrowBtn from '/components/global/GalleryArrowBtn'
import ModalDialog from '~nanostring-brand-elements/components/modals/ModalDialog'

interface Props {
    className?: string
    slides?: (string | React.FC)[]
    index?: number
}

let _resetTimeoutId: ReturnType<typeof setTimeout> | null = null

const _clearResetTimeout = () => {
    const t = _resetTimeoutId
    _resetTimeoutId = null
    if (t) {
        clearTimeout(t)
    }
}

const ViewLarger: React.FC<Props> = ({
    className,
    slides = [],
    index = 0,
}): React.ReactElement => {
    const [isOpen, setIsOpen] = useState(false)
    const [slideIndex, setSlideIndex] = useState(index)
    const getNextSlide =
        slideIndex + 1 > slides?.length - 1 ? 0 : slideIndex + 1
    const getPreviousSlide =
        slideIndex - 1 < 0 ? slides?.length - 1 : slideIndex - 1
    const dispatch = useDispatch()

    const MediaComponent: React.FC =
        typeof slides[slideIndex] === 'string'
            ? () => (
                  <div
                      sx={{
                          margin: '0 35px',
                      }}
                  >
                      <img
                          alt=''
                          src={slides[slideIndex] as string}
                          sx={{
                              // Includes padding on container below.
                              maxWidth: [
                                  'calc(100vw - 160px)',
                                  null,
                                  null,
                                  'calc(100vw - 240px)',
                              ],
                              maxHeight: [
                                  'calc(90vh - 40px)',
                                  null,
                                  null,
                                  'calc(90vh - 60px)',
                              ],
                          }}
                      />
                  </div>
              )
            : (slides[slideIndex] as React.FC)

    useEffect(() => {
        if (slides?.length > 0) {
            _clearResetTimeout()
            setIsOpen(true)
        }
    }, [slides])

    const onClose = () => {
        setIsOpen(false)
        _clearResetTimeout()
        _resetTimeoutId = setTimeout(() => {
            _resetTimeoutId = null
            dispatch(setModalSlides([]))
        }, 500)
    }

    return (
        <ModalDialog
            id='ViewLarger'
            className={className}
            isOpen={isOpen}
            onClose={onClose}
        >
            <div
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    key={slideIndex}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {MediaComponent && <MediaComponent />}
                </div>
            </div>
            {slides.length > 1 && (
                <div
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        flex: 0,
                        justifyContent: 'center',
                    }}
                >
                    <GalleryArrowBtn
                        pointLeft
                        onClick={() => setSlideIndex(getPreviousSlide)}
                    />

                    <GalleryArrowBtn
                        onClick={() => setSlideIndex(getNextSlide)}
                    />
                </div>
            )}
        </ModalDialog>
    )
}

const mapStateToProps = ({ layout }: ApplicationState) => ({
    slides: layout.modalSlides,
})

export default connect(mapStateToProps)(ViewLarger)
