import React from 'react'
import { Provider } from 'react-redux'
import configureStore from './configureStore'

const initialState =
    typeof window !== 'undefined' ? window.INITIAL_REDUX_STATE : {}
const store = configureStore(initialState)

const ReduxWrapper = ({ children }) => (
    <Provider store={store}>{children}</Provider>
)

export default ReduxWrapper
