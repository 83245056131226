import { Reducer } from 'redux'
import { State, ActionTypes } from './types'
import { steps } from '/content/profile'
import { StepNode, Step } from '/content/profile/types'
import { difference } from 'lodash'

export const initialState: State = {
    stepIndex: -1,
    maxIndex: 0,
    steps,
    selections: [],
    isShowingWorkflow: false,
}

const isOptionEnabled = (selections: string[], option: StepNode): boolean => {
    const { rules } = option

    for (const rulePath of rules) {
        const formattedRules = [...rulePath]
        for (let i = 0; i < selections.length; i++) {
            if (!formattedRules[i]) {
                formattedRules.push(selections[i])
            }
        }
        if (difference(formattedRules, selections).length === 0) {
            return true
        }
    }
    return false
}

const getAvailableOptions = (
    selections: string[],
    options: StepNode[]
): string[] => {
    const availableOptions: string[] = []
    for (const option of options) {
        if (isOptionEnabled(selections, option)) {
            availableOptions.push(option.id)
        }
    }
    return availableOptions
}

const reducer: Reducer<State> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_STEP_INDEX: {
            if (state.stepIndex === 3 && state.isShowingWorkflow) {
                return { ...state, isShowingWorkflow: false }
            }
            const totalSteps = state.steps.length - 1
            let stepIndex = action.payload
            if (stepIndex > totalSteps) stepIndex = totalSteps
            if (stepIndex < -1) stepIndex = -1
            return { ...state, stepIndex, isShowingWorkflow: false }
        }
        case ActionTypes.SET_STEP_AND_MAX_INDEX: {
            const totalSteps = state.steps.length - 1
            let stepIndex = action.payload
            if (stepIndex > totalSteps) stepIndex = totalSteps
            if (stepIndex < -1) stepIndex = -1
            return {
                ...state,
                stepIndex,
                maxIndex: stepIndex + 1,
                isShowingWorkflow: false,
            }
        }
        case ActionTypes.SET_IS_SHOWING_WORKFLOW: {
            return { ...state, isShowingWorkflow: action.payload }
        }
        case ActionTypes.RESET: {
            return {
                ...state,
                stepIndex: -1,
                selections: [],
                maxIndex: 0,
                isShowingWorkflow: false,
            }
        }
        case ActionTypes.SET_STEP_SELECTION: {
            const { index: stepIndex, id } = action.payload
            const { selections, steps } = state
            const newSelections =
                stepIndex >= selections.length - 1 ? [...selections] : []
            for (let i = 0; i < stepIndex; i++) {
                newSelections[i] = selections[i]
            }
            if (id) newSelections[stepIndex] = id
            const newSteps: Step[] = []
            const stepSelections: Record<string, string | null> = {}
            for (let i = 0; i < steps.length; i++) {
                const step = { ...steps[i] }
                const node = i < stepIndex + 1 ? newSelections[i] : null

                step.availableOptions = getAvailableOptions(
                    newSelections,
                    step.options
                )
                newSteps.push(step)
                stepSelections[step.type] = node
            }

            const totalSteps = state.steps.length - 1
            const maxIndex =
                newSelections.length < totalSteps
                    ? newSelections.length
                    : totalSteps
            const stepTwoSelection =
                newSelections.length >= 1 ? newSelections[1] : ''
            localStorage.setItem('stepTwoSelection', stepTwoSelection)
            return {
                ...state,
                selections: newSelections,
                ...stepSelections,
                steps: newSteps,
                maxIndex,
                isShowingWorkflow: false,
            }
        }
        default: {
            return state
        }
    }
}

export { reducer as profileReducer }
