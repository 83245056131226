/** @jsx jsx */
import { jsx, SxStyleProp } from 'theme-ui'
import React, { useContext } from 'react'
import cn from 'classnames'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import type { Page } from '/store/pages/types'

import { Context as APIContext } from '/components/APIProvider'
import {
    analyticsPush,
    eventButtonClick,
} from '~nanostring-brand-elements/components/analytics'
import {
    breakpointMobileWide,
    breakpointTablet,
    useBreakpointBelow,
} from '~nanostring-brand-elements/theme/breakpoints'

const EVENT_ACTIONS = [
    'backReagent',
    'backSample',
    'backRoi',
    'backWorkflow',
    'backResults',
]

interface Props {
    children?: React.ReactNode
    className?: string
    pages: Page[]
    pageIndex: number
    setPageIndex: Function
    maxIndex: number
}

interface ThumbProps {
    className?: string
    onClick?: () => void
    index: number
    isLast: boolean
    disabled?: boolean
    active?: boolean
    visited?: boolean
    label?: string
}

const connectorCSS: SxStyleProp = {
    content: `""`,
    display: 'block',
    borderStyle: 'solid',
    borderWidth: '1px',
    position: 'absolute',
    borderColor: 'muted',
    backgroundColor: 'muted',
}

const connectorCSSVisited: SxStyleProp = {
    borderColor: 'primary',
    backgroundColor: 'primary',
}

const StepThumb: React.FC<ThumbProps> = ({
    className,
    index,
    isLast,
    onClick,
    disabled,
    active,
    visited,
    label,
}): React.ReactElement => {
    const isMobileWide = useBreakpointBelow(breakpointMobileWide)
    const isTablet = useBreakpointBelow(breakpointTablet)
    const isPortrait = useIsPortrait()

    const size = isMobileWide ? 20 : isTablet ? 25 : 35
    const gap = isTablet ? 15 : 20

    return (
        <button
            className={cn(className, { active }, { visited })}
            sx={{
                position: 'relative',
                display: 'inline-block',
                '::before':
                    index > 0
                        ? {
                              ...connectorCSS,
                              width: isPortrait ? gap : 1,
                              height: isPortrait ? 1 : gap,
                              transform: isPortrait
                                  ? `translate(-${gap + 2}px, -1px)`
                                  : `translate(${size / 2 - 3}px, -${
                                        size + 3
                                    }px)`,
                          }
                        : null,
                borderStyle: 'solid',
                borderWidth: '1.5px',
                borderColor: 'muted',
                m: isLast
                    ? 0
                    : isPortrait
                    ? `0 ${gap}px 0 0`
                    : `0 0 ${gap}px 0`,
                width: size,
                height: size,
                backgroundColor: '#fff',
                borderRadius: '50%',
                '&.active': {
                    backgroundColor: 'primary',
                    borderColor: 'var(--theme-ui-colors-primary,#194983)',
                    '&:hover label': { color: 'primary' },
                    '::before': index > 0 ? connectorCSSVisited : null,
                },
                '&.visited': {
                    borderColor: 'var(--theme-ui-colors-primary,#194983)',
                    '&:hover label': { color: 'primary' },
                    '::before': index > 0 ? connectorCSSVisited : null,
                },
                '&:disabled': {
                    cursor: 'inherit',
                    backgroundColor: '#fff',
                },
                '&:hover': { backgroundColor: 'primary' },
                '&:disabled:hover': {
                    backgroundColor: 'muted',
                },
                '&:hover label': {
                    opacity: '1',
                    transform: isPortrait
                        ? 'scale(1, 1) translateX(-40%)'
                        : 'scale(1, 1)',
                },
                transition: 'background-color .3s ease, opacity .3s ease',
            }}
            disabled={disabled}
            onClick={onClick}
        >
            <label
                sx={{
                    opacity: '0',
                    transform: isPortrait
                        ? 'scale(1, 0) translateX(-40%)'
                        : 'scale(0, 1)',
                    transformOrigin: isPortrait ? '50% 50%' : '0% 50%',
                    position: 'absolute',
                    background: 'white',
                    padding: '2px 6px',
                    borderRadius: '4px',
                    transition: 'opacity .3s ease, transform .3s ease',
                    fontSize: '1rem',
                    top: isPortrait ? 40 : 0,
                    left: isPortrait ? 0 : '.2em',
                    margin: isPortrait ? '4px 0 0 0' : '4px 0 0 35px',
                    whiteSpace: 'nowrap',
                    color: 'overlay',
                    textAlign: 'left',
                    zIndex: 2,
                }}
            >
                {label}
            </label>
        </button>
    )
}

const StepThumbs: React.FC<Props> = ({
    className,
    pageIndex,
    setPageIndex,
    pages,
    maxIndex,
}): React.ReactElement => {
    const { userInteract } = useContext(APIContext)
    const isTablet = useBreakpointBelow(breakpointTablet)
    const isPortrait = useIsPortrait()

    return (
        <div className={className}>
            <ul
                sx={{
                    position: isPortrait ? 'static' : 'absolute',
                    top: isPortrait ? undefined : 0,
                    marginTop: isPortrait ? 50 : undefined,
                    left: isPortrait ? undefined : isTablet ? 20 : 40,
                    height: isPortrait ? undefined : '100%',
                    width: isPortrait ? '100%' : undefined,
                    display: 'flex',
                    flexDirection: isPortrait ? 'row' : 'column',
                    justifyContent: 'center',
                    zIndex: '2',
                }}
            >
                {pages.map((page: Page, i: number) => {
                    const active = pageIndex === i
                    const disabled = i > maxIndex
                    const visited = i <= maxIndex
                    return (
                        <li
                            key={`thumb${i}`}
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <StepThumb
                                active={active}
                                index={i}
                                isLast={i === pages.length - 1}
                                disabled={disabled}
                                onClick={() => {
                                    analyticsPush({
                                        eventSection: 'backNavigation',
                                        event: EVENT_ACTIONS[i],
                                        eventValue: eventButtonClick,
                                    })
                                    userInteract(
                                        page.label,
                                        'ProfileBuilderCircleNav'
                                    )
                                    setPageIndex(i)
                                }}
                                visited={visited}
                                label={page.label}
                            />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
export default StepThumbs
