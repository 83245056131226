/** @jsx jsx */
import { jsx } from 'theme-ui'

import * as Yup from 'yup'

import { keyFromId } from '~yipkos-common/utils/key-utils'

import LabeledInput from '~yipkos-common/components/forms/LabeledInput'

export interface ValuesPersonalInfo {
    firstName: string
    lastName: string
    email: string
    company: string
    country: string
    [key: string]: any
}

export interface ValuesOther {
    sendEmail: boolean
    attendeeID?: string | null
    demoName: string
    researchArea?: string
    readoutPlatform?: string
    readoutPlatformTextfield?: string // Note that, on form submission, the value in here gets copied to "readoutPlatform" iff readoutPlatform == "Other".
}

export const initialValuesPersonalInfo = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    country: '',
}
Object.freeze(initialValuesPersonalInfo)

export const initialValuesOther: ValuesOther = {
    sendEmail: true,
    attendeeID: '',
    demoName: 'NANOSTRING_SOFTWARE_DEMO',
    researchArea: '',
    readoutPlatform: '',
    readoutPlatformTextfield: '',
}
Object.freeze(initialValuesOther)

// A few symbols for US-style addresses, and all Unicode letters and digits
// to make sure we can handle foreign names well enough if necessary.  In
// general just expecting US text though, and we really just want to forbid
// emojis.
const plainTextValidator = Yup.string()
    .required('Required')
    .matches(/^[\p{L}\p{N}&'()+,:;./\s-]+$/u)

export const validationSchemaPersonalInfo = {
    firstName: plainTextValidator,
    lastName: plainTextValidator,
    email: Yup.string().email().required('Required'),
    company: plainTextValidator,
    country: plainTextValidator,
}
Object.freeze(validationSchemaPersonalInfo)

export const validationSchemaOther = {
    researchArea: Yup.string().required(),
    readoutPlatform: Yup.string().required(),
}
Object.freeze(validationSchemaOther)

/* eslint-disable react/jsx-key */
export const fieldsPersonalInfo = keyFromId([
    <LabeledInput
        id='firstName'
        label='First Name'
        withLabelColon
        type='text'
        autoComplete='given-name'
        errorMessage='Please enter your first name'
    />,
    <LabeledInput
        id='lastName'
        label='Last Name'
        withLabelColon
        type='text'
        autoComplete='family-name'
        errorMessage='Please enter your last name'
    />,
    <LabeledInput
        id='email'
        label='Email'
        withLabelColon
        type='email'
        autoComplete='email'
        errorMessage='Please enter a valid email'
    />,
    <LabeledInput
        id='company'
        label='Company&#8202;/&#8202;Organization'
        withLabelColon
        type='text'
        autoComplete='off'
        errorMessage='Please enter your affiliation'
    />,
    <LabeledInput
        id='country'
        label='Country'
        withLabelColon
        type='text'
        autoComplete='country'
        errorMessage='Please enter a valid country'
    />,
])
Object.freeze(fieldsPersonalInfo)

// The "Other" field for ReadoutPlatformRadioButtonGroup
export const fieldReadoutPlatformTextfield = (
    <LabeledInput
        id='readoutPlatformTextfield'
        label=''
        type='text'
        autoComplete='off'
        errorMessage='Please enter your requested platform'
    />
)
