import { MotionProps } from 'framer-motion'

const fadeAnimations: MotionProps = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
}

export default fadeAnimations
