import { Step, NodeTypes } from './types'
import { Protein, RNA } from './analytes'
import {
    SlideMountedTissue,
    CoreNeedleBiopsy,
    TissueMicroarray,
    TissueBlockFFPEBank,
    FreshFrozenTissue,
} from './samples'
import {
    GeometricProfiling,
    SegmentProfiling,
    ContourProfiling,
    GriddedProfiling,
    RareCellProfiling,
} from './regions'
import { ProteinWorkflow, RNAWorkflow } from './workflows'
import { ImmunePathways, CTA, HumanIO } from './assays'
import {
    LesinskiCaseStudy,
    RimmCaseStudy,
    CRCGeometricCaseStudy,
    CRCContourCaseStudy,
    CRCSegmentCaseStudy,
} from './caseStudies'

export const Step1: Step = {
    id: 'step1',
    label: 'Choose Reagent',
    type: NodeTypes.ANALYTE,
    options: [RNA, Protein],
    availableOptions: [],
}
export const Step2: Step = {
    id: 'step2',
    label: 'Choose Sample',
    type: NodeTypes.SAMPLE,
    options: [
        SlideMountedTissue,
        TissueMicroarray,
        CoreNeedleBiopsy,
        TissueBlockFFPEBank,
        FreshFrozenTissue,
    ],
    availableOptions: [],
}
export const Step3: Step = {
    id: 'step3',
    label: 'Choose ROI',
    type: NodeTypes.REGION,
    options: [
        GeometricProfiling,
        SegmentProfiling,
        ContourProfiling,
        GriddedProfiling,
        RareCellProfiling,
    ],
    availableOptions: [],
}
export const Step4: Step = {
    id: 'step4',
    label: 'View Workflow',
    type: NodeTypes.WORKFLOW,
    options: [ProteinWorkflow, RNAWorkflow],
    availableOptions: [],
}
export const Step5: Step = {
    id: 'step5',
    label: 'See Results',
    type: NodeTypes.ASSAY,
    options: [ImmunePathways, CTA, HumanIO],
    availableOptions: [],
}
export const Step6: Step = {
    id: 'step6',
    label: 'View Study',
    type: NodeTypes.CASE_STUDY,
    options: [
        LesinskiCaseStudy,
        RimmCaseStudy,
        CRCGeometricCaseStudy,
        CRCContourCaseStudy,
        CRCSegmentCaseStudy,
    ],
    availableOptions: [],
}

const steps = [Step1, Step2, Step3, Step4, Step5, Step6]

export default steps
