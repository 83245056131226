import RNAImg from './media/analytes/rna.svg'
import ProteinImg from './media/analytes/protein.svg'
import { NodeTypes, StepNode } from './types'

export enum AnalyteIds {
    PROTEIN = 'protein',
    RNA = 'rna',
}

export const Protein: StepNode = {
    id: AnalyteIds.PROTEIN,
    type: NodeTypes.ANALYTE,
    label: 'Protein',
    rules: [],
    media: ProteinImg,
}

export const RNA: StepNode = {
    id: AnalyteIds.RNA,
    type: NodeTypes.ANALYTE,
    label: 'RNA',
    rules: [],
    media: RNAImg,
}

export const allAnalytes: Map<string, StepNode> = new Map([
    [AnalyteIds.RNA, RNA],
    [AnalyteIds.PROTEIN, Protein],
])
