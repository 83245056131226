/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment, useEffect } from 'react'
import type { ReactNode } from 'react'

import DialogBase from '~yipkos-common/components/navigation/DialogBase'
import { valueToDimension } from '~yipkos-common/utils/css-utils'
import { useScreenshotMode } from '~yipkos-common/utils/screenshotMode'

import CloseBtn from '../CloseBtn'
import NSTGDialogBackdrop from './NSTGDialogBackdrop'

import { zIndexModalBackdrop, zIndexModalDialog } from '../../theme/z-indexes'

const outerPadding = [40, 50]
const outerPaddingPx = outerPadding.map(valueToDimension)

export interface StateProps {
    isOpen: boolean
    onClose?: () => void
}

export interface Props extends StateProps {
    id: string
    stackCount?: number
    scrollId?: string
    className?: string
    title?: string | ReactNode
    children?: ReactNode
}

const ModalDialog = (props: Props) => {
    const {
        id,
        title,
        isOpen,
        stackCount = 0,
        onClose,
        children,
        ...rest
    } = props
    const isScreenshotMode = useScreenshotMode()

    const stackZOffset = stackCount * zIndexModalDialog

    useEffect(() => {
        if (isOpen) {
            const el = document.getElementById(id)
            if (el) {
                if (isScreenshotMode) {
                    el.style.setProperty('position', 'absolute', 'important')
                    el.style.setProperty(
                        'top',
                        `${window.scrollY}px`,
                        'important'
                    )
                    el.style.setProperty('bottom', 'unset', 'important')
                } else {
                    el.style.removeProperty('position')
                    el.style.removeProperty('top')
                    el.style.removeProperty('bottom')
                }
            }
        }
    }, [isOpen, isScreenshotMode])

    return (
        <Fragment>
            <NSTGDialogBackdrop
                zIndex={stackZOffset + zIndexModalBackdrop}
                isOpen={isOpen}
                onClick={onClose}
            />
            <DialogBase
                {...rest}
                id={id}
                isOpen={isOpen}
                onClose={onClose}
                sx={{
                    position: 'fixed',
                    left: '50%',
                    top: '50%',

                    overflow: 'auto',
                    ['WebkitOverflowScrolling' as any]: 'touch',

                    transition:
                        'opacity 300ms ease-in, transform 300ms ease-in',

                    backgroundColor: 'white',
                    borderRadius: '10px',

                    padding: outerPaddingPx,

                    '& h1.title': {
                        margin: '-5px 50px 43px 20px',
                        lineHeight: 1,
                    },
                    opacity: isOpen ? 1 : 0,
                    transform: isOpen
                        ? 'translate(-50%, -50%) scale(1)'
                        : 'translate(-50%, -50%) scale(0)',
                    zIndex: stackZOffset + zIndexModalDialog,
                }}
            >
                {onClose && (
                    <CloseBtn
                        sx={{
                            position: 'absolute',
                            right: [20, 30],
                            top: [20, 30],
                            zIndex: 100,
                        }}
                        onClick={onClose}
                    />
                )}
                {title && (
                    <Fragment>
                        <h1 className='title'>{title}</h1>
                    </Fragment>
                )}
                {children}
            </DialogBase>
        </Fragment>
    )
}
export default ModalDialog

export const ModalDialogInnerScroll = (props: Props) => {
    const { id, children, ...rest } = props

    const scrollId = `${id}-scroller`

    return (
        <ModalDialog
            {...rest}
            id={id}
            scrollId={scrollId}
            sx={{
                overflow: 'hidden',
            }}
        >
            <div
                id={scrollId}
                className='permanentScrollbars'
                sx={{
                    flex: '1 1 auto',
                    overflowY: 'auto',
                    ['WebkitOverflowScrolling' as any]: 'touch',
                    marginRight: -15,
                    paddingRight: 20,
                }}
            >
                {children}
            </div>
        </ModalDialog>
    )
}
