import Wta from '/components/profile/ProfileResults/Wta'

import { StepNode, NodeTypes } from './types'
import { AnalyteIds } from './analytes'

import cta from './media/assays/cta.png'
import humanio from './media/assays/humanio.png'
import immunePathways from './media/assays/ImmunePathways.png'

export enum AssayIds {
    IMMUNE = 'immune-pathways',
    CTA = 'cta',
    HUMAN_IO = 'human-io',
    WTA = 'wta',
}

export const ImmunePathways: StepNode = {
    id: AssayIds.IMMUNE,
    type: NodeTypes.ASSAY,
    label: 'Immune Pathways',
    rules: [[AnalyteIds.RNA]],
    media: immunePathways,
    eventAction: 'profileImmunePathways',
}

export const CTA: StepNode = {
    id: AssayIds.CTA,
    type: NodeTypes.ASSAY,
    label: 'CTA',
    rules: [[AnalyteIds.RNA]],
    media: cta,
    eventAction: 'profileCta',
}

export const WTA: StepNode = {
    id: AssayIds.WTA,
    type: NodeTypes.ASSAY,
    label: 'WTA',
    rules: [[AnalyteIds.RNA]],
    media: Wta,
    eventAction: 'profileWta',
}

export const HumanIO: StepNode = {
    id: AssayIds.HUMAN_IO,
    type: NodeTypes.ASSAY,
    label: 'Human IO',
    rules: [[AnalyteIds.PROTEIN]],
    media: humanio,
}

export const allAssays: Map<string, StepNode> = new Map([
    // Order matters, the tabs on the results screen are in this order.
    [AssayIds.HUMAN_IO, HumanIO],
    [AssayIds.WTA, WTA],
    [AssayIds.IMMUNE, ImmunePathways],
    [AssayIds.CTA, CTA],
])
