import { Reducer } from 'redux'
import { LayoutState, LayoutActionTypes } from './types'

export const initialState: LayoutState = {
    modalSlides: [],
}

const reducer: Reducer<LayoutState> = (state = initialState, action) => {
    switch (action.type) {
        case LayoutActionTypes.SET_MODAL_SLIDES: {
            let modalSlides = action.payload || []
            if (typeof action.payload === 'string')
                modalSlides = [action.payload]
            return { ...state, modalSlides }
        }
        default: {
            return state
        }
    }
}

export { reducer as layoutReducer }
