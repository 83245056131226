import React, { ReactNode } from 'react'
import { useHistory } from 'react-router'

interface Props {
    className?: string
    href: string
    target?: string
    onClick?: () => void // Called *in addition to* obeying this.to
    children?: ReactNode
}

const NavLink = (props: Props) => {
    const { className, href, target, onClick, children } = props
    const history = useHistory()

    return (
        <a
            className={className}
            href={href}
            target={target}
            onClick={(ev) => {
                ev.preventDefault()
                ev.stopPropagation()
                if (onClick) {
                    onClick()
                }
                history.push(href)
            }}
        >
            {children}
        </a>
    )
}

export default NavLink
