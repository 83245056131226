/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Route, Redirect, Switch } from 'react-router-dom'

import AnalyzeData from '/components/analyze/AnalyzeData/AnalyzeData'

import ExploreBiomarkerDiscoveryPage from '/components/explore/ExploreBiomarkerDiscoveryPage'
import ExploreData from '/components/explore/ExploreData'
import ExplorePathwayAnalysisPage from '/components/explore/ExplorePathwayAnalysisPage'
import ExploreSpatialCellTypingPage from '/components/explore/ExploreSpatialCellTypingPage'
import ExploreSpatialProfilingPage from '/components/explore/ExploreSpatialProfilingPage'

import { ChooseAssayContent } from '/components/profile/ChooseAssay/ChooseAssay'
import ProfileBuilder from '/components/profile/ProfileBuilder'
import {
    CurrentStep,
    ProfileBuilderFrame,
} from '/components/profile/ProfileBuilder/ProfileBuilder'
import { ProfileResultsContent } from '/components/profile/ProfileResults/ProfileResults'
import { WorkflowContent } from '/components/profile/Workflow/Workflow'
import { WorkflowROIContent } from '/components/profile/Workflow/WorkflowROI'

import SubpageLayout from '/components/global/SubpageLayout'
import ScheduleConsultForm from '/components/schedule/ScheduleConsultForm/ScheduleConsultForm'

import { steps } from '/content/profile'
import { RegionIds } from '/content/profile/regions'
import { SampleIds } from '/content/profile/samples'
import { AnalyteIds } from '/content/profile/analytes'

const Routes = () => {
    return (
        <Switch>
            <Route exact path='/'>
                <Redirect to='/profile' />
            </Route>
            <Route exact path='/profile' component={ProfileBuilder} />
            <Route
                exact
                path='/profile/:id(.*-step2)'
                component={Step2TestRender}
            />
            <Route
                exact
                path='/profile/:id(.*-case-study)'
                component={CaseStudyTestRender}
            />
            <Route
                exact
                path='/profile/:id(.*-profiling)'
                component={WorkflowROITestRender}
            />
            <Route
                exact
                path='/profile/:id(.*)-results'
                component={ChooseAssayTestRender}
            />
            <Route
                exact
                path='/profile/:id(.*)-workflow'
                component={WorkflowTestRender}
            />
            <Route path='/explore'>
                <Switch>
                    <Route exact path='/explore'>
                        <SubpageLayout>
                            <ExploreData />
                        </SubpageLayout>
                    </Route>
                    <Route path='/explore/*'>
                        <SubpageLayout hasBackButton>
                            <Switch>
                                <Route
                                    exact
                                    path='/explore/biomarker-discovery'
                                    component={ExploreBiomarkerDiscoveryPage}
                                />
                                <Route
                                    exact
                                    path='/explore/pathway-analysis'
                                    component={ExplorePathwayAnalysisPage}
                                />
                                <Route
                                    exact
                                    path='/explore/spatial-profiling'
                                    component={ExploreSpatialProfilingPage}
                                />
                                <Route
                                    exact
                                    path='/explore/spatial-typing'
                                    component={ExploreSpatialCellTypingPage}
                                />
                                <Route path='/'>
                                    <Redirect to='/explore' />
                                </Route>
                            </Switch>
                        </SubpageLayout>
                    </Route>
                    <Route path='/'>
                        <Redirect to='/explore' />
                    </Route>
                </Switch>
            </Route>
            <Route exact path='/analyze'>
                <SubpageLayout>
                    <AnalyzeData />
                </SubpageLayout>
            </Route>
            <Route exact path='/demo'>
                <SubpageLayout>
                    <ScheduleConsultForm />
                </SubpageLayout>
            </Route>
            <Route path='/'>
                <Redirect to='/profile' />
            </Route>
        </Switch>
    )
}
export default Routes

const Step2TestRender = (props) => {
    const id = props.match?.params?.id
    if (!id) {
        return null
    }
    return (
        <ProfileBuilderTestFrame stepIndex={2} selections={[id]}>
            <CurrentStep steps={steps} stepIndex={2} />
        </ProfileBuilderTestFrame>
    )
}

// Only used for development ease.
const WorkflowROITestRender = (props) => {
    const id = props.match?.params?.id
    if (!id) {
        return null
    }

    const selections = [AnalyteIds.RNA, SampleIds.SMT, id]

    return (
        <ProfileBuilderTestFrame stepIndex={3} selections={selections}>
            <WorkflowROIContent roiId={id} />
        </ProfileBuilderTestFrame>
    )
}

// Only used for development ease.
const WorkflowTestRender = (props) => {
    const id = props.match?.params?.id
    if (!id) {
        return null
    }

    const selections = [id, SampleIds.SMT, RegionIds.GEO]
    console.log(id)

    return (
        <ProfileBuilderTestFrame
            stepIndex={3}
            selections={selections}
            isShowingWorkflow
        >
            <WorkflowContent workflowId={`${id}-workflow`} />
        </ProfileBuilderTestFrame>
    )
}

// Only used for development ease.
const ChooseAssayTestRender = (props) => {
    const id = props.match?.params?.id
    if (!id) {
        return null
    }

    const selections = [id, SampleIds.SMT, RegionIds.GEO]

    return (
        <ProfileBuilderTestFrame stepIndex={4} selections={selections}>
            <ChooseAssayContent
                selections={selections}
                onClickViewCaseStudy={() => {}}
            />
        </ProfileBuilderTestFrame>
    )
}

// Only used for development ease.
const CaseStudyTestRender = (props) => {
    const id = props.match?.params?.id
    if (!id) {
        return null
    }

    const selections = [AnalyteIds.RNA, SampleIds.SMT, RegionIds.GEO]

    return (
        <ProfileBuilderTestFrame stepIndex={5} selections={selections}>
            <ProfileResultsContent caseStudyId={id} />
        </ProfileBuilderTestFrame>
    )
}

const ProfileBuilderTestFrame = (props) => {
    return (
        <ProfileBuilderFrame
            {...props}
            setPage={() => {}}
            maxIndex={props.stepIndex}
        />
    )
}
