/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'
import { motion } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import fadeAnimations from '~yipkos-common/animations/fade'
import ZoomableImage from '/components/global/ZoomableImage'

import pathwayAnalysisBottom from './assets/pathway-analysis-bottom.png'
import pathwayAnalysisTop from './assets/pathway-analysis-top.png'

const roi = '/images/pathway-analysis-roi.png'
const tissueSegmentation = '/images/pathway-analysis-tissue-segmentation.png'

const ExplorePathwayAnalysisPage = () => {
    const isPortrait = useIsPortrait()
    return isPortrait ? (
        <ExplorePathwayAnalysisPagePortrait />
    ) : (
        <ExplorePathwayAnalysisPageLandscape />
    )
}

const ExplorePathwayAnalysisPageLandscape = () => {
    return (
        <Fragment>
            <motion.div
                className='f-col-c-100'
                sx={{
                    textAlign: 'center',
                }}
                {...fadeAnimations}
            >
                <div>
                    <motion.h2 {...fadeAnimations}>
                        Pathway Analysis Application
                    </motion.h2>
                    <motion.h4 {...fadeAnimations}>
                        Spatial Mapping of Pathway Activation and Signaling
                    </motion.h4>
                </div>
            </motion.div>
            <motion.div
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '2em',
                    justifyContent: 'center',
                    '& label': {
                        margin: '0',
                        display: 'block',
                        width: '100%',
                        textAlign: 'center',
                    },
                }}
                {...fadeAnimations}
            >
                <div sx={{ width: '40%' }}>
                    <label
                        sx={{
                            fontSize: ['0.9rem', '1rem', '1.4rem'],
                            fontWeight: 600,
                            letterSpacing: '0.05em',
                            padding: '0 4rem',
                        }}
                    >
                        Pathway Signaling within Tissue Compartments
                    </label>
                    <div
                        className='f-col-c'
                        sx={{
                            fontWeight: 500,
                            marginBottom: '2ex',
                        }}
                    >
                        <ZoomableImage
                            preview={roi}
                            src={roi}
                            sx={{
                                width: '30%',
                                img: {
                                    marginTop: '2ex',
                                },
                            }}
                        />
                        <label>Region of Interest</label>
                    </div>
                    <ZoomableImage
                        preview={tissueSegmentation}
                        src={tissueSegmentation}
                        sx={{ width: '100%', height: '52%' }}
                    />
                </div>
                <div
                    sx={{
                        width: '60%',
                    }}
                >
                    <label
                        sx={{
                            fontSize: ['0.9rem', '1rem', '1.4rem'],
                            fontWeight: 600,
                            letterSpacing: '0.05em',
                            paddingLeft: '2.5rem',
                            paddingRight: '2.5rem',
                            paddingBottom: '2ex',
                        }}
                    >
                        Explore pathway signaling across complex tissue
                        structures and tumor boundaries
                    </label>

                    <PathwayAnalysisBottom />
                </div>
            </motion.div>
        </Fragment>
    )
}
const ExplorePathwayAnalysisPagePortrait = () => {
    return (
        <Fragment>
            <motion.div
                className='f-col-c-100'
                sx={{
                    textAlign: 'center',
                }}
                {...fadeAnimations}
            >
                <div>
                    <motion.h2
                        sx={{
                            fontSize: '2.8rem',
                            marginTop: '5vh',
                        }}
                        {...fadeAnimations}
                    >
                        Pathway Analysis Application
                    </motion.h2>
                    <motion.h4
                        sx={{
                            fontSize: '2.25rem',
                            paddingTop: '1.3vh',
                        }}
                        {...fadeAnimations}
                    >
                        Spatial Mapping of Pathway Activation and Signaling
                    </motion.h4>
                </div>
            </motion.div>
            <motion.div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 0,
                    justifyContent: 'center',
                    '& label': {
                        margin: '0',
                        display: 'block',
                        width: '100%',
                        textAlign: 'center',
                    },
                }}
                {...fadeAnimations}
            >
                <div sx={{ width: '100%' }}>
                    <label
                        sx={{
                            fontSize: ['0.9rem', '1rem', '1.5rem'],
                            fontWeight: 700,
                            letterSpacing: '0.05em',
                            padding: '4rem 4rem 3rem 4rem',
                        }}
                    >
                        Pathway Signaling within Tissue Compartments
                    </label>
                    <div
                        className='f-col-c'
                        sx={{
                            fontWeight: 500,
                            marginBottom: '2ex',
                        }}
                    >
                        <PathwayAnalysisTop />
                        <label
                            sx={{
                                fontSize: ['.9rem', '1rem', '1.5rem'],
                                fontWeight: 700,
                                letterSpacing: '0.05em',
                                padding: ' 4rem 5.5rem 2rem 5.5rem',
                            }}
                        >
                            Explore pathway signaling across complex tissue
                            structures and tumor boundaries
                        </label>

                        <PathwayAnalysisBottom />
                    </div>
                </div>
            </motion.div>
        </Fragment>
    )
}

const PathwayAnalysisTop = () => {
    return (
        <ZoomableImage
            preview={pathwayAnalysisTop}
            src={pathwayAnalysisTop}
            sx={{
                width: '100%',
                height: '50%',
            }}
        />
    )
}

const PathwayAnalysisBottom = () => {
    const isPortrait = useIsPortrait()
    return (
        <ZoomableImage
            preview={pathwayAnalysisBottom}
            src={pathwayAnalysisBottom}
            sx={{
                width: isPortrait ? '90%' : '100%',
                height: '50%',
            }}
        />
    )
}

export default ExplorePathwayAnalysisPage
