/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { analyticsPush } from '~nanostring-brand-elements/components/analytics'

import { CaseStudyProps } from '/content/profile/types'

import { useSubpageLayout } from '/components/global/SubpageLayoutContext'
import ZoomableImage from '/components/global/ZoomableImage'

import CaseStudySlider from './CaseStudySlider'

import img1 from './rimm-detail1-2.jpg'
import img2 from './rimm-detail3.jpg'
import img3 from './rimm-detail4-full.jpg'
import img4 from './rimm-detail5-full.jpg'
import img6 from './rimm-detail6.jpg'
import img7 from './rimm-detail7.jpg'

const Page1 = () => {
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <h3 sx={{ color: 'black', m: '0 1rem 1ex 1rem' }}>
                Identification of Predictive Biomarkers in Melanoma
            </h3>
            <div
                sx={{
                    display: 'flex',
                    flexDirection: isPortrait ? 'column-reverse' : 'row',
                    alignItems: isPortrait ? 'center' : 'flex-start',
                    height: 'calc(100% - 2rem)',
                    overflow: 'auto',
                }}
            >
                <div
                    sx={{
                        mx: '1rem',
                        flex: '1 1 40%',
                        height: '100%',
                    }}
                >
                    <ZoomableImage
                        preview={img1}
                        src={img1}
                        sx={{
                            p: '0',
                            img: {
                                m: '0 1rem 0 auto',
                            },
                        }}
                    />
                </div>
                <div
                    sx={{
                        maxWidth: '90%',
                        margin: '0 auto',
                        paddingTop: '1rem',
                        flex: '1 1 60%',
                        h4: {
                            textAlign: 'left',
                        },
                    }}
                >
                    <h4
                        sx={{
                            paddingTop: '0.3rem',
                        }}
                    >
                        David Rimm, MD, PhD
                    </h4>
                    <h4
                        sx={{
                            fontSize: '0.95em',
                        }}
                    >
                        Director of Pathology Tissue Services
                    </h4>
                    <h4
                        sx={{
                            fontSize: '0.95em',
                        }}
                    >
                        Director of Translational Pathology
                    </h4>
                    <h4
                        sx={{
                            fontSize: '0.95em',
                        }}
                    >
                        Yale University
                    </h4>
                    <p
                        sx={{
                            textAlign: 'left',
                            paddingTop: '0.3rem',
                            color: 'black',
                        }}
                    >
                        Using tissue microarrays from pre-treatment biopsies,
                        DSP reveals novel predictive biomarkers throughout
                        various tissue segments
                    </p>
                </div>
            </div>
        </motion.div>
    )
}

const Page2 = () => {
    const { contentHeight } = useSubpageLayout()
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            {...fadeAnimations}
        >
            <div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    gap: isPortrait ? 40 : undefined,
                }}
            >
                <div>
                    <h3 sx={{ color: 'black', textAlign: 'center' }}>
                        Mask strategy for each TMA core
                    </h3>
                    <br />
                    <h4 sx={{ color: 'black', fontWeight: '300' }}>
                        Using tissue microarrays from pre-treatment biopsies,
                        DSP segments each core into distinct segments.
                    </h4>
                    <br />
                    <p>
                        <strong>Serial masks for each TMA core:</strong>
                    </p>
                    <ol
                        sx={{
                            listStyle: 'decimal',
                            lineHeight: '1rem',
                            paddingLeft: '3rem',
                            fontSize: '1rem',
                        }}
                    >
                        <li>CD68 (macrophages)</li>
                        <li>CD45 (all lymphocytes except macrophages)</li>
                        <li>S100B+PMEL17 (melanocytes)</li>
                        <li>
                            DNA (non-tumor&thinsp;/&thinsp;non-lymphocyte cells)
                            - remaining material from patient
                        </li>
                    </ol>
                </div>
                <div
                    sx={{
                        img: {
                            width: 'auto',
                            height: 'auto',
                            maxHeight: isPortrait
                                ? undefined
                                : `calc(${contentHeight}px - 360px - 1rem)`,
                            maxWidth: '100%',
                            margin: '0 auto',
                        },
                        maxHeight: isPortrait
                            ? undefined
                            : `calc(${contentHeight}px - 360px)`,
                        flex: isPortrait
                            ? undefined
                            : `1 0 calc(${contentHeight}px - 360px)`,
                    }}
                >
                    <ZoomableImage preview={img2} src={img2} />
                </div>
            </div>
        </motion.div>
    )
}

const Page3 = () => {
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <h3 sx={{ color: 'black', textAlign: 'center' }}>
                Differential Expression is Highest Between Tumor and Stroma
            </h3>
            <div
                sx={{
                    display: 'flex',
                    flexDirection: isPortrait ? 'column' : 'row',
                    paddingTop: '0.5rem',
                    height: '100%',
                    gap: 20,
                }}
            >
                <div
                    sx={{
                        flex: isPortrait ? undefined : 1,
                    }}
                >
                    <ul
                        sx={{
                            listStyleType: 'none',
                        }}
                    >
                        <li>
                            <span
                                sx={{
                                    color: 'secondary',
                                    fontSize: '1.5em',
                                }}
                            >
                                •
                            </span>{' '}
                            Largest fold changes differences observed between
                            tumor and stromal compartment
                        </li>
                        <li sx={{ marginTop: '1.5rem' }}>
                            <span
                                sx={{
                                    color: 'secondary',
                                    fontSize: '1.5em',
                                }}
                            >
                                •
                            </span>{' '}
                            Lineage associated markers were most differential
                            between CD68 and CD45 compartments
                        </li>
                    </ul>
                </div>

                <div
                    sx={{
                        flex: isPortrait ? undefined : 2,

                        img: { width: '100%' },
                    }}
                >
                    <ZoomableImage
                        preview={img3}
                        src={img3}
                        sx={{
                            p: 0,
                        }}
                    />
                </div>
            </div>
        </motion.div>
    )
}

const Page4 = () => {
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <h3 sx={{ color: 'black', textAlign: 'center' }}>
                Tumor Compartments Cluster Separately Under Unsupervised
                Analysis
            </h3>
            <div
                sx={{
                    display: 'flex',
                    flexDirection: isPortrait ? 'column' : 'row',
                    paddingTop: '2rem',
                    height: 'calc(100% - 3rem)',
                    gap: 20,
                }}
            >
                <div
                    sx={{
                        flex: isPortrait ? undefined : 1,
                    }}
                >
                    <ul
                        sx={{
                            listStyleType: 'none',
                        }}
                    >
                        <li>
                            <span
                                sx={{
                                    color: 'secondary',
                                }}
                            >
                                •
                            </span>{' '}
                            Analyzing all compartments together clusters are
                            driven by tumor vs stroma
                        </li>
                        <li sx={{ marginTop: '1.5rem' }}>
                            <span
                                sx={{
                                    color: 'secondary',
                                }}
                            >
                                •
                            </span>{' '}
                            &lsquo;Hot&rsquo; CD68 and CD45 compartments with
                            higher immune maker expression tend to be from
                            patients with CR/PR
                        </li>
                    </ul>
                </div>

                <div
                    sx={{
                        flex: isPortrait ? undefined : 2,
                        height: '100%',
                        img: { width: '100%' },
                    }}
                >
                    <ZoomableImage preview={img4} src={img4} />
                </div>
            </div>
        </motion.div>
    )
}

const Page5 = () => {
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            className='f-col'
            sx={{
                position: 'relative',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <h3 sx={{ color: 'black', textAlign: 'center' }}>
                Patient Outcome Associated with Compartment Specific Expression
                Profile
            </h3>

            <div
                className='f-col'
                sx={{
                    justifyContent: 'center',
                    height: '100%',
                    gap: isPortrait ? 40 : undefined,
                }}
            >
                <p
                    sx={{
                        textAlign: 'center',
                        color: 'black',
                    }}
                >
                    Several probes, primarily in the stromal compartments, are
                    associated with patient outcome
                </p>

                <div
                    className='f-row'
                    sx={{
                        mt: '0.5ex',
                    }}
                >
                    <div
                        className='f-col'
                        sx={{
                            flex: '1 1 55%',
                            textAlign: 'center',
                        }}
                    >
                        <h5>Multivariate Analysis</h5>
                        <div
                            sx={{
                                m: '0 8px',
                                img: { width: '100%' },
                            }}
                        >
                            <ZoomableImage preview={img6} src={img6} />
                        </div>
                        <ul
                            sx={{
                                listStyleType: 'none',
                                textAlign: 'left',
                                fontSize: '0.85em',
                                lineHeight: '1.1',
                            }}
                        >
                            <li>
                                <span
                                    sx={{
                                        color: 'secondary',
                                    }}
                                >
                                    •
                                </span>{' '}
                                PDL1 showed strongest association with OS in the
                                CD68 compartment
                            </li>
                            <li>
                                <span
                                    sx={{
                                        color: 'secondary',
                                    }}
                                >
                                    •
                                </span>{' '}
                                B2M in CD45 compartment was associated with both
                                PFS and OS as well
                            </li>
                            <li>
                                <span
                                    sx={{
                                        color: 'secondary',
                                    }}
                                >
                                    •
                                </span>{' '}
                                Results are independent of prior treatment
                            </li>
                        </ul>
                    </div>
                    <div
                        sx={{
                            flex: '1 1 45%',
                            img: { width: '100%' },
                        }}
                    >
                        <ZoomableImage
                            preview={img7}
                            src={img7}
                            sx={{
                                p: 0,
                            }}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

const CaseStudy: React.FC<CaseStudyProps> = ({
    className,
}): React.ReactElement => {
    useEffect(() => {
        analyticsPush({
            eventSection: 'caseStudyViewed',
            event: 'predictiveBiomarkers',
            eventValue: 1,
        })
    }, [])
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (pageIndex) => {
            analyticsPush({
                eventSection: 'caseStudyViewed',
                event: 'predictiveBiomarkers',
                eventValue: pageIndex + 1,
            })

            if (pageIndex === 4) {
                analyticsPush({
                    eventSection: 'caseStudyViewed',
                    event: 'predictiveBiomarkers',
                    eventValue: 'completed',
                })
            }
        },
    }

    return (
        <CaseStudySlider
            className={clsx(className, 'rimm_case')}
            settings={settings}
            title='Endpoint: Predictive Biomarkers'
        >
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 />
            <Page5 />
        </CaseStudySlider>
    )
}

export default CaseStudy
