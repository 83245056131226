/** @jsx jsx */
import { jsx } from 'theme-ui'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { StepNodeComponent } from '../../types'

import WorkflowLandscapeSVG from './workflow-landscape.inline-svg'
import WorkflowPortraitSVG from './workflow-portrait.inline-svg'

interface Props extends StepNodeComponent {
    analyte: string
}

const WorkflowAnimation = (props: Props) => {
    const { className, analyte } = props
    const isPortrait = useIsPortrait()

    const WorkflowSVG = isPortrait ? WorkflowPortraitSVG : WorkflowLandscapeSVG

    const onMount = (svgEl) => {
        if (!svgEl) {
            return
        }

        const delays = [null, 1000, 750, 1500, 2000, 2000]
        let delay = 500

        const fadeIn = (id, translate) => {
            const el = document.getElementById(id)
            if (!el) {
                return
            }
            if (translate) {
                el.style.transform = isPortrait
                    ? 'translateY(200px)'
                    : 'translateX(200px)'
                el.style.transition = 'all 600ms ease-out'
            }
            setTimeout(() => {
                el.style.opacity = '1'
                el.style.transform = 'none'
            }, delay)
        }

        const grow = (id, isVertical?: boolean) => {
            const el = document.getElementById(id)
            if (!el) {
                return
            }
            const rects = el.getElementsByTagName('rect')
            for (let i = 0; i < rects.length; i++) {
                const rect = rects[i]
                const x = rect.x.baseVal.valueAsString
                const y = rect.y.baseVal.valueAsString
                rect.style.transformOrigin = `${x}px ${y}px`
                rect.style.transform = isVertical
                    ? 'scaleY(0.01)'
                    : 'scaleX(0.01)'
                rect.style.transition = 'transform 300ms ease-in'
            }
            setTimeout(() => {
                const rects = el.getElementsByTagName('rect')
                for (let i = 0; i < rects.length; i++) {
                    const rect = rects[i]
                    rect.style.transform = 'none'
                }
            }, delay)
        }

        for (const step of [1, 2, 3, 4, 5]) {
            fadeIn(`workflow-lbl-${step}`, step !== 5)
            fadeIn(`workflow-num-${step}`, step !== 5)
            fadeIn(`workflow-img-${step}`, step !== 5)
            fadeIn(`workflow-img-${step}-${analyte}`, step !== 5)
            if (isPortrait) {
                if (step === 5) {
                    fadeIn(`workflow-line-${step}`, false)
                } else if (step === 2) {
                    grow(`workflow-line-${step}`, true)
                    delay += 500
                    grow(`workflow-line-${step}-b`, true)
                } else {
                    grow(`workflow-line-${step}`, true)
                }
            } else {
                if (step === 5) {
                    grow(`workflow-line-${step}`, true)
                    delay += 300
                    fadeIn(`workflow-line-${step}-b`, false)
                } else {
                    grow(`workflow-line-${step}`)
                }
            }
            delay += delays[step] || 0
            if (step === 2) {
                fadeIn('workflow-roi-callout', false)
                fadeIn('workflow-img-2-callout', false)
                delay += delays[step] || 0
            }
        }
    }

    return (
        <div
            ref={onMount}
            sx={{ width: 'calc(100% - 60px)', height: 'calc(100% - 4rem)' }}
        >
            <WorkflowSVG
                sx={{ width: '100%', height: '100%' }}
                className={className}
            />
        </div>
    )
}

export default WorkflowAnimation

export const RNAWorkflowGfx = (props) => (
    <WorkflowAnimation analyte='rna' {...props} />
)

export const ProteinWorkflowGfx = (props) => (
    <WorkflowAnimation analyte='protein' {...props} />
)
