/** @jsx jsx */
import { jsx } from 'theme-ui'

import ROIIcon from './ROIIcon'

const ROIHeader = (props) => {
    const { roi } = props

    return (
        <div className='f-row-center'>
            <ROIIcon roi={roi} />
            <div className='f-col'>
                <h2>Build Your GeoMx Profile</h2>
                <h4>
                    Region of Interest:{' '}
                    <span className='highlighted'>{roi.label}</span>
                </h4>
            </div>
        </div>
    )
}
export default ROIHeader
