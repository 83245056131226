import { StepNode, NodeTypes } from './types'
import { AnalyteIds } from './analytes'
import { SampleIds } from './samples'
import { RegionIds } from './regions'

import CRCGeometric from './media/case-studies/CRCGeometric'
import CRCSegment from './media/case-studies/CRCSegment'
import CRCContour from './media/case-studies/CRCContour'
import Lesinski from './media/case-studies/Lesinski'
import Rimm from './media/case-studies/Rimm'

export enum CaseStudyIds {
    LESINSKI = 'lesinski-case-study',
    RIMM = 'rimm-case-study',
    CRC_GEO = 'crc-geometric-case-study',
    CRC_CONTOUR = 'crc-contour-case-study',
    CRC_SEGMENT = 'crc-segment-case-study',
}

export const LesinskiCaseStudy: StepNode = {
    id: CaseStudyIds.LESINSKI,
    type: NodeTypes.CASE_STUDY,
    label: 'Neoadjuvant Response',
    rules: [
        [AnalyteIds.PROTEIN, SampleIds.SMT, RegionIds.GEO],
        [AnalyteIds.PROTEIN, SampleIds.FFPE, RegionIds.GEO],
    ],
    media: Lesinski,
}
export const RimmCaseStudy: StepNode = {
    id: CaseStudyIds.RIMM,
    type: NodeTypes.CASE_STUDY,
    label: 'Predictive Biomarkers',
    rules: [
        [AnalyteIds.PROTEIN, SampleIds.SMT, RegionIds.RARE],
        [AnalyteIds.PROTEIN, SampleIds.TMA, RegionIds.RARE],
        [AnalyteIds.PROTEIN, SampleIds.FFPE, RegionIds.RARE],
    ],
    media: Rimm,
}
export const CRCGeometricCaseStudy: StepNode = {
    id: CaseStudyIds.CRC_GEO,
    type: NodeTypes.CASE_STUDY,
    label: 'CRC Geometric',
    rules: [
        [AnalyteIds.RNA, SampleIds.SMT, RegionIds.GEO],
        [AnalyteIds.RNA, SampleIds.FFPE, RegionIds.GEO],
    ],
    media: CRCGeometric,
}
export const CRCContourCaseStudy: StepNode = {
    id: CaseStudyIds.CRC_CONTOUR,
    type: NodeTypes.CASE_STUDY,
    label: 'CRC Contour',
    rules: [
        [AnalyteIds.RNA, SampleIds.SMT, RegionIds.CONTOUR],
        [AnalyteIds.RNA, SampleIds.FFPE, RegionIds.CONTOUR],
    ],
    media: CRCContour,
}
export const CRCSegmentCaseStudy: StepNode = {
    id: CaseStudyIds.CRC_SEGMENT,
    type: NodeTypes.CASE_STUDY,
    label: 'CRC Segment',
    rules: [[AnalyteIds.RNA, SampleIds.SMT, RegionIds.SEGMENT]],
    media: CRCSegment,
}

export const allCaseStudies: Map<string, StepNode> = new Map([
    [CaseStudyIds.LESINSKI, LesinskiCaseStudy],
    [CaseStudyIds.RIMM, RimmCaseStudy],
    [CaseStudyIds.CRC_GEO, CRCGeometricCaseStudy],
    [CaseStudyIds.CRC_CONTOUR, CRCContourCaseStudy],
    [CaseStudyIds.CRC_SEGMENT, CRCSegmentCaseStudy],
])
