import breakpoints, {
    breakpointDesktop,
    breakpointMobileNarrow,
    breakpointMobileWide,
    breakpointSmallDesktop,
    breakpointTablet,
} from './breakpoints'
import { base } from '@theme-ui/presets'

export default {
    ...base,
    colors: {
        ...base.colors,
        primary: '#194983',
        primaryHover: 'rgba(25, 73, 131, 0.8)',
        secondary: '#A5CD39',
        background: '#267594',
        backgroundGradient: '#32888c',
        muted: '#DFDFDF',
        anchor: '#974B97',
        hover: '#951B95',
        overlay: '#121212',
        highlight: '#d97845',
        mutedBackground: '#f6f8e2',
    },
    breakpoints: [
        breakpointMobileNarrow,
        breakpointMobileWide,
        breakpointTablet,
        breakpointSmallDesktop,
        breakpointDesktop,
    ].map((b) => `${breakpoints[b]}px`),
    styles: {
        ...base.styles,

        root: {
            '.highlighted': { color: 'secondary' },
            button: {
                ':focus': { outline: 0 },
                fontFamily: 'inherit',
            },
            fontFamily: '"Gotham", sans-serif',
        },
    },
    forms: {
        input: {
            borderStyle: 'solid',
            borderWidth: '.12rem',
            borderRadius: '.4rem',
            borderColor: 'primary',
            backgroundColor: '#fff',
            color: 'primary',
            fontWeight: 600,
            fontSize: '.8rem',
            '::placeholder': {
                color: '#C6C6C6',
            },
        },
        select: {
            borderStyle: 'solid',
            borderWidth: '.12rem',
            borderRadius: '.4rem',
            borderColor: 'primary',
            backgroundColor: '#fff',
            color: 'primary',
        },
    },
}
