import { action } from 'typesafe-actions'
import { ActionTypes } from './types'

export const setStepIndex = (index: number) =>
    action(ActionTypes.SET_STEP_INDEX, index)

export const setIsShowingWorkflow = (isShowing: boolean) =>
    action(ActionTypes.SET_IS_SHOWING_WORKFLOW, isShowing)

export const setStepAndMaxIndex = (index: number) =>
    action(ActionTypes.SET_STEP_AND_MAX_INDEX, index)

export const setStepSelection = (index: number, id?: string | null) =>
    action(ActionTypes.SET_STEP_SELECTION, { index, id })

export const reset = () => action(ActionTypes.RESET)
