/** @jsx jsx */
import { css, jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { analyticsPush } from '~nanostring-brand-elements/components/analytics'

import { CaseStudyProps } from '/content/profile/types'

import ZoomableImage from '/components/global/ZoomableImage'

import CaseStudySlider from './CaseStudySlider'

import img1 from './lesinski-detail1.png'
import img2 from './lesinski-detail2.png'
import img3 from './lesinski-detail3.png'
import img4 from './lesinski-detail4.jpg'
import arrowUp from './ArrowUp.png'
import arrowDown from './ArrowDown.png'

const commonIndent = css({
    listStyleType: 'none',
    color: 'primary',
})

const arrowPointsCSS = css({
    width: '16px',
    height: '16px',
})
const arrowFlexCSS = css({
    display: 'flex',
    width: '100%',
    paddingLeft: '1em',
})

const Page1 = () => {
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <h3 sx={{ color: 'black', m: '0 1rem 1ex 1rem' }}>
                Immune Biology of Colorectal Tumors
            </h3>
            <div
                sx={{
                    display: 'flex',
                    flexDirection: isPortrait ? 'column-reverse' : 'row',
                    height: 'calc(100% - 2rem)',
                    overflow: 'auto',
                }}
            >
                <div
                    sx={{
                        mx: '1rem',
                        flex: '1 1 40%',
                    }}
                >
                    <ZoomableImage
                        preview={img1}
                        src={img1}
                        sx={{
                            display: 'flex',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            justifyContent: 'center',
                        }}
                    />
                </div>
                <div
                    sx={{
                        flex: '1 1 60%',
                        maxWidth: '90%',
                        margin: '0 auto',
                        paddingTop: '1rem',
                        h4: {
                            textAlign: 'left',
                        },
                        p: {
                            textAlign: 'left',
                            paddingTop: '0.3rem',
                        },
                    }}
                >
                    <h4
                        sx={{
                            paddingTop: '0.3rem',
                        }}
                    >
                        Greg Lesinski, PhD, MPH
                    </h4>
                    <h4
                        sx={{
                            fontSize: '0.95em',
                        }}
                    >
                        Emory University
                    </h4>
                    <p>
                        Immunotherapies have shown great promise in a subset of
                        patients with certain malignancies in clinical trials,
                        but not Pancreatic Ductal Adenocarcinoma (PDAC).
                        Immunotherapies have been initially tested as single
                        agents.
                    </p>
                    <p>
                        How does co-treatment with relevant chemo- or
                        radiotherapy regimens impact T cell checkpoint blockade?
                    </p>
                </div>
            </div>
        </motion.div>
    )
}

const Page2 = () => {
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            {...fadeAnimations}
        >
            <div>
                <h3 sx={{ color: 'black', textAlign: 'center' }}>
                    Sampling different regions to understand tumor biology
                </h3>

                <div
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        sx={{
                            display: 'flex',
                            flexDirection: isPortrait ? 'column' : 'row',
                            paddingTop: '1.5em',
                            paddingBottom: '2.5em',
                            gap: isPortrait ? '1rem' : undefined,
                        }}
                    >
                        <div
                            sx={{
                                flex: '1 1 60%',
                                img: { width: '100%' },
                            }}
                        >
                            <ZoomableImage
                                preview={img2}
                                src={img2}
                                sx={{
                                    display: 'flex',

                                    p: 0,
                                }}
                            />
                        </div>

                        <div sx={{ flex: '1 1 40%' }}>
                            <h4
                                sx={{
                                    color: 'primary',
                                }}
                            >
                                ROI Selection Strategy
                            </h4>
                            <ul>
                                <li css={commonIndent}>
                                    <span sx={{ color: 'primary' }}>• </span>
                                    Tumor Enriched
                                </li>
                                <li css={commonIndent}>
                                    <span sx={{ color: 'primary' }}>• </span>
                                    Immune Enriched
                                </li>
                                <li css={commonIndent}>
                                    <span sx={{ color: 'primary' }}>• </span>
                                    Stroma Enriched
                                </li>
                            </ul>

                            <p
                                sx={{
                                    color: 'black',
                                    marginTop: '1rem',
                                }}
                            >
                                To determine the immunological effect of
                                neoadjuvant therapy (FOLFIRINOX) in PDAC
                                distinct regions of the tumor and the tumor
                                microenvironment were chosen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

const Page3 = () => {
    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <h3 sx={{ color: 'black', textAlign: 'center', pb: '1rem' }}>
                Clustering of protein expression across tumor compartments
            </h3>
            <div
                sx={{
                    height: 'calc(100% - 2rem)',
                    img: { width: '100%', maxHeight: '100%' },
                }}
            >
                <ZoomableImage preview={img3} src={img3} />
            </div>
        </motion.div>
    )
}

const Page4 = () => {
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit',
                maxHeight: 'inherit',
            }}
            {...fadeAnimations}
        >
            <h3
                sx={{
                    color: 'black',
                    textAlign: 'center',
                }}
            >
                FOLFIRINOX treatment results in significant spatially-distinct
                changes in protein expression
            </h3>
            <div
                sx={{
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'row',
                    m: 'auto 0',
                    pt: '0.5rem',
                    p: {
                        fontSize: '0.85em',
                    },
                }}
            >
                <div sx={{ flex: isPortrait ? '1 1 40%' : '1 1 65%' }}>
                    <p sx={{ paddingBottom: '1ex', color: 'black' }}>
                        FOLFIRINOX treatment is associated with:
                    </p>
                    <ul>
                        <li css={commonIndent} sx={{ color: 'primary' }}>
                            <span sx={{ color: 'secondary' }}>• </span>
                            Tumor Enriched
                        </li>
                        <div css={arrowFlexCSS} sx={{ paddingBottom: '2px' }}>
                            <motion.img src={arrowUp} css={arrowPointsCSS} />
                            <p>CD45, CD3, CD45RO, CD56, BCL2, PTEN</p>
                        </div>

                        <div css={arrowFlexCSS}>
                            <motion.img src={arrowDown} css={arrowPointsCSS} />
                            <p>β-Catenin</p>
                        </div>

                        <li
                            css={commonIndent}
                            sx={{ color: 'primary', marginTop: '1rem' }}
                        >
                            <span sx={{ color: 'secondary' }}>• </span>
                            Immune Enriched
                        </li>

                        <div css={arrowFlexCSS} sx={{ paddingBottom: '2px' }}>
                            <motion.img src={arrowUp} css={arrowPointsCSS} />
                            <p>
                                CD14, B7H3, PD-L1, Granzyme B, pAKT, PTEN,
                                β-Catenin, pSTAT3
                            </p>
                        </div>

                        <div css={arrowFlexCSS}>
                            <motion.img src={arrowDown} css={arrowPointsCSS} />
                            <p>CD19, CD20, CD4</p>
                        </div>

                        <li
                            css={commonIndent}
                            sx={{ color: 'primary', marginTop: '1rem' }}
                        >
                            <span sx={{ color: 'secondary' }}>• </span>
                            Stroma Enriched
                        </li>

                        <div css={arrowFlexCSS} sx={{ paddingBottom: '2px' }}>
                            <motion.img src={arrowUp} css={arrowPointsCSS} />
                            <p>BCL2, pSTAT3</p>
                        </div>

                        <div css={arrowFlexCSS}>
                            <motion.img src={arrowDown} css={arrowPointsCSS} />
                            <p>CD3, CD68, VISTA, Ki67</p>
                        </div>
                    </ul>
                </div>
                <div
                    sx={{
                        flex: isPortrait ? '1 1 60%' : '1 1 35%',
                        height: 'inherit',
                        maxHeight: 'inherit',
                        img: { width: '100%', maxHeight: '100%' },
                    }}
                >
                    <ZoomableImage
                        preview={img4}
                        src={img4}
                        sx={{
                            p: 0,
                        }}
                    />
                </div>
            </div>
        </motion.div>
    )
}

const CaseStudy: React.FC<CaseStudyProps> = ({
    className,
}): React.ReactElement => {
    useEffect(() => {
        analyticsPush({
            eventSection: 'caseStudyViewed',
            event: 'neoadjuvantResponse',
            eventValue: 1,
        })
    }, [])

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (pageIndex) => {
            analyticsPush({
                eventSection: 'caseStudyViewed',
                event: 'neoadjuvantResponse',
                eventValue: pageIndex + 1,
            })

            if (pageIndex === 3) {
                analyticsPush({
                    eventSection: 'caseStudyViewed',
                    event: 'neoadjuvantResponse',
                    eventValue: 'completed',
                })
            }
        },
    }

    return (
        <CaseStudySlider
            className={clsx(className, 'lesinski_case')}
            settings={settings}
            title='Endpoint: Neoadjuvant Response'
        >
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 />
        </CaseStudySlider>
    )
}

export default CaseStudy
