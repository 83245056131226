import React from 'react'

export interface Step {
    id: string
    label: string
    heading?: string
    subhead?: string
    type: string
    options: StepNode[]
    availableOptions: string[]
}

export interface StepNode {
    id: string
    label: string
    type: string
    rules: string[][]
    media?: React.ComponentType<StepNodeComponent> | string
    thumb?: React.ComponentType<StepNodeComponent> | string
    detail?: React.ComponentType<StepNodeComponent> | string
    eventAction?: string
    eventValue?: string
}

export interface CaseStudyProps {
    className?: string
}

export interface StepNodeComponent {
    children?: React.ReactNode
    className?: string
}

export enum NodeTypes {
    ANALYTE = 'analyte',
    SAMPLE = 'sample',
    REGION = 'region',
    WORKFLOW = 'workflow',
    ASSAY = 'assay',
    CASE_STUDY = 'case-study',
}
