/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import { ApplicationState } from '/store'
import { setStepAndMaxIndex } from '/store/profile/actions'

import fadeAnimations from '~yipkos-common/animations/fade'
import ButtonWithArrow from '/components/global/ButtonWithArrow'
import { StepNodeNav } from '/components/profile/ProfileBuilder/interfaces'
import { analyticsPush } from '~nanostring-brand-elements/components/analytics'
import { nodes } from '/content/profile'
import { StepNode } from '/content/profile/types'

const getOptionMedia = (option: StepNode) =>
    typeof option.media === 'string'
        ? () => <img alt='' src={option.media as string} />
        : option.media

const Workflow: React.FC<StepNodeNav & { selections: string[] }> = (props) => {
    const { className, availableOptions, stepIndex, selections } = props
    const dispatch = useDispatch()

    // console.log(availableOptions, 'selections', selections)

    if (!availableOptions) {
        return null
    }
    const chosenWorkflow = availableOptions.find(
        (element) => element.indexOf(selections[0]) !== -1
    )
    if (!chosenWorkflow) {
        return null
    }
    const workflowNode = nodes.get(chosenWorkflow)
    if (!workflowNode) {
        return null
    }
    const MediaComponent = getOptionMedia(workflowNode)
    if (!MediaComponent) {
        return null
    }

    const onSeeResults = () => {
        analyticsPush({
            eventSection: 'profileResults',
            event: 'profileResultsViewed',
        })
        dispatch(setStepAndMaxIndex(stepIndex + 1))
    }

    return (
        <WorkflowContent
            className={className}
            workflowId={chosenWorkflow}
            onSeeResults={onSeeResults}
        />
    )
}

export const WorkflowContent = (props) => {
    const { className, onSeeResults, workflowId } = props

    const workflowNode = nodes.get(workflowId)
    if (!workflowNode) {
        return null
    }
    const MediaComponent = getOptionMedia(workflowNode)
    if (!MediaComponent) {
        return null
    }

    return (
        <motion.div
            className={className}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}
            {...fadeAnimations}
        >
            <MediaComponent />
            <ButtonWithArrow onClick={onSeeResults}>
                See Results
            </ButtonWithArrow>
        </motion.div>
    )
}

const mapStateToProps = ({ profile }: ApplicationState) => ({
    stepIndex: profile.stepIndex,
    selections: profile.selections,
})

export default connect(mapStateToProps)(Workflow)
