/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { Fragment, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AnimatePresence, motion } from 'framer-motion'

import { ApplicationState } from '/store'
import { setIsShowingWorkflow } from '/store/profile/actions'

import fadeAnimations from '~yipkos-common/animations/fade'
import ButtonWithArrow from '/components/global/ButtonWithArrow'
import ZoomableImage from '/components/global/ZoomableImage'
import Workflow from '/components/profile/Workflow'
import { StepNodeNav } from '/components/profile/ProfileBuilder/interfaces'
import { analyticsPush } from '~nanostring-brand-elements/components/analytics'
import { allRegions } from '/content/profile/regions'

const WorkflowROI: React.FC<
    StepNodeNav & { selections: string[]; isShowingWorkflow: boolean }
> = (props) => {
    const { selections, isShowingWorkflow, ...rest } = props
    const roiId = selections[2]
    const dispatch = useDispatch()

    const onViewWorkflow = () => {
        analyticsPush({
            eventSection: 'profileResults',
            event: 'profileWorkflow',
        })
        dispatch(setIsShowingWorkflow(true))
    }

    return (
        <Fragment>
            {!isShowingWorkflow && (
                <WorkflowROIContent
                    roiId={roiId}
                    onViewWorkflow={onViewWorkflow}
                />
            )}
            <AnimatePresence>
                {isShowingWorkflow && <Workflow {...rest} />}
            </AnimatePresence>
        </Fragment>
    )
}
const mapStateToProps = ({ profile }: ApplicationState) => ({
    selections: profile.selections,
    isShowingWorkflow: profile.isShowingWorkflow,
})
export default connect(mapStateToProps)(WorkflowROI)

export const WorkflowROIContent = (props) => {
    const { roiId, onViewWorkflow } = props

    const roi = allRegions.get(roiId)
    if (roi == null) {
        return null
    }

    useEffect(() => {
        analyticsPush({
            eventSection: 'profileResults',
            event: 'created',
        })
    }, [])

    const detail = roi.detail

    const Detail = !detail
        ? null
        : typeof detail === 'string'
        ? () => (
              <ZoomableImage
                  src={detail}
                  preview={detail}
                  sx={{
                      height: 'calc(100% - 16ex)',
                      pb: '1ex',
                  }}
              />
          )
        : detail

    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                alignItems: 'center',
            }}
            {...fadeAnimations}
        >
            {Detail && <Detail sx={{ width: '100%' }} />}
            <ButtonWithArrow onClick={onViewWorkflow}>
                View Workflow
            </ButtonWithArrow>
        </motion.div>
    )
}
