import React, { useCallback, useEffect } from 'react'
import type { CSSProperties, ReactNode } from 'react'
import clsx from 'clsx'

import { disableBodyScroll, enableBodyScroll } from '../../utils/bodyScrollLock'
import { useScreenshotMode } from '../../utils/screenshotMode'

export interface Props {
    id: string // The ID of the dialog, to give to disableBodyScroll.  Also placed on the containing div.
    scrollId?: string
    className?: string
    style?: CSSProperties
    doNotDisableBodyScroll?: boolean // Set this if you want scrolling of the whole page instead of the modal.
    isOpen: boolean
    onClose?: () => void
    children?: ReactNode
}

/*

    If you want to scroll inside this dialog directly, then you only need
    to specify id='UniqueString'.

    If you want to scroll a child element (e.g. if you want a fixed heading
    with a scroller inside the dialog) then you need to specify
    both id='UniqueString' and scrollId='AnotherUniqueString'.  The
    id will be applied to this dialog, and the scrollId you must apply
    to the inner element that will be the scroll container.

    In either case, the scroll container needs to have:

        overflowY: 'auto',
        ['WebkitOverflowScrolling' as any]: 'touch',

    If you don't do these things correctly, then your dialog might still
    work, mostly.  It is iOS Safari that requires all this complexity.

*/

const DialogBase = (props: Props) => {
    const {
        id,
        scrollId = id,
        className,
        doNotDisableBodyScroll = false,
        isOpen,
        onClose,
        ...rest
    } = props
    const isScreenshotMode = useScreenshotMode()

    const onKeyDown = useCallback(
        (ev) => {
            if (!isOpen || !onClose) {
                return
            }
            if (ev.key === 'Escape' || ev.key === 'Esc' || ev.keyCode === 27) {
                onClose()
            }
        },
        [isOpen, onClose]
    )

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', onKeyDown)
        }
        return () => {
            document.removeEventListener('keydown', onKeyDown)
        }
    }, [isOpen, onKeyDown])

    useEffect(() => {
        if (isOpen) {
            if (isScreenshotMode || doNotDisableBodyScroll) {
                enableBodyScroll(scrollId)
            } else {
                disableBodyScroll(scrollId, { reserveScrollBarGap: true })
            }
            const el = document.getElementById(scrollId)
            if (el) {
                el.scrollTo(0, 0)
            }
        } else {
            enableBodyScroll(scrollId)
        }
    }, [scrollId, isOpen, doNotDisableBodyScroll, isScreenshotMode])

    useEffect(() => {
        return () => {
            enableBodyScroll(scrollId)
        }
    }, [])

    return (
        <div
            {...rest}
            id={id}
            className={clsx(
                className,
                'DialogBase',
                isOpen && 'DialogBase-isOpen'
            )}
        />
    )
}
export default DialogBase
