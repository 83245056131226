/** @jsx jsx */
import { jsx, Label, Radio } from 'theme-ui'
import type { ReactNode } from 'react'
import clsx from 'clsx'

import { Field } from 'formik'

interface Props {
    label?: ReactNode
    otherField?: string
    name?: string
    [key: string]: any
}

const LabeledRadioButton = (props: Props) => {
    const { label, otherField, ...rest } = props

    return (
        // Classes are for targeting by external CSS.
        <Label
            className={clsx(
                'formField',
                'formField-radioButton',
                otherField && 'hasOtherField'
            )}
        >
            <Field {...rest} type='radio' as={Radio} />
            {label}
            {otherField}
        </Label>
    )
}
export default LabeledRadioButton
