/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { useState, useContext, useEffect } from 'react'
import cn from 'classnames'
import { motion } from 'framer-motion'
import { camelCase } from 'lodash'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import type { StepNode } from '/content/profile/types'

import { Context as APIContext } from '/components/APIProvider'
import { analyticsPush } from '~nanostring-brand-elements/components/analytics'
import MediaImage from '/components/global/MediaImage'
import type { StepNodeNav } from '/components/profile/ProfileBuilder/interfaces'

const ChooseSample = (props: StepNodeNav) => {
    const { className, options, availableOptions, onChange } = props

    const isPortrait = useIsPortrait()
    const [selectedOption, setSelectedOption] = useState<StepNode | null>(null)
    const { userInteract } = useContext(APIContext)
    const [stepTwoSelection, setStepTwoSelection] = useState<string | null>(
        null
    )

    useEffect(() => {
        setStepTwoSelection(localStorage.getItem('stepTwoSelection') || null)
    }, [])

    if (!availableOptions) {
        return null
    }

    return (
        <motion.div
            className={className}
            sx={{
                display: 'flex',
                flexDirection: isPortrait ? 'column' : 'row',
                width: '100%',
            }}
        >
            <Flex
                sx={{
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                }}
            >
                <div
                    sx={{
                        margin: 0,
                        py: 0,
                        px: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '90%',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignContent: 'center',
                    }}
                >
                    {options.map((option, i: number) => {
                        const disabled =
                            availableOptions.indexOf(option.id) === -1
                        const active = stepTwoSelection
                            ? stepTwoSelection === option.id
                            : selectedOption && selectedOption.id === option.id
                        return (
                            <div
                                key={`li-option-${i}-${option.id}`}
                                sx={{
                                    listStyle: 'none',
                                    display: 'flex',
                                    width: '35%',
                                    maxHeight: '25%',
                                    height: '40%',
                                    // alignItems: 'center',
                                    flexDirection: 'column',
                                    m: '2.5%',
                                }}
                            >
                                <motion.button
                                    initial={{ opacity: 1 }}
                                    animate={{ opacity: 1 }}
                                    exit={{
                                        opacity: 0,
                                        transition: {
                                            delay:
                                                (options.length - 1 - i) * 0.05,
                                        },
                                    }}
                                    transition={{ delay: i * 0.1 }}
                                    className={cn(
                                        {
                                            active,
                                        },
                                        { disabled }
                                    )}
                                    disabled={disabled}
                                    key={`btn-${option.id}`}
                                    sx={{
                                        ':focus': { outline: 0 },
                                        transition: 'all .3s ease',
                                        border: 'none',
                                        padding: 0,
                                        margin: 0,
                                        cursor: 'pointer',
                                        width: '100%',
                                        height: '100%',
                                        backgroundImage: disabled
                                            ? 'repeating-linear-gradient(0deg, #dfdfdf, #dfdfdf 7px, transparent 7px, transparent 21px, #dfdfdf 21px), repeating-linear-gradient(90deg, #dfdfdf, #dfdfdf 7px, transparent 7px, transparent 21px, #dfdfdf 21px), repeating-linear-gradient(180deg, #dfdfdf, #dfdfdf 7px, transparent 7px, transparent 21px, #dfdfdf 21px), repeating-linear-gradient(270deg, #dfdfdf, #dfdfdf 7px, transparent 7px, transparent 21px, #dfdfdf 21px)'
                                            : 'repeating-linear-gradient(0deg, #96C93D, #96C93D 7px, transparent 7px, transparent 21px, #96C93D 21px), repeating-linear-gradient(90deg, #96C93D, #96C93D 7px, transparent 7px, transparent 21px, #96C93D 21px), repeating-linear-gradient(180deg, #96C93D, #96C93D 7px, transparent 7px, transparent 21px, #96C93D 21px), repeating-linear-gradient(270deg, #96C93D, #96C93D 7px, transparent 7px, transparent 21px, #96C93D 21px)',
                                        backgroundSize:
                                            '2px 100%, 100% 2px, 2px 100% , 100% 2px',
                                        backgroundPosition:
                                            '0 0, 0 0, 100% 0, 0 100%',
                                        backgroundRepeat: 'no-repeat',
                                        borderRadius: 5,
                                        position: 'relative',
                                        p: '2%',
                                        img: {
                                            width: 'auto',
                                            height: 'auto',
                                            maxWidth: '100%',
                                            maxHeight: '90%',
                                            margin: 'auto',
                                        },
                                        svg: {
                                            width: 'auto',
                                            height: [
                                                '60px',
                                                '60px',
                                                '70px',
                                                '100px',
                                                '150px',
                                            ],
                                            maxHeight: '85%',
                                            margin: 'auto',
                                            textRendering: 'geometricPrecision',
                                        },
                                        '&.active': {
                                            borderStyle: 'solid',
                                            borderWidth: 3,
                                            borderColor: '#96C93D',
                                            backgroundImage: 'none',
                                        },
                                        '::after': {
                                            transition: 'all .3s ease',
                                            content: disabled
                                                ? '"Case Study Coming Soon"'
                                                : '"Choose Sample"',
                                            backgroundColor: 'overlay',
                                            color: '#fff',
                                            opacity: 0,
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            p: '2rem',
                                            display: 'flex',
                                            width: '100%',
                                            height: '100%',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            borderRadius: 5,
                                            pointerEvents: 'none',
                                        },
                                        '&:hover': {
                                            '::after': {
                                                opacity: 0.8,
                                            },
                                        },
                                    }}
                                    onClick={() => {
                                        analyticsPush({
                                            eventSection: 'profileBuild',
                                            event: 'sample',
                                            eventValue: camelCase(option.id),
                                        })
                                        setSelectedOption(option)
                                        if (userInteract) {
                                            userInteract(
                                                'ChooseSampleStep',
                                                'SampleButton',
                                                option.id
                                            )
                                        }
                                        onChange(option.id)
                                    }}
                                >
                                    <MediaImage media={option.media} />
                                </motion.button>
                            </div>
                        )
                    })}
                </div>
            </Flex>
        </motion.div>
    )
}

export default ChooseSample
