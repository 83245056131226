import { combineReducers, Reducer } from 'redux'
import { LayoutState, layoutReducer } from './layout'
import { State as ProfileState, profileReducer } from './profile'

export interface ApplicationState {
    layout: LayoutState
    profile: ProfileState
}

export const createRootReducer = (): Reducer<ApplicationState> =>
    combineReducers({
        layout: layoutReducer,
        profile: profileReducer,
    })
