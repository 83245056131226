/** @jsx jsx */
import { Button, jsx } from 'theme-ui'

import ModalDialog from '~nanostring-brand-elements/components/modals/ModalDialog'

const ThankYouDialog = (props) => {
    const { isOpen, ...rest } = props

    return (
        <ModalDialog
            id='ThankYouDialog'
            isOpen={isOpen}
            sx={{
                width: '30rem',
                height: 'fit-content',
            }}
        >
            <ThankYouDialogContent {...rest} />
        </ModalDialog>
    )
}
export default ThankYouDialog

const ThankYouDialogContent = (props) => {
    const { onClose } = props

    return (
        <div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'left',
            }}
        >
            <p sx={{ mb: '1ex' }}>Thank you for your request.</p>
            <p sx={{ mb: '8ex' }}>
                Someone will be in touch shortly to schedule a live project
                consultation with you.
            </p>
            <Button
                variant='standard'
                sx={{
                    alignSelf: 'center',
                }}
                onClick={onClose}
            >
                Continue
            </Button>
        </div>
    )
}
