import { MutableRefObject } from 'react'

export const preloadImages = (
    imgs: string[],
    delayMsec: number,
    stateRef?: MutableRefObject<{ [key: string]: boolean }>
) => {
    if (stateRef) {
        const d = stateRef.current || {}
        for (const src of imgs) {
            d[src] = false
        }
        stateRef.current = d
    }
    setTimeout(() => {
        for (const src of imgs) {
            const img = new Image()
            if (stateRef) {
                img.onload = () => _preloadImagesOnLoad(stateRef, src)
            }
            img.src = src
        }
    }, delayMsec)
}

const _preloadImagesOnLoad = (
    stateRef: MutableRefObject<{ [key: string]: boolean }>,
    src: string
) => {
    const d = stateRef.current || {}
    d[src] = true
    stateRef.current = d
    // console.log('Preloaded image', src)
}
