import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory, createHashHistory } from 'history'
import { ThemeProvider } from 'theme-ui'

import smoothscroll from 'smoothscroll-polyfill'

import { preloadImages } from '~yipkos-common/utils/image-utils'
import { ScreenshotModeProvider } from '~yipkos-common/utils/screenshotMode'

import theme from '~nanostring-brand-elements/theme'

import APIProvider from '/components/APIProvider'
import Layout from '/components/Layout'
import {
    createSubpageLayoutContext,
    SubpageLayoutContext,
} from '/components/global/SubpageLayoutContext'
import ReduxWrapper from '/store/ReduxWrapper'

import Routes from './Routes'

import './assets/scss/imports.scss'
import './assets/scss/index.scss'

export const isWebApp = (window.navigator as any)?.standalone

// Use hashHistory if we're in a webapp, so that iOS doesn't show the nav
// toolbar.  We have on-screen Back buttons where necessary.
const browserHistory = isWebApp ? createHashHistory() : createBrowserHistory()
smoothscroll.polyfill()

preloadImages(['/images/explore-menu-bg.png'], 2000)

const geoMxTheme = {
    ...theme,
    buttons: {
        primary: {
            border: 'none',
            backgroundColor: 'primary',
            color: '#fff',
            fontSize: '1rem',
            fontWeight: '500',
            py: ['.5rem', '1.5rem'],
            px: ['1.3rem', '2.3rem'],
            borderRadius: 5,
            cursor: 'pointer',
            transition: 'all .3s ease',
            '&:hover': {
                backgroundColor: 'primaryHover',
            },
        },
        open: {
            border: 'none',
            backgroundColor: 'transparent',
            color: 'primary',
            fontSize: '1rem',
            fontWeight: '500',
            py: ['.5rem', '1.5rem'],
            px: ['1.3rem', '2.3rem'],
            borderRadius: 5,
            cursor: 'pointer',
            transition: 'all .3s ease',
            '&:hover': {
                color: 'black',
            },
        },
        standard: {
            backgroundColor: '#fff',
            color: 'primary',
            px: '1.6rem',
            py: '.7rem',
            m: '-.5rem',
            fontSize: '.85rem',
            display: 'flex',
            fontWeight: '600',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderStyle: 'solid',
            borderWidth: '.2rem',
            borderRadius: '.3rem',
            overflow: 'hidden',
            borderColor: 'primary',
            '&.active,&:hover': {
                backgroundColor: 'primary',
                color: '#fff',
            },
            transition: 'all .3s ease',
            img: {
                maxWidth: ['2rem', null, '2rem'],
                maxHeight: ['2rem', null, '2rem'],
            },
        },
    },
    styles: {
        ...theme.styles,
        root: {
            ...theme.styles.root,
            h2: {
                fontSize: ['25px', null, '28px'],
                lineHeight: 1.25,
                fontWeight: 'bold',
                color: '#121212',
            },
            h3: {
                fontSize: ['13px', null, '14px'],
                fontWeight: 'bold',
                lineHeight: 1.15,
                color: '#121212',
            },
            h4: {
                fontSize: ['15px', null, '18px'],
                fontWeight: 'bold',
                lineHeight: 1.15,
                color: 'primary',
            },
        },
    },
}

const App = () => {
    return (
        <ThemeProvider theme={geoMxTheme}>
            <ReduxWrapper>
                <AppContent />
            </ReduxWrapper>
        </ThemeProvider>
    )
}
export default App

const AppContent = () => {
    return (
        <Router history={browserHistory}>
            <APIProvider>
                <RouterContent />
            </APIProvider>
        </Router>
    )
}

const RouterContent = () => {
    const subpageLayoutContext = createSubpageLayoutContext()

    return (
        <ScreenshotModeProvider isEnabled={_isScreenshotModeEnabled()}>
            <SubpageLayoutContext.Provider value={subpageLayoutContext}>
                <Layout>
                    <Routes />
                </Layout>
            </SubpageLayoutContext.Provider>
        </ScreenshotModeProvider>
    )
}

const _isScreenshotModeEnabled = () => {
    const location = window?.location
    if (!location) {
        return false
    }

    return (
        location.href.startsWith('http://localhost') ||
        location.href.includes('geomx-development')
    )
}
