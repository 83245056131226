/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion, AnimatePresence } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import fadeAnimations from '~yipkos-common/animations/fade'
import AnalyzeDataAnimation from '/components/analyze/AnalyzeDataAnimation'
import ScheduleConsultForm from '/components/schedule/ScheduleConsultForm/ScheduleConsultForm'

const cancerResearch = '/images/cr_80_4_cover-ns 1.png'
const screenshot = '/images/analyze-data-screenshot.png'

const heading = 'Analyze Your Data'
const subhead =
    'Integrated interactive analysis between tissue and expression resulting in a truly spatial biological interpretation'

const AnalyzeData = () => {
    const isPortrait = useIsPortrait()

    return (
        <div
            className={isPortrait ? 'f-col' : 'f-row'}
            sx={{
                justifyContent: isPortrait ? 'space-between' : 'flex-start',
                height: '100%',
            }}
        >
            <div
                className='f-col f-gs-auto'
                sx={{
                    justifyContent: isPortrait ? 'space-evenly' : 'flex-start',
                }}
            >
                <AnimatePresence exitBeforeEnter>
                    <motion.div
                        className='f-col'
                        sx={{
                            textAlign: 'center',
                        }}
                        {...fadeAnimations}
                    >
                        <h2
                            sx={{
                                fontSize: isPortrait
                                    ? [22, null, null, 45]
                                    : [28, null, null, 45],
                                m: isPortrait
                                    ? [0, null, null, '0 1rem 3ex 1rem']
                                    : '0 1rem',
                            }}
                        >
                            {heading}
                        </h2>
                        <h4
                            sx={{
                                fontSize: isPortrait
                                    ? [18, null, null, 36]
                                    : undefined,
                                m: isPortrait ? '0 auto 0 auto' : '1rem',
                            }}
                        >
                            {subhead}
                        </h4>
                    </motion.div>
                </AnimatePresence>
                <div className='f-row'>
                    <div
                        sx={{
                            display: 'flex',
                            flexDirection: isPortrait ? 'column' : 'row',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            className='f-col-c-100'
                            sx={{
                                mb: isPortrait ? '0rem' : '1rem',
                                mt: isPortrait ? [0, null, null, '1rem'] : 0,
                            }}
                        >
                            <AnalyzeDataAnimation />
                            <div
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '3fr 1fr',
                                    mt: ['1ex', null, null, '2ex'],
                                    width: isPortrait
                                        ? ['70%', null, null, '100%']
                                        : '100%',
                                }}
                            >
                                <img
                                    alt=''
                                    src={screenshot}
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'cover',
                                    }}
                                />
                                <img
                                    alt=''
                                    src={cancerResearch}
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'cover',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScheduleConsultForm
                isSubForm
                sx={{
                    flex: '0 1 305px',
                    justifyContent: 'center',
                    textAlign: 'center',
                    ml: isPortrait ? '0rem' : '1rem',
                    mt: isPortrait ? [0, null, null, '5ex'] : '0rem',
                }}
            />
        </div>
    )
}
export default AnalyzeData
