/** @jsx jsx */
import { jsx } from 'theme-ui'
import { MouseEventHandler } from 'react'
import { motion } from 'framer-motion'

import CloseSvg from '../assets/images/icons/closeCircle.inline-svg'

interface Props {
    className?: string
    onClick?: MouseEventHandler
}

const CloseBtn = (props: Props) => {
    const { className, onClick } = props
    return (
        <motion.button
            className={className}
            sx={{
                width: 'auto',
            }}
            onClick={onClick}
        >
            <CloseSvg
                sx={{
                    width: ['30px', null, null, '50px'],
                    height: ['30px', null, null, '50px'],
                }}
            />
        </motion.button>
    )
}
export default CloseBtn
