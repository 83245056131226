/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'
import { motion } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import fadeAnimations from '~yipkos-common/animations/fade'

import ZoomableImage from '/components/global/ZoomableImage'

import biomarkerTopImage from './assets/biomarker-top.png'
import biomarkerBotImage from './assets/biomarker-bot.png'
import biomarkerBotHorizontalImage from './assets/biomarker-bot-horizontal.png'

const ExploreBiomarkerDiscoveryPage = () => {
    const isPortrait = useIsPortrait()
    return isPortrait ? (
        <ExploreBiomarkerDiscoveryPagePortrait />
    ) : (
        <ExploreBiomarkerDiscoveryPageLandscape />
    )
}

const ExploreBiomarkerDiscoveryPagePortrait = () => {
    return (
        <Fragment>
            <div
                sx={{
                    textAlign: 'center',
                    marginTop: '5vh',
                }}
            >
                <motion.h2
                    {...fadeAnimations}
                    sx={{
                        fontSize: '45px',
                    }}
                >
                    Biomarker Discovery Application
                </motion.h2>
                <motion.h4
                    {...fadeAnimations}
                    sx={{
                        textAlign: 'center',
                        fontSize: '36px',
                        padding: '20px 51px',
                    }}
                >
                    Spatial Resolution of Immune Response within the
                    Microenvironment
                </motion.h4>
            </div>

            <motion.div
                sx={{
                    marginTop: '10%',
                    padding: '0 3%',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr ',
                    gridTemplateRows: '50% 50%',
                    gap: '5%',
                    width: '100%',
                    height: '100%',
                }}
                {...fadeAnimations}
            >
                <BiomarkerTopImage />
                <BiomarkerText
                    sx={{
                        gridColumn: '2',
                        gridRow: '1',
                    }}
                />
                <div
                    sx={{
                        gridRow: '2',
                        gridColumn: '1 / 3',
                    }}
                >
                    <ZoomableImage
                        preview={biomarkerBotHorizontalImage}
                        src={biomarkerBotHorizontalImage}
                        withoutHeight
                    />
                </div>
            </motion.div>
        </Fragment>
    )
}

const ExploreBiomarkerDiscoveryPageLandscape = () => {
    return (
        <Fragment>
            <motion.div
                sx={{
                    display: 'flex',
                    textAlign: 'center',
                    width: '100%',
                    height: '20%',
                    flexDirection: 'column',
                }}
                {...fadeAnimations}
            >
                <div>
                    <motion.h2 {...fadeAnimations}>
                        Biomarker Discovery Application
                    </motion.h2>
                    <motion.h4 {...fadeAnimations}>
                        Spatial Resolution of Immune Response within the
                        Microenvironment
                    </motion.h4>
                </div>
            </motion.div>
            <motion.div
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingTop: '4ex',
                }}
                {...fadeAnimations}
            >
                <BiomarkerTopImage />
                <div
                    sx={{
                        flex: '1 1 calc(50% - 10rem)',
                    }}
                >
                    <ZoomableImage
                        preview={biomarkerBotImage}
                        src={biomarkerBotImage}
                    />
                </div>
                <BiomarkerText />
            </motion.div>
        </Fragment>
    )
}

const BiomarkerText = () => {
    const isPortrait = useIsPortrait()
    return (
        <div
            sx={{
                flex: isPortrait ? undefined : '1 1 10rem',
                fontSize: isPortrait ? '24px' : '0.9rem',
                paddingLeft: '1rem',
                fontWeight: 400,
            }}
        >
            <p>
                Here we see selected TIS-H blocks profiled with
                auto-segmentation. Two 600 Qm diameter circular ROIs were placed
                in each tumor location (IM, CT, and Tumor). The GeoMx software
                then automatically selected segments in the ROI that are
                positive or negative for Pan-CK. Barcodes were collected by
                focusing UV light either to tumor (Pan-CK+) or stroma (Pan-CK-)
                segments. Clustering showed that stromal and tumor segments
                contained clearly different gene expression. Below, we see that
                expression of TIS genes was higher in the stromal segments.
            </p>
        </div>
    )
}

const BiomarkerTopImage = () => {
    const isPortrait = useIsPortrait()
    return (
        <div
            sx={{
                flex: isPortrait ? undefined : '1 1 calc(50% - 10rem)',
            }}
        >
            <ZoomableImage
                preview={biomarkerTopImage}
                src={biomarkerTopImage}
                withoutHeight
            />
        </div>
    )
}
export default ExploreBiomarkerDiscoveryPage
