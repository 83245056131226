import { useField } from 'formik'
import { ComponentType } from 'react'
import clsx from 'clsx'

interface ButtonProps {
    name: string
    value: string
    checked: boolean | undefined
    onClick: (MouseEvent) => void | undefined
    [key: string]: any
}

interface Options {
    id: string
    buttonProps?: { [key: string]: any }
}

export interface RadioButtonGroupProps {
    className?: string
    name: string
    value: string | null
    options: Options[]
    Button?: ComponentType<ButtonProps>
    onChange: (newValue: string | undefined) => void | undefined
}

const RadioButtonGroup = (props: RadioButtonGroupProps) => {
    const { className, name, value, options, Button, onChange } = props

    if (!Button) {
        return null
    }

    const onClick = React.useCallback(
        (ev) => {
            onChange(ev.currentTarget.value)
        },
        [onChange]
    )

    const buttons = options.map((o) => (
        <Button
            {...(o.buttonProps || {})}
            name={name}
            key={o.id}
            value={o.id}
            checked={o.id === value}
            onClick={onClick}
        />
    ))

    return (
        <div
            role='group'
            // Classes are for targeting by external CSS.
            className={clsx('formField', 'formField-radioGroup', className)}
        >
            {buttons}
        </div>
    )
}
export default RadioButtonGroup

export interface RadioButtonGroupFormikProps {
    className?: string
    name: string
    options: Options[]
    Button?: ComponentType<ButtonProps>
}

export const RadioButtonGroupFormik = (props: RadioButtonGroupFormikProps) => {
    const { name, ...rest } = props
    const [field, , helpers] = useField(name)

    return (
        <RadioButtonGroup
            {...rest}
            name={name}
            value={field.value}
            onChange={helpers.setValue}
        />
    )
}
