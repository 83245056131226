/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'
import scalednCounterCounts from '/content/profile/media/regions/Scaled nCounter Counts horizonal.png'
import assay from '/content/profile/media/regions/geometric-detail-assay.png'
import stain from '/content/profile/media/regions/geometric-detail-stain.png'
import legend from '/content/profile/media/regions/geometric-region-legend.png'
import { Fragment } from 'react'

const GeometricProfilingDetail = () => {
    const isPortrait = useIsPortrait()

    return isPortrait ? (
        <GeometricProfilingDetailPortrait />
    ) : (
        <GeometricProfilingDetailLandscape />
    )
}

const GeometricProfilingDetailPortrait = () => {
    return (
        <Fragment>
            <Heading />
            <motion.div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 0,

                    height: '100%',
                    justifyContent: 'space-around',
                }}
            >
                <div
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    <Stain />
                    <AssayImage />
                </div>

                <div
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: '4rem',
                    }}
                >
                    <Legend />
                    <ScalednCounterCounts />
                </div>
            </motion.div>
        </Fragment>
    )
}

const GeometricProfilingDetailLandscape = () => {
    return (
        <Fragment>
            <Heading />
            <motion.div
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <div
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Stain />
                </div>
                <div
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <AssayImage />
                    <div
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Legend />

                        <ScalednCounterCounts />
                    </div>
                </div>
            </motion.div>
        </Fragment>
    )
}

const Heading = () => {
    return (
        <h3
            className='f-none highlight'
            sx={{
                textAlign: 'center',
                maxWidth: 'min(60%, 600px)',
                mt: '2ex',
                mb: '1ex',
            }}
        >
            Geometric profiling identifies distinct expression profiles across
            and within specific regions of the tissue
        </h3>
    )
}

const AssayImage = () => {
    const isPortrait = useIsPortrait()
    return (
        <motion.img
            alt=''
            src={assay}
            sx={{
                maxWidth: '95%',
                height: isPortrait ? '90%' : 'auto',
                objectFit: 'contain',
                paddingBottom: isPortrait ? '70px' : 0,
                mt: isPortrait ? 0 : '4rem',
            }}
            {...fadeAnimations}
        />
    )
}

const Stain = () => {
    const isPortrait = useIsPortrait()

    return (
        <motion.img
            alt=''
            src={stain}
            sx={{
                maxWidth: '85%',
                maxHeight: 'auto',
                objectFit: 'contain',
                borderRadius: '8px',
                ml: isPortrait ? 0 : '1rem',
            }}
            {...fadeAnimations}
        />
    )
}

const Legend = () => {
    const isPortrait = useIsPortrait()
    return (
        <motion.img
            alt=''
            src={legend}
            sx={{
                height: 'auto',
                width: '16%',
                objectFit: 'contain',
                ml: isPortrait ? 0 : '1rem',
                mt: isPortrait ? 0 : '2rem',
            }}
            {...fadeAnimations}
        />
    )
}

const ScalednCounterCounts = () => {
    return (
        <motion.img
            alt=''
            src={scalednCounterCounts}
            sx={{
                height: 'auto',
                width: '27%',
                objectFit: 'contain',
                ml: '2rem',
            }}
            {...fadeAnimations}
        />
    )
}

export default GeometricProfilingDetail
