/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState, useContext, Fragment } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router'
import { connect, useDispatch } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { ApplicationState } from '/store'
import { setStepAndMaxIndex, reset } from '/store/profile'

import fadeAnimations from '~yipkos-common/animations/fade'
import { Context as APIContext } from '/components/APIProvider'
import ButtonWithArrow from '/components/global/ButtonWithArrow'
import ButtonWithReset from '/components/global/ButtonWithReset'
import { useSubpageLayout } from '/components/global/SubpageLayoutContext'
import ZoomableImage from '/components/global/ZoomableImage'
import { StepNodeNav } from '/components/profile/ProfileBuilder/interfaces'
import { analyticsPush } from '~nanostring-brand-elements/components/analytics'
import { nodes, selectionsMatchRules } from '/content/profile'
import { allAnalytes } from '/content/profile/analytes'
import { allAssays } from '/content/profile/assays'
import { allCaseStudies } from '/content/profile/caseStudies'
import { StepNode } from '/content/profile/types'

const getAssayChoiceButtons = (
    optionIds: string[],
    setSelectedOption: Function,
    selectedOption: StepNode | null
) => {
    return optionIds.map((optionId, i) => {
        const assayNode = nodes.get(optionId)
        if (!assayNode) {
            return null
        }

        return (
            <motion.button
                className={
                    selectedOption && selectedOption.id === assayNode.id
                        ? 'active'
                        : undefined
                }
                key={`assay-${i}`}
                sx={{
                    flex: 1,
                    backgroundColor: '#fff',
                    color: 'primary',
                    py: '.5rem',
                    fontSize: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderBottom: '5px solid #eee',
                    fontWeight: 'bold',
                    '&.active,&:hover': {
                        borderColor: 'primary',
                    },
                    transition: 'all .3s ease',
                }}
                onClick={() => {
                    analyticsPush({
                        eventSection: 'profileResults',
                        event: assayNode.eventAction,
                    })
                    setSelectedOption(assayNode)
                }}
            >
                {assayNode.label}
            </motion.button>
        )
    })
}

const ChooseAssay: React.FC<
    StepNodeNav & {
        selections: string[]
    }
> = ({ selections, stepIndex }) => {
    const location = useLocation()
    const { pathname } = location

    const dispatch = useDispatch()
    const { userInteract } = useContext(APIContext)

    const onClickViewCaseStudy = () => {
        userInteract(pathname, '', '')
        dispatch(setStepAndMaxIndex(stepIndex + 1))
    }

    const onClickBuildAnother = () => {
        dispatch(reset())
    }

    return (
        <ChooseAssayContent
            selections={selections}
            onClickViewCaseStudy={onClickViewCaseStudy}
            onClickBuildAnother={onClickBuildAnother}
        />
    )
}

const mapStateToProps = ({ profile }: ApplicationState) => ({
    selections: profile.selections,
    stepIndex: profile.stepIndex,
})
export default connect(mapStateToProps)(ChooseAssay)

export const ChooseAssayContent = (props) => {
    const { selections, onClickViewCaseStudy, onClickBuildAnother } = props
    const { contentHeight } = useSubpageLayout()
    const isPortrait = useIsPortrait()

    const assayOptions = [...allAssays.entries()]
        .map(([id, assay]) => {
            return selectionsMatchRules(selections, assay.rules) ? id : null
        })
        .filter(Boolean) as string[]
    const firstAssayNode = nodes.get(assayOptions[0])

    const caseStudyOptions = [...allCaseStudies.entries()]
        .map(([id, caseStudy]) => {
            return selectionsMatchRules(selections, caseStudy.rules) ? id : null
        })
        .filter(Boolean) as string[]
    const hasCaseStudy = caseStudyOptions.length > 0
    const handleClickViewCaseStudy = hasCaseStudy
        ? onClickViewCaseStudy
        : undefined

    const [selectedOption, setSelectedOption] = useState(firstAssayNode)

    if ((!selections && selections.length === 0) || !selectedOption) {
        return null
    }

    const { media: assayMedia } = selectedOption
    const AssayComponent =
        typeof assayMedia === 'string'
            ? () => (
                  <ZoomableImage
                      preview={assayMedia}
                      src={assayMedia}
                      className='f-gs-auto'
                      sx={{
                          height: isPortrait
                              ? 'calc(100% - 12rem)'
                              : 'calc(100% - 4rem) !important',
                          p: 0,
                          overflow: 'hidden',
                          width: '100%',
                          img: {
                              width: '100%',
                              objectFit: 'contain',
                              height: 'calc(100% - 4rem)',
                          },
                      }}
                  />
              )
            : assayMedia

    const workflow = allAnalytes.get(selections[0])
    if (!workflow) {
        return null
    }
    const workflowLabel = workflow.label

    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <div
                sx={{
                    display: 'grid',
                    gridTemplateColumns: ['10rem 1fr', null, null, '14rem 1fr'],
                    gridTemplateRows: isPortrait
                        ? `calc(${contentHeight}px - 26ex)`
                        : `calc(${contentHeight}px - 6ex)`,
                }}
            >
                <div
                    className='f-col f-none'
                    sx={{
                        height: '100%',
                    }}
                >
                    <p
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '14px',
                            lineHeight: '14px',
                            fontWeight: 'bold',
                            margin: '1rem 0',
                        }}
                    >
                        {workflowLabel} Profile Selection
                    </p>
                    <div
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            height: isPortrait
                                ? 'calc(100% - 14px)'
                                : 'calc(100% - 2rem - 14px)',
                            border: '3px solid',
                            borderColor: 'mutedBackground',
                            padding: '0.8rem',
                            borderRadius: '4px',
                        }}
                    >
                        {selections.map((optionId: string, i: number) => {
                            if (i > 2 || !optionId) return null
                            const option = nodes.get(optionId)
                            if (!option) {
                                return <Fragment />
                            }
                            const { media } = option
                            const Icon =
                                typeof media === 'string'
                                    ? () => (
                                          <motion.img
                                              src={media}
                                              sx={{
                                                  width: 'auto',
                                                  height: 'auto',
                                                  maxHeight: '100%',
                                                  maxWidth: '100%',
                                              }}
                                          />
                                      )
                                    : media
                            return (
                                <div
                                    key={`selected-${optionId}`}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        height: '33%',
                                        maxHeight: isPortrait
                                            ? '14rem'
                                            : undefined,
                                    }}
                                >
                                    <span
                                        sx={{
                                            alignSelf: 'start',
                                            width: '100%',
                                            color: 'rgb(25, 73, 131)',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            mb: '1ex',
                                        }}
                                    >
                                        {` ${i + 1}. ${getResultsLabel(
                                            i,
                                            option.label,
                                            workflow
                                        )}`}
                                    </span>
                                    <div
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            overflow: 'hidden',
                                            mb: i == 2 ? 0 : '1rem',
                                            '& > svg': {
                                                maxWidth:
                                                    i > 0 ? '85%' : 'unset',
                                            },
                                            '& > img': {
                                                maxWidth:
                                                    i > 0 ? '80%' : 'unset',
                                            },
                                        }}
                                    >
                                        {Icon && <Icon />}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div
                    className='f-col f-gs-auto'
                    sx={{
                        alignItems: 'center',
                        height: 'calc(100% - 1rem)',
                        marginLeft: '12px',
                        marginTop: '1rem',
                        marginBottom: isPortrait ? '1rem' : 0,
                        overflow: 'hidden',
                        borderRadius: '6px',
                        backgroundColor: 'mutedBackground',
                    }}
                >
                    <AnimatePresence>
                        {assayOptions.length > 1 && (
                            <motion.div
                                sx={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                                {...fadeAnimations}
                            >
                                {getAssayChoiceButtons(
                                    assayOptions,
                                    setSelectedOption,
                                    selectedOption
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {AssayComponent && <AssayComponent />}

                    {!isPortrait && (
                        <Fragment>
                            <div className='f-gs-auto' sx={{ lineHeight: 0 }}>
                                &nbsp;
                            </div>

                            <ButtonRow
                                sx={{
                                    padding: '0 1rem',
                                }}
                                onClickBuildAnother={onClickBuildAnother}
                                onClickViewCaseStudy={handleClickViewCaseStudy}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
            {isPortrait && (
                <ButtonRow
                    sx={{
                        marginTop: '1rem',
                        // Avoid the Back button in portrait.
                        padding: '0 1rem 0 16rem',
                    }}
                    onClickBuildAnother={onClickBuildAnother}
                    onClickViewCaseStudy={handleClickViewCaseStudy}
                />
            )}
        </motion.div>
    )
}

const ButtonRow = (props) => {
    const { className, onClickBuildAnother, onClickViewCaseStudy } = props

    return (
        <div
            className={clsx('f-row f-none', className)}
            sx={{
                width: '100%',
                justifyContent: 'space-evenly',
                height: '3rem',
                marginBottom: '1rem',
            }}
        >
            <ButtonWithReset size='sm' onClick={onClickBuildAnother}>
                Build Another Profile
            </ButtonWithReset>
            {onClickViewCaseStudy && (
                <ViewCaseStudyButton onClick={onClickViewCaseStudy} />
            )}
        </div>
    )
}

const ViewCaseStudyButton = (props) => {
    const { onClick } = props

    return (
        <ButtonWithArrow size='sm' onClick={onClick}>
            View Your Case Study
        </ButtonWithArrow>
    )
}

const getResultsLabel = (i: number, label: string, workflow: StepNode) => {
    switch (i) {
        case 0:
            return label + ' Analyte'
        case 1:
            return workflow.id === 'rna' ? 'RNA Sample' : 'Protein Sample'
        case 2:
            return label
        default:
            return ''
    }
}
