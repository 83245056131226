/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'
import { Fragment } from 'react'

const img1 = '/images/WholeTranscriptomeAtlasIconHuman.png'
const img2 = '/images/WholeTranscriptomeAtlasIconMouse.png'

const Wta = () => {
    const isPortrait = useIsPortrait()
    return isPortrait ? <WtaPortrait /> : <WtaLandscape />
}
const heading =
    'GeoMx Whole Transcriptome Atlases: over 22,000 spatially resolved RNAs'
const boxText = (
    <Fragment>
        <p>
            Whole Transcriptome Coverage: probes specific to protein coding mRNA
            sequences
        </p>
        <ul>
            <li>
                Superior sensitivity: Detects more genes per sample and
                quantitates low, medium and high expressed genes
            </li>
            <li>
                Any spatial feature: Antibody or RNAscope defined regions of
                interest
            </li>
            <li>
                Streamline workflows: Designed for FFPE or Fresh Frozen samples
                for human and FFPE or Fixed Frozen for mouse
            </li>
            <li>
                Customization: detect non protein coding genes, synthetic
                targets and exogenous genes along with the transcriptome
            </li>
            <li>Up to 60 custom targets</li>
            <li>Minimal sequencing: ~100M reads/sample for 12 regions</li>
        </ul>
    </Fragment>
)

const WtaPortrait = () => {
    return (
        <div
            className='f-col'
            sx={{
                padding: ['1rem', '1rem', '2rem', null, '3rem'],
                justifyContent: 'center',
                h1: {
                    fontSize: ['1rem', null, null, '2.25rem', null],
                    margin: ['1rem', null, null, null, '2rem 0'],
                },
                '& p, ul': {
                    fontSize: ['0.75rem', null, '0.9rem', '1.5rem'],
                    lineHeight: [1, null, null, 1.25],
                },
                maxWidth: ['90%', null, null, null, '100%'],
                ul: {
                    ml: '1rem',
                },
                li: {
                    mb: '1ex',
                },
            }}
        >
            <h1>{heading}</h1>
            {boxText}
            <div
                className='f-row'
                sx={{
                    width: '100%',
                    justifyContent: 'center',
                    mt: ['1rem', null, null, '3rem'],
                    gap: '2rem',
                }}
            >
                <img
                    sx={{
                        height: 'auto',
                        width: ['120px', null, null, '270px'],
                    }}
                    src={img1}
                    alt=''
                />

                <img
                    sx={{
                        height: 'auto',
                        width: ['120px', null, null, '270px'],
                    }}
                    src={img2}
                    alt=''
                />
            </div>
        </div>
    )
}
const WtaLandscape = () => {
    return (
        <div
            sx={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <div
                className='f-col'
                sx={{
                    ml: '2rem',
                    mt: '3rem',
                    width: '18%',
                }}
            >
                <img
                    src={img1}
                    alt=''
                    sx={{
                        width: '100%',
                        mb: '1rem',
                    }}
                />
                <img
                    src={img2}
                    alt=''
                    sx={{
                        width: '100%',
                    }}
                />
            </div>
            <div
                className='f-col'
                sx={{
                    justifyContent: 'center',
                    width: '68%',
                    ml: '2rem',
                    h1: {
                        fontSize: ['1.15rem', null, null, '1.25rem', '1.5rem'],
                        margin: '1rem 0',
                    },
                    '& p, ul': {
                        fontSize: ['0.9rem', null, null, '1rem', null],
                        lineHeight: [1, null, null, 1.25],
                    },
                    ul: {
                        ml: '1rem',
                    },
                    li: {
                        mb: '0.5ex',
                    },
                }}
            >
                <h1>{heading}</h1>
                {boxText}
            </div>
        </div>
    )
}
export default Wta
