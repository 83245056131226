/** @jsx jsx */
import { jsx } from 'theme-ui'
import { MouseEventHandler } from 'react'
import { motion } from 'framer-motion'
import NavArrow from '/assets/icons/nav-arrow.inline-svg'

interface Props {
    className?: string
    onClick?: MouseEventHandler
}

const BackBtn = (props: Props) => {
    const { onClick, className } = props
    return (
        <motion.button
            className={className}
            sx={{
                ['WebkitTapHighlightColor' as any]: 'transparent',
                display: 'flex',
                alignItems: 'center',
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: '24px',
                color: 'black',
            }}
            onClick={onClick}
        >
            <NavArrow
                sx={{
                    width: 'auto',
                    height: '16px',
                    marginTop: '-2px',
                    transform: 'rotate(180deg)',
                    path: {
                        fill: 'black',
                    },
                }}
            />
            <div>&nbsp;Back</div>
        </motion.button>
    )
}
export default BackBtn
