/** @jsx jsx */
import { useRef } from 'react'
import { jsx } from 'theme-ui'
import { useHistory } from 'react-router'

import type { Page } from '/store/pages/types'
import {
    analyticsPush,
    eventButtonClick,
} from '~nanostring-brand-elements/components/analytics'

import { useIsHoverNo } from '~yipkos-common/utils/mediaQueries'

import Bg from './assets/explore-menu.inline-svg'

const EVENT_ACTIONS = {
    'spatial-profiling': 'spatialProfilingButton',
    'pathway-analysis': 'pathwayAnalysisButton',
    'biomarker-discovery': 'biomarkerDiscoveryButton',
    'spatial-typing': 'spatialCellTypingButton',
}

interface Props {
    className?: string
    pages: Page[]
}

const ExploreDataInteractiveMenu = (props: Props) => {
    const { className, pages } = props
    const history = useHistory()
    const isHoverNo = useIsHoverNo()
    const initialized = useRef(false)

    const onMount = (el) => {
        if (!el || initialized.current) {
            return
        }
        initialized.current = true
        const bg = document.getElementById('explore-menu-bg')
        if (bg) {
            setTimeout(() => {
                bg.style.opacity = '1'
            }, 400)
        }
        let delay = 700
        for (const page of pages) {
            const bits = page.path.split('/')
            const pageId = bits[bits.length - 1]
            const btnId = `explore-menu-btn-${pageId}`
            const coneId = `explore-menu-cone-${pageId}`
            const lblId = `explore-menu-lbl-${pageId}`
            const btn = document.getElementById(btnId) as SVGGElement | null
            const cone = document.getElementById(coneId) as SVGGElement | null
            const lbl = document.getElementById(lblId) as SVGGElement | null
            if (btn && cone && lbl) {
                setTimeout(() => {
                    btn.style.opacity = '1'
                    cone.style.opacity = '1'
                }, delay)
                setTimeout(() => {
                    lbl.style.opacity = '1'
                }, delay + 300 * 4)
            }
            let clickableEl: SVGGElement | null = null
            const rolloverId = `explore-menu-rollover-${pageId}`
            const rollEl = document.getElementById(
                rolloverId
            ) as SVGGElement | null
            if (isHoverNo) {
                if (rollEl) {
                    rollEl.style.pointerEvents = 'none'
                }
                clickableEl = btn
            } else {
                if (rollEl) {
                    rollEl.style.pointerEvents = 'all'
                }
                clickableEl = rollEl
            }
            if (clickableEl) {
                ;(clickableEl.style as any).WebkitTapHighlightColor =
                    'transparent'
                clickableEl.addEventListener('click', () => {
                    analyticsPush({
                        eventSection: 'explore',
                        event: EVENT_ACTIONS[pageId],
                        eventValue: eventButtonClick,
                    })
                    history.push(page.path)
                })
            }
            delay += 300
        }
    }

    return (
        <div
            ref={onMount}
            sx={{
                position: 'relative',
                display: 'flex',
                width: '100%',
                height: '100%',
            }}
            className={className}
        >
            <Bg sx={{ width: '100%', height: '100%' }} />
        </div>
    )
}

export default ExploreDataInteractiveMenu
