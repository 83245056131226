/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import fadeAnimations from '~yipkos-common/animations/fade'

import ZoomableImage from '/components/global/ZoomableImage'

const segmentationImg1 = '/images/segmentation 1.png'
const segmentationImg2 = '/images/segmentation 2.png'

const ExploreSpatialProfilingPage = () => {
    const isPortrait = useIsPortrait()

    return (
        <div
            className='f-col-c-100'
            sx={{
                height: '100%',
            }}
        >
            <div
                className='f-none'
                sx={{
                    maxWidth: isPortrait ? '80%' : '100%',
                }}
            >
                <motion.h2
                    {...fadeAnimations}
                    sx={{
                        textAlign: 'center',
                        marginTop: isPortrait ? '5vh' : 0,
                        fontSize: isPortrait ? '45px' : 'auto',
                    }}
                >
                    Spatial Profiling Application
                </motion.h2>
                <motion.h4
                    {...fadeAnimations}
                    sx={{
                        textAlign: 'center',
                        fontSize: isPortrait ? '36px' : 'auto',
                        paddingTop: isPortrait ? '20px' : '0',
                    }}
                >
                    Biology Based Segmentation Enables near 100% Cellularity
                </motion.h4>
            </div>
            <motion.div
                className='f-s-auto'
                {...fadeAnimations}
                sx={{
                    mt: 'max(4ex, 5vh)',
                    width: '100%',
                    height: isPortrait
                        ? undefined
                        : 'max(310px, calc(100vh - 86ex))',
                }}
            >
                <div
                    className='f-row-center'
                    sx={{
                        height: '100%',
                        gap: 20,
                        padding: isPortrait ? '0 4%' : '0',
                    }}
                >
                    <div
                        sx={{
                            width: '34vw', // Keeps this image in proportion with the one next to it.
                        }}
                    >
                        <ZoomableImage
                            preview={segmentationImg1}
                            src={segmentationImg1}
                        />
                    </div>
                    <ZoomableImage
                        preview={segmentationImg2}
                        src={segmentationImg2}
                    />
                </div>
            </motion.div>
            <DescText />
        </div>
    )
}
export default ExploreSpatialProfilingPage

const DescText = () => {
    const isPortrait = useIsPortrait()

    return (
        <div
            className='f-none'
            sx={{
                lineHeight: '23px',

                fontSize: [null, null, null, '24px'],
                margin: isPortrait
                    ? ['4ex 1rem', null, null, '6ex 2rem']
                    : '4ex 1rem',
            }}
        >
            GeoMx DSP allows segmentation of the tumor and TME, without this
            then all you will observe is an average signal inhibiting your
            ability to investigate tumor or immune biology and only explore
            average biology. Established tumor / immune markers are highly
            specific to tumor or TME segments. However, most canonical driver
            genes and immuno-oncology genes are expressed by both tumor and TME.
            Only with segmented profiling can we attribute their expression to
            tumor or TME.
        </div>
    )
}
