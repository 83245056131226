/** @jsx jsx */

import { jsx } from 'theme-ui'

import DialogBackdrop from '~yipkos-common/components/navigation/DialogBackdrop'

import { zIndexModalBackdrop } from '../../theme/z-indexes'

export interface Props {
    className?: string
    isOpen: boolean
    onClick?: () => void
}

const NSTGDialogBackdrop = (props) => {
    // noinspection HtmlUnknownTarget
    return (
        <DialogBackdrop
            {...props}
            zIndex={zIndexModalBackdrop}
            background='rgba(0, 0, 0, 0.4)'
            backdropFilter='none'
        />
    )
}
export default NSTGDialogBackdrop
