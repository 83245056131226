/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { setModalSlides } from '/store/layout'

interface Props {
    className?: string
    src: string | React.FC
    preview?: string

    withoutHeight?: boolean
    withoutWidth?: boolean
}

const PreviewImg: React.FC<{ src: string; className?: string }> = ({
    src,
    className,
}) => {
    return (
        <img
            alt=''
            src={src}
            sx={{
                objectFit: 'contain',
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
            }}
            className={className}
        />
    )
}

const ZoomableImage: React.FC<Props> = ({
    className,
    src,
    preview,
    withoutHeight,
    withoutWidth,
}): React.ReactElement => {
    const dispatch = useDispatch()
    return (
        <motion.button
            className={className}
            sx={{
                position: 'relative',
                width: withoutWidth ? undefined : preview ? '100%' : '1.2rem',
                height: withoutHeight ? undefined : preview ? '100%' : '1.2rem',
                p: preview ? 0 : '.5rem',
            }}
            onClick={() => dispatch(setModalSlides([src]))}
        >
            {preview && <PreviewImg src={preview} />}
        </motion.button>
    )
}

export default ZoomableImage
