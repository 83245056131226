/** @jsx jsx */
import { Fragment } from 'react'
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useSubpageLayout } from '/components/global/SubpageLayoutContext'

import ContourDetail1 from '/content/profile/media/regions/contour-detail1.png'
import ContourDetail2 from '/content/profile/media/regions/contour-detail2.png'

const ContourProfilingDetail = () => {
    const { contentHeight } = useSubpageLayout()
    const isPortrait = useIsPortrait()

    return (
        <Fragment>
            <div
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <h3
                    sx={{
                        color: '#d97845',
                        fontSize: '18px',
                        marginBottom: '1rem',
                        textAlign: 'center',
                    }}
                >
                    Radiating ROIs surrounding macrophages show distinct
                    expression profiles based on proximity
                </h3>
            </div>
            <motion.div
                sx={{
                    flex: '1 1 auto',
                    display: 'flex',
                    flexDirection: isPortrait ? 'column' : 'row',
                    columnGap: '2rem',
                    alignItems: 'center',
                    borderRadius: 0,
                    paddingBottom: isPortrait ? '2ex' : 0,
                    overflow: 'hidden',
                }}
            >
                <div
                    className='f-center'
                    sx={{
                        flex: 1,
                        height: `calc(${contentHeight}px - 10rem)`,
                    }}
                >
                    <motion.img
                        alt=''
                        src={ContourDetail1}
                        sx={{
                            width: 'auto',
                            objectFit: 'contain',
                            maxHeight: ['70%', null, null, '84%'],
                            alignItems: 'center',
                        }}
                        {...fadeAnimations}
                    />
                </div>
                <div
                    sx={{
                        flex: 1,
                        height: `calc(${contentHeight}px - 10rem)`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <motion.img
                        alt=''
                        src={ContourDetail2}
                        sx={{
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '84%',
                            maxHeight: `calc(${contentHeight}px - 11rem)`,
                            objectFit: 'contain',
                        }}
                        {...fadeAnimations}
                    />
                </div>
            </motion.div>
        </Fragment>
    )
}
export default ContourProfilingDetail
