import { createContext, useCallback, useContext, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'

interface SubpageLayoutContextProps {
    contentWidth: number
    contentHeight: number
    setContentSize: (width: number, height: number) => void
}

export const SubpageLayoutContext = createContext<SubpageLayoutContextProps>({
    contentWidth: 0,
    contentHeight: 0,
    setContentSize: () => {},
})
SubpageLayoutContext.displayName = 'SubpageLayoutContext'

export const useSubpageLayout = (): SubpageLayoutContextProps => {
    return useContext(SubpageLayoutContext)
}

export const createSubpageLayoutContext = (): SubpageLayoutContextProps => {
    const [contentWidth, setContentWidth] = useState(0)
    const [contentHeight, setContentHeight] = useState(0)

    const setContentSize = useCallback(
        (w, h) => {
            unstable_batchedUpdates(() => {
                setContentWidth(w)
                setContentHeight(h)
            })
        },
        [setContentHeight, setContentWidth]
    )

    return {
        contentWidth,
        contentHeight,
        setContentSize,
    }
}
