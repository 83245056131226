/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useCallback, MouseEventHandler } from 'react'
import clsx from 'clsx'
import { useHistory, useLocation } from 'react-router'
import { AnimatePresence } from 'framer-motion'

import {
    analyticsPush,
    eventButtonClick,
} from '~nanostring-brand-elements/components/analytics'
import {
    breakpointSmallDesktop,
    useBreakpointBelow,
} from '~nanostring-brand-elements/theme/breakpoints'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import BackBtn from '/components/global/BackBtn'
import { useSubpageLayout } from '/components/global/SubpageLayoutContext'

interface Props {
    children?: React.ReactNode
    className?: string
    hasBackButton?: boolean // If set, equivalent to onClickBack={() => history.goBack()}
    onClickBack?: MouseEventHandler
}

const SubpageLayout = (props: Props) => {
    const { children, className, hasBackButton, onClickBack } = props
    const history = useHistory()
    const location = useLocation()
    const { pathname } = location
    const { setContentSize } = useSubpageLayout()
    const isPortrait = useIsPortrait()
    const isSmallDesktop = useBreakpointBelow(breakpointSmallDesktop)

    const isProfile = pathname.startsWith('/profile')

    const stepperPaddingLeft = isPortrait || !isProfile ? 0 : 55

    const onResizeEl = useCallback(
        (el) => {
            const r = el.getBoundingClientRect()
            // Compensated for padding on the div below and room for the
            // stepper if it's on the left.
            setContentSize(
                r.width -
                    stepperPaddingLeft -
                    (isSmallDesktop ? 20 + 30 : 40 + 40),
                r.height - (isSmallDesktop ? 30 + 30 : 40 + 40)
            )
        },
        [isSmallDesktop, setContentSize]
    )

    const onResize = useCallback(
        (observers: ResizeObserverEntry[]) => {
            for (const ob of observers) {
                onResizeEl(ob.target)
            }
        },
        [onResizeEl]
    )

    const onMount = (el) => {
        if (!el) {
            return
        }

        const resizeObserver = new ResizeObserver(onResize)
        resizeObserver.observe(el)

        return () => resizeObserver.unobserve(el)
    }

    const handleClickBack =
        hasBackButton || onClickBack
            ? (ev) => {
                  analyticsPush({
                      eventSection:
                          pathname.split('/')[1] === 'explore'
                              ? 'explore'
                              : 'backNavigation',
                      event: 'backButton',
                      eventValue: eventButtonClick,
                  })

                  if (onClickBack) {
                      onClickBack(ev)
                  } else {
                      ev.preventDefault()
                      ev.stopPropagation()
                      history.goBack()
                  }
              }
            : null

    return (
        <div
            className={clsx(
                'SubpageLayout', // For bug-workaround code to be able to find this element.
                className
            )}
            ref={onMount}
            sx={{
                position: 'relative',
                boxSizing: 'border-box',
                margin: ['0 40px', null, null, null, null, '0 auto'],
                maxWidth: 1440,
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'hidden',
                borderRadius: '8px',
                background: 'white',
                padding: isSmallDesktop
                    ? `30px 30px 30px ${stepperPaddingLeft + 20}px`
                    : `40px 40px 40px ${stepperPaddingLeft + 40}px`,
            }}
        >
            {handleClickBack && (
                <BackBtn
                    sx={{
                        position: 'absolute',
                        left: '48px',
                        top: isPortrait ? undefined : '24px',
                        bottom: isPortrait ? '48px' : undefined,
                        zIndex: 3,
                        backgroundColor: 'white',
                    }}
                    onClick={handleClickBack}
                />
            )}
            <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
        </div>
    )
}
export default SubpageLayout
