/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import { AnimatePresence, motion } from 'framer-motion'

import { preloadImages } from '~yipkos-common/utils/image-utils'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { nodes, steps } from '/content/profile'
import { Step } from '/content/profile/types'

import { ApplicationState } from '/store'
import { reset } from '/store/profile'
import * as profileActions from '/store/profile/actions'
import type { Page } from '/store/pages/types'

import fadeAnimations from '~yipkos-common/animations/fade'
import { Context as APIContext } from '/components/APIProvider'
import {
    analyticsPush,
    eventButtonClick,
} from '~nanostring-brand-elements/components/analytics'
import SubpageLayout from '/components/global/SubpageLayout'

import ChooseAnalyte from '/components/profile/ChooseAnalyte/ChooseAnalyte'
import ChooseSample from '/components/profile/ChooseSample/ChooseSample'
import ChooseRegionInteractiveMenu, {
    regionMenu,
    regionMenuCenterImg,
} from '/components/profile/ChooseRegionInteractiveMenu/ChooseRegionInteractiveMenu'
import ChooseAssay from '/components/profile/ChooseAssay'
import ProfileBuilderHeader from '/components/profile/ProfileBuilderHeader'
import ProfileBuilderIntro from '/components/profile/ProfileBuilderIntro/ProfileBuilderIntro'
import ProfileResults from '/components/profile/ProfileResults/ProfileResults'
import WorkflowROI from '/components/profile/Workflow/WorkflowROI'

import {
    step3Subhead,
    SubheadH4,
} from '/components/profile/ProfileBuilderHeader/ProfileBuilderHeader'

import CaseStudyFrame from './CaseStudyFrame'
import StepThumbs from './StepThumbs/StepThumbs'

interface Props {
    steps: Step[]
    stepIndex: number
    maxIndex: number
    selections: string[]
    globalPages: Page[]
    isShowingWorkflow: boolean
}

export const initialPageState = {
    pages: [
        ...steps.map((step) => {
            return {
                path: `/profile/${step.id}`,
                label: step.label,
                heading: '',
                subhead: '',
            }
        }),
    ],
    pageIndex: 0,
    previousPage: null,
    nextPage: null,
}

const ChooseSampleAndROI = (props) => {
    const { isROI, selections, steps, stepIndex, onChange, ...rest } = props
    const dispatch = useDispatch()
    const [epoch, setEpoch] = useState(1)
    const isPortrait = useIsPortrait()
    const analyte = nodes.get(selections[0])

    const {
        options: prevOptions,
        availableOptions: prevAvailableOptions,
    } = steps[isROI ? stepIndex - 1 : stepIndex]

    const chooseSampleOnChange = (optionId: string) => {
        if (isROI) {
            dispatch(profileActions.setStepSelection(stepIndex - 1, optionId))
            setEpoch(epoch + 1)
        } else {
            onChange(optionId)
        }
    }

    return (
        <div
            sx={{
                display: 'grid',
                gridTemplateColumns: isPortrait ? undefined : '1fr 1fr',
                gridTemplateRows: isPortrait ? '1fr 1.3fr' : undefined,
                position: 'relative',
                width: '100%',
            }}
        >
            <ChooseSample
                key={epoch}
                {...rest}
                onChange={chooseSampleOnChange}
                options={prevOptions}
                availableOptions={prevAvailableOptions}
            />
            <AnimatePresence>
                {isROI && (
                    <div className='f-col-c-100'>
                        {isPortrait && (
                            <SubheadH4>
                                <motion.span>Step 3: </motion.span>
                                {step3Subhead(analyte?.label || '')}
                            </SubheadH4>
                        )}
                        <ChooseRegionInteractiveMenu
                            {...rest}
                            onChange={onChange}
                        />
                    </div>
                )}
            </AnimatePresence>
        </div>
    )
}

const stepComponents = [
    ChooseAnalyte,
    (props) => <ChooseSampleAndROI {...props} isROI={false} />,
    (props) => <ChooseSampleAndROI {...props} isROI />,
    WorkflowROI,
    ChooseAssay,
    ProfileResults,
]
Object.freeze(stepComponents)

const ProfileBuilder: React.FC<Props> = ({
    steps,
    stepIndex = -1,
    maxIndex,
    selections,
    isShowingWorkflow,
}): React.ReactElement => {
    const globalDispatch = useDispatch()
    const { enterPage } = useContext(APIContext)

    const pageIndex = stepIndexToPageIndex(stepIndex)
    const prevPageStepIndex = pageIndexToStepIndexGoingBack(pageIndex - 1)

    const setPage = (newStepIndex: number) => {
        // console.log('SET PAGE', newStepIndex, maxIndex)
        globalDispatch(profileActions.setStepIndex(newStepIndex))
    }

    useEffect(() => {
        if (stepIndex === 0) {
            // Trigger a preload for these large images before they are needed.
            preloadImages([regionMenu, regionMenuCenterImg], 500)
        }
    }, [stepIndex])

    useEffect(() => {
        enterPage(`/profile/step${stepIndex}`, selections.toString())
    }, [pageIndex, selections, maxIndex, stepIndex])

    const onReset = () => {
        analyticsPush({
            eventSection: 'profileResults',
            event: 'profileBuildAnother',
            eventValue: eventButtonClick,
        })
        globalDispatch(reset())
    }

    return (
        <ProfileBuilderFrame
            stepIndex={stepIndex}
            selections={selections}
            setPage={setPage}
            maxIndex={maxIndex}
            isShowingWorkflow={isShowingWorkflow}
            onClickBack={
                stepIndex === -1 ? undefined : () => setPage(prevPageStepIndex)
            }
            onReset={onReset}
        >
            {stepIndex === -1 ? (
                <ProfileBuilderIntro
                    sx={{
                        flex: '1 1 auto',
                    }}
                    onUserStart={() => {
                        analyticsPush({
                            eventSection: 'profileBuild',
                            event: 'start',
                            eventValue: eventButtonClick,
                        })
                        setPage(0)
                    }}
                />
            ) : (
                <CurrentStep
                    selections={selections}
                    steps={steps}
                    stepIndex={stepIndex}
                />
            )}
        </ProfileBuilderFrame>
    )
}

export const ProfileBuilderFrame = (props) => {
    const {
        stepIndex,
        selections,
        setPage,
        maxIndex,
        isShowingWorkflow,
        children,
        onClickBack,
        onReset,
    } = props
    const { pages } = initialPageState
    const isPortrait = useIsPortrait()

    const isCaseStudy = stepIndex === 5

    const stepper =
        stepIndex !== -1 ? (
            <StepThumbs
                pageIndex={stepIndex}
                setPageIndex={setPage}
                pages={pages}
                maxIndex={maxIndex}
            />
        ) : null

    const header = (
        <ProfileBuilderHeader
            stepIndex={stepIndex}
            selections={selections}
            isShowingWorkflow={isShowingWorkflow}
            stepper={isPortrait && stepper}
            sx={{
                ml: isCaseStudy ? '1.5rem' : undefined,
            }}
        />
    )

    return (
        <SubpageLayout
            onClickBack={onClickBack}
            hasBackButton={onClickBack || stepIndex !== -1}
        >
            {!isPortrait && stepper}
            {isCaseStudy ? (
                <CaseStudyFrame header={header} onReset={onReset}>
                    {children}
                </CaseStudyFrame>
            ) : (
                <Fragment>
                    {header}
                    {children}
                </Fragment>
            )}
        </SubpageLayout>
    )
}

export const CurrentStep = (props) => {
    const { selections, steps, stepIndex } = props
    const currentStep = steps[stepIndex]
    const { options, availableOptions } = currentStep
    const StepComponent = stepComponents[stepIndex]
    const globalDispatch = useDispatch()

    return (
        <StepComponent
            key={`step-${stepIndex}`}
            options={options}
            availableOptions={availableOptions}
            selections={selections}
            steps={steps}
            stepIndex={stepIndex}
            {...fadeAnimations}
            onChange={(optionId: string) => {
                globalDispatch(
                    profileActions.setStepSelection(stepIndex, optionId)
                )
                globalDispatch(profileActions.setStepIndex(stepIndex + 1))
            }}
        />
    )
}

const mapStateToProps = ({ profile }: ApplicationState) => ({
    steps: profile.steps,
    stepIndex: profile.stepIndex,
    maxIndex: profile.maxIndex,
    selections: profile.selections,
    isShowingWorkflow: profile.isShowingWorkflow,
})

export default connect(mapStateToProps)(ProfileBuilder)

const stepIndexToPageIndex = (stepIndex: number) => {
    switch (stepIndex) {
        case -1:
            return -1
        case 0:
            return 0
        case 1:
            return 1
        default:
            return stepIndex - 1
    }
}

const pageIndexToStepIndexGoingBack = (pageIndex: number) => {
    switch (pageIndex) {
        case -1:
            return -1
        case 0:
            return 0
        case 1:
            return 2
        default:
            return pageIndex + 1
    }
}
