/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import { motion } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'

import { analyticsPush } from '~nanostring-brand-elements/components/analytics'

import { CaseStudyProps } from '/content/profile/types'

import ZoomableImage from '/components/global/ZoomableImage'

import img1 from './crc-contour-detail1.jpg'

const FrontPage: React.FC = (): React.ReactElement => {
    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <h3 sx={{ textAlign: 'center', marginTop: '1rem' }}>
                Explore pathway signaling across complex tissue structures and
                tumor boundaries
            </h3>
            <div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    padding: '2rem',
                }}
            >
                <div
                    sx={{
                        textAlign: 'center',
                        fontSize: '.7rem',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <ZoomableImage
                        preview={img1}
                        src={img1}
                        sx={{ width: '100%' }}
                    />
                </div>
                <h4
                    sx={{
                        color: 'black',
                        textAlign: 'center',
                        fontWeight: 300,
                        paddingX: '20%',
                    }}
                >
                    The Cancer Transcriptome Atlas with 1800+ genes was used to
                    explore every contour along the tumor-immune boundary.
                </h4>
            </div>
        </motion.div>
    )
}

const CaseStudy: React.FC<CaseStudyProps> = ({
    className,
}): React.ReactElement => {
    useEffect(() => {
        analyticsPush({
            eventSection: 'caseStudyViewed',
            event: 'crcContour',
            eventValue: 1,
        })
        analyticsPush({
            eventSection: 'caseStudyViewed',
            event: 'crcContour',
            eventValue: 'completed',
        })
    }, [])

    return (
        <motion.div
            {...fadeAnimations}
            className={className}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                border: '3px solid #f6f8e2',
            }}
        >
            <FrontPage />
        </motion.div>
    )
}

export default CaseStudy
