/** @jsx jsx */
import { jsx } from 'theme-ui'
import { MouseEventHandler } from 'react'
import { motion } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { analyticsPush, eventButtonClick } from './analytics'
import { zIndexHeader } from '../theme/z-indexes'

interface Props {
    className?: string
    height: number
    logoAlt: string
    logoSrc: string
    onClickLogo?: MouseEventHandler
}

const Header = (props: Props) => {
    const { className, height, logoAlt, logoSrc, onClickLogo } = props
    const isPortrait = useIsPortrait()

    const handleClick = onClickLogo
        ? (ev) => {
              analyticsPush({
                  eventSection: 'global',
                  event: 'logoGlobalButton',
                  eventValue: eventButtonClick,
              })
              onClickLogo(ev)
          }
        : undefined

    return (
        <header
            className={className}
            sx={{
                width: '100%',
                height: height,
                flex: `0 0 ${height}px`,
                maxWidth: [null, null, null, '1200px', '1440px'],
                m: '0 auto',
                zIndex: zIndexHeader,
            }}
        >
            <div sx={{ width: '100%', height: height - 70 }}>
                <motion.img
                    alt={logoAlt}
                    src={logoSrc}
                    onClick={handleClick}
                    sx={{
                        display: 'block',
                        width: 'auto',
                        height: height - 70,
                        m: isPortrait
                            ? '40px auto 30px auto'
                            : '40px 40px 30px 40px',
                        cursor: handleClick ? 'pointer' : undefined,
                    }}
                    {...fadeAnimations}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    exit={{ opacity: 0 }}
                />
            </div>
        </header>
    )
}
export default Header
