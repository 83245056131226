/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ReactNode } from 'react'
import { motion } from 'framer-motion'

import { zIndexFooter } from '../theme/z-indexes'

import nanoStringLogo from '../assets/images/logos/NanoString-logo.png'

interface Props {
    className?: string
    height: number
    copyright?: ReactNode
    nav?: ReactNode
}

const Footer = (props: Props) => {
    const { className, height, copyright, nav } = props

    return (
        <footer
            className={className}
            sx={{
                width: '100%',
                height,
                maxWidth: [null, null, null, '1200px', '1440px'],
                m: '0 auto',
                zIndex: zIndexFooter,
            }}
        >
            {nav}
            <div
                className='f-row'
                sx={{
                    height: '59px',
                    margin: '30px 40px 40px 40px',
                    alignItems: 'center',
                }}
            >
                <div
                    className='f-col'
                    sx={{
                        maxWidth: '1000px',
                    }}
                >
                    <ForResearchOnly />
                    {copyright}
                </div>
                <motion.img
                    src={nanoStringLogo}
                    sx={{
                        opacity: 0,
                        width: 'auto',
                        height: '59px',
                        m: '0 0 0 auto',
                    }}
                    animate={{ opacity: 1 }}
                />
            </div>
        </footer>
    )
}
export default Footer

const ForResearchOnly = () => (
    <p
        sx={{
            fontSize: '11px',
            fontWeight: '600',
            color: 'white',
        }}
    >
        FOR RESEARCH USE ONLY. Not for use with diagnostic procedures.
    </p>
)
