/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ReactNode, useEffect, useRef } from 'react'
import clsx from 'clsx'
import Slider from 'react-slick'
import { motion } from 'framer-motion'

import {
    breakpointTablet,
    useBreakpointBelow,
} from '~nanostring-brand-elements/theme/breakpoints'
import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { useSubpageLayout } from '/components/global/SubpageLayoutContext'

import { applySliderContainerBugfix } from './case-study-utils'

interface Props {
    className?: string
    title: string
    settings: any
    children: ReactNode
}

const CaseStudySlider = (props: Props) => {
    const { className, title, children, settings } = props
    const { contentWidth, contentHeight } = useSubpageLayout()
    const isPortrait = useIsPortrait()
    const isTablet = useBreakpointBelow(breakpointTablet)
    const rootElRef = useRef()

    useEffect(() => {
        const el = rootElRef.current
        if (!el) {
            return
        }
        applySliderContainerBugfix(
            el,
            contentWidth,
            contentHeight,
            isPortrait,
            isTablet
        )
    }, [contentWidth, contentHeight, isPortrait])

    const onMount = (el) => {
        rootElRef.current = el
    }

    return (
        <motion.div
            {...fadeAnimations}
            id='case_study_slider'
            className={clsx(className, 'f-col')}
            sx={{
                width: '100%',
                height: '100%',
                border: '3px solid',
                borderColor: 'mutedBackground',
            }}
        >
            <h4
                sx={{
                    textAlign: 'center',
                    mb: '0.5ex',
                    fontSize: '1.5rem',
                }}
            >
                {title}
            </h4>

            <div
                ref={onMount}
                className='fade-in'
                sx={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <Slider
                    {...settings}
                    sx={{
                        paddingBottom: '50px',
                    }}
                >
                    {children}
                </Slider>
            </div>
        </motion.div>
    )
}
export default CaseStudySlider
