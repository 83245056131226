/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import fadeAnimations from '~yipkos-common/animations/fade'

import imgPancABCells from '/content/profile/media/regions/pancreatic-AB-cells.png'
import imgPancABCellsVolcanoPlot from '/content/profile/media/regions/panc-AB-cells-volcano-plot.png'

const SegmentProfilingDetail = () => {
    const isPortrait = useIsPortrait()

    return isPortrait ? (
        <SegmentProfilingDetailPortrait />
    ) : (
        <SegmentProfilingDetailLandscape />
    )
}

const SegmentProfilingDetailPortrait = () => {
    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3rem',
                alignItems: 'center',
                height: '100%',
                mt: '3rem',
            }}
            {...fadeAnimations}
        >
            <p
                sx={{
                    maxWidth: '77%',
                    margin: '0 auto',
                    textAlign: 'center',
                    fontSize: '1.9rem',
                }}
            >
                Alpha cells and Beta cells from Pancreatic tissues are
                visualized. Alpha Cells are stained with antibodies against
                Glucagon and Beta cells are stained with antibodies against
                Insulin
            </p>

            <PancABCells />

            <p
                sx={{
                    maxWidth: '77%',
                    margin: '0 auto',
                    textAlign: 'center',
                    fontSize: '1.9rem',
                }}
            >
                WTA profiling of the alpha and beta cell ROIs reveals that the
                GCG (glucagon) gene in enriched in alpha cells, and the INS
                (insulin) and ABCC8 (insulin transport) genes are enriched in
                beta cells.
            </p>

            <PancABCellsVolcano />
        </motion.div>
    )
}

const SegmentProfilingDetailLandscape = () => {
    return (
        <motion.div
            sx={{
                flex: '1 1 auto',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '4fr 6fr',
                justifyItems: 'center',
                textAlign: 'center',
                alignItems: 'center',
                margin: '0 0 2rem 0',
            }}
            {...fadeAnimations}
        >
            <p
                sx={{
                    gridColumn: '1',
                    gridRow: '1',
                    maxWidth: '63%',
                    m: '0 auto',
                }}
            >
                Alpha cells and Beta cells from Pancreatic tissues are
                visualized. Alpha Cells are stained with antibodies against
                Glucagon and Beta cells are stained with antibodies against
                Insulin
            </p>

            <PancABCells
                sx={{
                    gridColumn: '1',
                    gridRow: '2',
                }}
            />

            <p
                sx={{
                    gridColumn: '2',
                    gridRow: '1',
                    maxWidth: '63%',
                    m: '0 auto',
                }}
            >
                WTA profiling of the alpha and beta cell ROIs reveals that the
                GCG (glucagon) gene in enriched in alpha cells, and the INS
                (insulin) and ABCC8 (insulin transport) genes are enriched in
                beta cells.
            </p>

            <PancABCellsVolcano
                sx={{
                    gridColumn: '2',
                    gridRow: '2',
                }}
            />
        </motion.div>
    )
}
export default SegmentProfilingDetail

const PancABCells = (props) => {
    const { className } = props
    return (
        <motion.img
            className={className}
            alt=''
            src={imgPancABCells}
            sx={{
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
            }}
            {...fadeAnimations}
        />
    )
}

const PancABCellsVolcano = (props) => {
    const { className } = props
    return (
        <motion.img
            className={className}
            alt=''
            src={imgPancABCellsVolcanoPlot}
            sx={{
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
            }}
            {...fadeAnimations}
        />
    )
}
