export const eventButtonClick = 'buttonClick'
export const eventFormSubmit = 'formSubmit'
export const eventModalClose = 'modalClose'
export const eventModalOpen = 'modalOpen'
export const eventModalDwell = 'modalDwell'

export const eventCategoryFormSubmissions = 'Form Submissions'
export const eventCategoryOutboundLinkClicks = 'Outbound Link Clicks'

// e.g. to be used with eventCategoryFormSubmissions
export const eventLabelFailure = 'Failure'
export const eventLabelSuccess = 'Success'

declare var dataLayer: [{}]

export const dataLayerPush = (evs) => {
    if (!evs) {
        return
    }

    if (Array.isArray(evs)) {
        for (const ev of evs) {
            dataLayerPush_(ev)
        }
    } else {
        dataLayerPush_(evs)
    }
}

const dataLayerPush_ = (ev) => {
    const location = window?.location
    if (!location) {
        return
    }

    if (
        location.href.startsWith('http://localhost') ||
        typeof dataLayer === 'undefined' ||
        !dataLayer
    ) {
        console.log('dataLayerPush', ev)
    } else {
        dataLayer.push(ev)
    }
}
