/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'
import { connect } from 'react-redux'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { nodes } from '/content/profile'
import { AnalyteIds } from '/content/profile/analytes'
import { ApplicationState } from '/store'

import RareCellRNAProfilingDetail from '/components/profile/DetailPanels/RareCellRNAProfilingDetail'

import assay from '/content/profile/media/regions/rare-detail-assay.png'
import stain1 from '/content/profile/media/regions/rare-detail-stain-1.png'
import stain2 from '/content/profile/media/regions/rare-detail-stain-2.png'
import stain3 from '/content/profile/media/regions/rare-detail-stain-3.png'

const RareCellProfilingDetail = (props) => {
    const { selections } = props
    const analyte = nodes.get(selections[0])

    return analyte?.id === AnalyteIds.PROTEIN ? (
        <RareCellProteinProfilingDetail />
    ) : (
        <RareCellRNAProfilingDetail />
    )
}
const mapStateToProps = ({ profile }: ApplicationState) => ({
    selections: profile.selections,
})
export default connect(mapStateToProps)(RareCellProfilingDetail)

const RareCellProteinProfilingDetail = () => {
    const isPortrait = useIsPortrait()
    return isPortrait ? (
        <RareCellProteinProfilingDetailPortrait />
    ) : (
        <RareCellProteinProfilingDetailLandscape />
    )
}

const RareCellProteinProfilingDetailPortrait = () => {
    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'hidden',
                gap: '17px',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <Header />
            <div
                sx={{
                    maxHeight: '19vh',
                }}
            >
                <Stain1 />
            </div>

            <div
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 20,
                    maxHeight: '19vh',
                }}
            >
                <Stain2 />
                <Stain3 />
            </div>
            <div
                sx={{
                    marginTop: '20px',
                    maxHeight: '25vh',
                }}
            >
                <Assay />
            </div>
        </motion.div>
    )
}

const RareCellProteinProfilingDetailLandscape = () => {
    return (
        <motion.div
            sx={{
                flex: '1 1 auto',
                display: 'flex',
                borderRadius: 0,
                overflow: 'hidden',
                pb: '2ex',
            }}
            {...fadeAnimations}
        >
            <div
                sx={{
                    height: '100%',
                    overflow: 'hidden',
                    flex: '1 1 50%',
                }}
            >
                <Header />
                <div
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '33% 33% 33%',
                        gridTemplateRows: '50% 50%',
                        gap: '8px',
                        width: 'calc(100% - 16px)',
                        height: 'calc(100% - 8ex)',
                    }}
                >
                    <div
                        sx={{
                            gridColumn: '1 / 3',
                            gridRow: '1 / 3',
                        }}
                    >
                        <Stain1 />
                    </div>
                    <div
                        sx={{
                            gridColumn: '3',
                            gridRow: '1',
                        }}
                    >
                        <Stain2 />
                    </div>
                    <div
                        sx={{
                            gridColumn: '3',
                            gridRow: '2',
                        }}
                    >
                        <Stain3 />
                    </div>
                </div>
            </div>
            <div
                sx={{
                    flex: '1 1 50%',
                    display: 'flex',
                }}
            >
                <Assay />
            </div>
        </motion.div>
    )
}

const Header = () => {
    return (
        <h3
            className='highlight'
            sx={{
                fontSize: ['14px !important', null, '20px !important', '18px'],
                my: ['1ex', '1.5ex', null, '2ex'],
                maxWidth: '30rem',
                marginX: 'auto',
                textAlign: 'center',
            }}
        >
            Isolated immune cell populations show unique expression profiles
        </h3>
    )
}

const Stain1 = () => {
    return (
        <motion.img
            alt=''
            src={stain1}
            sx={{
                objectFit: 'contain',
                objectPosition: 'right',
                width: 'calc(100% - 1rem)',
                height: 'calc(100% - 1rem)',
                m: '0.5rem',
            }}
            {...fadeAnimations}
        />
    )
}
const Stain2 = () => {
    return (
        <motion.img
            alt=''
            src={stain2}
            sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'left',
            }}
            {...fadeAnimations}
        />
    )
}
const Stain3 = () => {
    return (
        <motion.img
            alt=''
            src={stain3}
            sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'left',
            }}
            {...fadeAnimations}
        />
    )
}

const Assay = () => {
    const isPortrait = useIsPortrait()
    return (
        <motion.img
            alt=''
            src={assay}
            sx={{
                maxWidth: '100%',
                maxHeight: '94%',
                objectFit: 'contain',
                marginLeft: isPortrait ? 0 : '2vw',
            }}
            {...fadeAnimations}
        />
    )
}
