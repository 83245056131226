import steps from './steps'
import { allAnalytes } from './analytes'
import { allSamples } from './samples'
import { allRegions } from './regions'
import { allWorkflows } from './workflows'
import { allAssays } from './assays'
import { allCaseStudies } from './caseStudies'
import { StepNode } from './types'

export const nodes: Map<string, StepNode> = new Map([
    ...allAnalytes,
    ...allSamples,
    ...allRegions,
    ...allWorkflows,
    ...allAssays,
    ...allCaseStudies,
])
export { steps }

export function selectionsMatchRules(selections: string[], rules: string[][]) {
    return Boolean(rules.find((r) => selectionsMatchRule(selections, r)))
}

function selectionsMatchRule(selections: string[], rule: string[]) {
    for (let i = 0; i < selections.length; i++) {
        if (i < rule.length) {
            if (rule[i] !== selections[i]) {
                return false
            }
        } else {
            return true
        }
    }
    return true
}
