/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import { motion } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { analyticsPush } from '~nanostring-brand-elements/components/analytics'

import { CaseStudyProps } from '/content/profile/types'

import ZoomableImage from '/components/global/ZoomableImage'

import CaseStudySlider from './CaseStudySlider'

import img1 from './crc-geometric-detail1.jpg'
import img2 from './crc-geometric-detail2.jpg'
import img3 from './crc-geometric-detail3full.jpg'

const Page1 = () => {
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                textAlign: 'center',
                h4: { p: '1rem' },
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <h3 sx={{ color: 'black', fontSize: '1.5rem' }}>
                Immune Biology of Colorectal Tumors
            </h3>
            <div
                sx={{
                    display: 'flex',
                    flexDirection: isPortrait ? 'column' : 'row',
                    marginTop: isPortrait ? '10ex' : '3ex',
                    alignItems: isPortrait ? 'center' : 'stretch',
                }}
            >
                <div
                    sx={{
                        textAlign: 'center',
                        flex: 1,
                        mr: isPortrait ? 0 : '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <ZoomableImage
                        sx={{
                            p: '0 0 4px 0',
                            overflow: 'hidden',
                        }}
                        preview={img1}
                        src={img1}
                        withoutWidth
                    />
                    <span sx={{ fontSize: '1.2rem', padding: '0.5rem' }}>
                        colorectal tumors (CRC)
                    </span>
                </div>
                <div
                    sx={{
                        color: 'primary',
                        fontSize: '1rem',
                        textAlign: 'left',
                        flex: 1,
                        padding: isPortrait ? '7rem 2rem 0 2rem' : 0,
                    }}
                >
                    <p sx={{ color: 'black', fontSize: '1.4rem' }}>
                        DSP characterizes microsatellite stable (MSS) or
                        instable (MSI) colorectal tumors to differentiate immune
                        hot and cold regions of the tumors despite MSS status
                        using the the tumor inflammation signature (TIS) gene
                        set.
                    </p>
                </div>
            </div>
        </motion.div>
    )
}

const Page2 = () => {
    return (
        <motion.div
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            {...fadeAnimations}
        >
            <h3 sx={{ textAlign: 'center', fontSize: '1.5rem' }}>
                Various regions types were chosen throughout the CRC sample
            </h3>
            <div
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                }}
            >
                <ZoomableImage preview={img2} src={img2} withoutHeight />
                <p
                    sx={{
                        padding: '1rem',
                        textAlign: 'left',
                        fontSize: '1.5rem',
                    }}
                >
                    Each sample was profiled with 400 um diameter circular ROIs
                    in CD45-enriched locations: 5 in the invasive margin (IM)
                    and 4 in the tumor center (CT) as well as 3 in
                    pan-cytokeratin enriched (Tumor) locations.
                </p>
            </div>
        </motion.div>
    )
}

const Page3 = () => {
    const isPortrait = useIsPortrait()
    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                height: 'inherit',
                maxHeight: 'inherit',
            }}
            {...fadeAnimations}
        >
            <div sx={{ flex: 0, fontSize: '1.5rem' }}>
                <h3 sx={{ fontSize: '1.5rem' }}>RNA Immune Pathways</h3>
                <br />
                <h4 sx={{ color: 'black' }}>
                    Unique expression patterns of immune markers by location
                    within the TME
                </h4>
            </div>
            <div
                sx={{
                    flex: 1,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    img: { width: ['76%', null, null, '100%'] },
                    maxHeight: isPortrait ? '85%' : '78%',
                    marginTop: '2rem',
                    ml: ['20%', null, null, '0'],
                }}
            >
                <ZoomableImage preview={img3} src={img3} />
            </div>
        </motion.div>
    )
}

const CaseStudy: React.FC<CaseStudyProps> = ({
    className,
}): React.ReactElement => {
    useEffect(() => {
        analyticsPush({
            eventSection: 'caseStudyViewed',
            event: 'crcGeometric',
            eventValue: 1,
        })
    }, [])
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (pageIndex) => {
            analyticsPush({
                eventSection: 'caseStudyViewed',
                event: 'crcGeometric',
                eventValue: pageIndex + 1,
            })

            if (pageIndex === 2) {
                analyticsPush({
                    eventSection: 'caseStudyViewed',
                    event: 'crcGeometric',
                    eventValue: 'completed',
                })
            }
        },
    }

    return (
        <CaseStudySlider
            className={className}
            settings={settings}
            title='Endpoint: CRC Geometric'
        >
            <Page1 />
            <Page2 />
            <Page3 />
        </CaseStudySlider>
    )
}

export default CaseStudy
