/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { default as FooterBase } from '~nanostring-brand-elements/components/Footer'
import FooterNav from '~nanostring-brand-elements/components/FooterNav'
import FooterNavButton from '~nanostring-brand-elements/components/FooterNavButton'
import {
    breakpointSmallDesktop,
    useBreakpointBelow,
} from '~nanostring-brand-elements/theme/breakpoints'

import { reset } from '/store/profile'

const pages: { path: string; label: string; eventAction: string }[] = [
    {
        path: '/profile',
        label: 'Build Your Profile',
        eventAction: 'buildYourProfileGlobalButton',
    },
    {
        path: '/explore',
        label: 'Explore Data Applications',
        eventAction: 'exploreDataApplicationsGlobalButton',
    },
    {
        path: '/analyze',
        label: 'Analyze Your Data',
        eventAction: 'analyzeYourDataGlobalButton',
    },
    {
        path: 'demo',
        label: 'Schedule a Consultation',
        eventAction: 'scheduleConsultationGlobalButton',
    },
]
Object.freeze(pages)

const Footer = (props) => {
    const location = useLocation()
    const pathname = location.pathname
    const isSmallDesktop = useBreakpointBelow(breakpointSmallDesktop)
    const dispatch = useDispatch()

    const nav = (
        <FooterNav innerHeight={isSmallDesktop ? 49 : 72}>
            {pages.map(({ label, path, eventAction }) => (
                <FooterNavButton
                    key={path}
                    label={label}
                    path={path}
                    eventAction={eventAction}
                    isActive={pathname.includes(path)}
                    onClick={() => {
                        dispatch(reset())
                    }}
                />
            ))}
        </FooterNav>
    )

    return <FooterBase {...props} nav={nav} copyright={<Copyright />} />
}
export default Footer

const Copyright = () => (
    <p
        sx={{
            fontSize: '10px',
            fontWeight: '400',
            color: 'white',
        }}
    >
        &copy; 2020 NanoString Technologies, Inc. All rights reserved.
        NanoString, NanoString Technologies, GeoMx, and the NanoString logo are
        trademarks or registered trademarks of NanoString Technologies, Inc., in
        the United States and/or other countries.
    </p>
)
