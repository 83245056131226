/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { analyticsPush } from '~nanostring-brand-elements/components/analytics'

import { CaseStudyProps } from '/content/profile/types'

import ZoomableImage from '/components/global/ZoomableImage'

const segmentationImg1 = '/images/segmentation 1.png'
const segmentationImg2 = '/images/segmentation 2.png'

const FrontPage: React.FC = (): React.ReactElement => {
    const isPortrait = useIsPortrait()

    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                paddingTop: '1rem',
                height: 'calc(100% - 1rem)',
            }}
            {...fadeAnimations}
        >
            <h3 sx={{ color: 'black' }}>
                Explore the Tumor-Microenvironment with the Cancer Transcriptome
                Atlas
            </h3>
            <div sx={{ marginTop: '2rem', height: 'calc(100% - 2rem)' }}>
                <div
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: isPortrait
                            ? undefined
                            : 'calc(30% - 5px) calc(70% - 5px)',
                        gridTemplateRows: isPortrait
                            ? 'calc(45% - 15px) calc(55% - 15px)'
                            : '100%',
                        gap: isPortrait ? 30 : 10,
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <ZoomableImage
                        preview={segmentationImg1}
                        src={segmentationImg1}
                    />
                    <ZoomableImage
                        preview={segmentationImg2}
                        src={segmentationImg2}
                    />
                </div>
            </div>
        </motion.div>
    )
}

const CaseStudy: React.FC<CaseStudyProps> = ({
    className,
}): React.ReactElement => {
    useEffect(() => {
        analyticsPush({
            eventSection: 'caseStudyViewed',
            event: 'crcSegment',
            eventValue: 1,
        })
        analyticsPush({
            eventSection: 'caseStudyViewed',
            event: 'crcSegment',
            eventValue: 'completed',
        })
    }, [])

    return (
        <motion.div
            {...fadeAnimations}
            className={className}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                border: '3px solid #f6f8e2',
            }}
        >
            <AnimatePresence>
                <FrontPage />
            </AnimatePresence>
        </motion.div>
    )
}

export default CaseStudy
