/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { createContext, useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { isMobile, isTablet, isIOS, isMobileSafari } from 'react-device-detect'

import LayoutFrame from '~nanostring-brand-elements/components/LayoutFrame'
import {
    breakpointSmallDesktop,
    useBreakpointBelow,
} from '~nanostring-brand-elements/theme/breakpoints'
import {
    disableBodyScroll,
    enableBodyScroll,
} from '~yipkos-common/utils/bodyScrollLock'

import { isWebApp } from '/App'
import { ApplicationState } from '/store'
import { zIndexContent } from '/z-indexes'

import Footer from '/components/global/Footer'
import Header from '/components/global/Header'
import ViewLarger from '/components/global/ViewLarger/ViewLarger'

interface LayoutProps {
    children?: React.ReactNode
}

interface ContextProps {
    isMobile: boolean
}

export const Context = createContext<ContextProps>({
    isMobile: false,
})

export const useLayoutContext = () => {
    return useContext(Context)
}

export const useIsMobile = () => {
    const ctx = useLayoutContext()
    return ctx.isMobile
}

const Layout = (props: LayoutProps) => {
    return (
        <Context.Provider
            value={{
                isMobile: isMobile || isMobileSafari || isIOS || isTablet,
            }}
        >
            <LayoutContent {...props} />
            <ViewLarger />
        </Context.Provider>
    )
}
export default Layout

const LayoutContent_ = (props) => {
    const { children, stepIndex } = props
    const location = useLocation()
    const pathname = location.pathname
    const isProfile = pathname.startsWith('/profile')
    const isSmallDesktop = useBreakpointBelow(breakpointSmallDesktop)

    const isScrollLocked =
        isWebApp &&
        window.innerWidth > window.innerHeight &&
        (isProfile || pathname === '/explore')

    const minHeight = isProfile
        ? 730
        : pathname.startsWith('/analyze')
        ? 700
        : pathname === '/explore'
        ? 527
        : pathname.startsWith('/explore')
        ? 700
        : pathname.startsWith('/demo')
        ? 700
        : stepIndex === 5 || pathname.endsWith('-case-study')
        ? 750
        : 527

    const headerHeight = isSmallDesktop ? 100 : 115
    const footerHeight = isSmallDesktop ? 190 : 231
    const mainHeight = `max(${minHeight}px, calc(100vh - ${headerHeight}px - ${footerHeight}px))`

    useEffect(() => {
        if (isScrollLocked) {
            disableBodyScroll('body')
            document.body.style.touchAction = 'pinch-zoom'
        } else {
            enableBodyScroll('body')
            document.body.style.touchAction = 'initial'
        }
    }, [isScrollLocked])

    return (
        <LayoutFrame>
            <Header
                height={headerHeight}
                sx={{
                    maxWidth: [null, null, null, '1200px', '1440px'],
                    m: '0 auto',
                }}
            />
            <main
                sx={{
                    width: '100%',
                    height: mainHeight,
                    zIndex: zIndexContent,
                    maxWidth: [null, null, null, '1200px', '1440px'],
                    m: '0 auto',
                }}
            >
                {children}
            </main>
            <Footer height={footerHeight} />
        </LayoutFrame>
    )
}
const mapStateToProps = ({ profile }: ApplicationState) => ({
    stepIndex: profile.stepIndex,
})
const LayoutContent = connect(mapStateToProps)(LayoutContent_)
