/** @jsx jsx */
import { jsx } from 'theme-ui'

import { useField } from 'formik'

import { fieldReadoutPlatformTextfield } from '/components/forms/FieldDefinitions'
import NSTGRadioButtonGroup from '/components/forms/NSTGRadioButtonGroup'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

interface Props {
    label: string
    isSubPage?: boolean
}

const fieldName = 'readoutPlatform'
const readoutPlatformnCounter = 'nCounter'
const readoutPlatformNGS = 'ngs'
export const readoutPlatformOther = 'other'

const ReadoutPlatformRadioButtonGroup = (props: Props) => {
    const isPortrait = useIsPortrait()
    return (
        <NSTGRadioButtonGroup
            {...props}
            name={fieldName}
            options={[
                {
                    id: readoutPlatformnCounter,
                    buttonProps: {
                        label: 'nCounter',
                    },
                },
                {
                    id: readoutPlatformNGS,
                    buttonProps: {
                        label: 'NextGen Sequencer',
                    },
                },
                {
                    id: readoutPlatformOther,
                    buttonProps: {
                        label: 'Other',
                        otherField: <OtherField />,
                    },
                },
            ]}
            sx={{
                minWidth: '22ch',
                ml: isPortrait ? '100px' : '14px',
            }}
        />
    )
}
export default ReadoutPlatformRadioButtonGroup

const OtherField = () => {
    const [field] = useField(fieldName)

    const isVisible = field.value === 'other'
    return (
        <div
            className='fade-in'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                opacity: isVisible ? 1 : 0,
                '& label': {
                    display: 'none',
                },
                '& input': {
                    padding: '4px 14px 4px 12px',
                    margin: '0 1em',
                },
            }}
        >
            {fieldReadoutPlatformTextfield}
        </div>
    )
}
