import { useEffect, useRef, useState } from 'react'

/*
    Use this when you want to have a component that puts itself in the DOM
    and then makes itself visible a small delay afterwards.  This is useful
    if you want it to transition onto the screen gracefully.  (And the
    reverse for unmounting.)

    Set isShown when the component should be shown.  This hook returns
    isMounted and isVisible, which get set after short delays when isShown
    changes.
 */
const useMountAndMakeVisible = (isShown: boolean) => {
    const [isMounted, setIsMounted] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const isVisibleTimer = useRef<ReturnType<typeof setTimeout> | null>(null)

    useEffect(() => {
        const t = isVisibleTimer.current
        isVisibleTimer.current = null
        if (t) {
            clearTimeout(t)
        }
        if (isShown) {
            setIsMounted(true)
            isVisibleTimer.current = setTimeout(() => {
                isVisibleTimer.current = null
                setIsVisible(true)
            }, 100)
        } else {
            setIsVisible(false)
            isVisibleTimer.current = setTimeout(() => {
                isVisibleTimer.current = null
                setIsMounted(false)
            }, 100)
        }
        return () => {
            const t = isVisibleTimer.current
            isVisibleTimer.current = null
            if (t) {
                clearTimeout(t)
            }
        }
    }, [isShown])

    return [isMounted, isVisible]
}
export default useMountAndMakeVisible
