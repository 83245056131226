/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'
import { motion } from 'framer-motion'

import type { Page } from '/store/pages/types'

import fadeAnimations from '~yipkos-common/animations/fade'

import ExploreDataInteractiveMenu from '../ExploreDataInteractiveMenu/ExploreDataInteractiveMenu'

export const pages: Page[] = [
    {
        path: '/explore',
        label: 'Explore Apps',
        heading: 'Applications',
        subhead: 'Explore the Data',
        gotoFunction: () => pages[0].path,
    },
    {
        path: '/explore/spatial-profiling',
        label: 'Spatial',
        heading: 'Spatial Profiling Application',
        subhead: 'Biology Based Segmentation Enables near 100% Cellularity',
        gotoFunction: () => pages[1].path,
    },
    {
        path: '/explore/biomarker-discovery',
        label: 'Biomarker',
        heading: 'Biomarker Discovery Application',
        subhead:
            'Spatial Resolution of Immune Response within the Microenvironment',
        gotoFunction: () => pages[2].path,
    },
    {
        path: '/explore/spatial-typing',
        label: 'Spatial Cell',
        heading: 'Spatial Cell Typing Application',
        subhead:
            'Single cell deconvolution quantifies relative cell abundance at specific tissue locations',
        gotoFunction: () => pages[3].path,
    },
    {
        path: '/explore/pathway-analysis',
        label: 'Pathway Analysis',
        heading: 'Pathway Analysis Application',
        subhead: 'Spatial Mapping of Pathway Activation and Signaling',
        gotoFunction: () => pages[4].path,
    },
    {
        path: '/analyze',
        label: 'Analyze Data',
        heading: '',
        subhead: '',
        gotoFunction: () => pages[5].path,
    },
]

export const menuPages: Page[] = [
    {
        path: '/explore',
        label: 'Explore Apps',
        heading: 'Applications',
        subhead: 'Explore the Data',
        gotoFunction: pages[0].path,
    },
    {
        path: '/explore/spatial-profiling',
        label: 'Spatial',
        heading: 'Spatial Profiling Application',
        subhead: 'Biology Based Segmentation Enables near 100% Cellularity',
        gotoFunction: pages[1].path,
    },
    {
        path: '/explore/biomarker-discovery',
        label: 'Biomarker',
        heading: 'Biomarker Discovery Application',
        subhead:
            'Spatial Resolution of Immune Response within the Microenvironment',
        gotoFunction: pages[2].path,
    },
    {
        path: '/explore/spatial-typing',
        label: 'Spatial Cell',
        heading: 'Spatial Cell Typing Application',
        subhead:
            'Single cell deconvolution quantifies relative cell abundance at specific tissue locations',
        gotoFunction: pages[3].path,
    },
    {
        path: '/explore/pathway-analysis',
        label: 'Pathway Analysis',
        heading: 'Pathway Analysis Application',
        subhead: 'Spatial Mapping of Pathway Activation and Signaling',
        gotoFunction: pages[4].path,
    },
]

const ExploreData = () => {
    return (
        <Fragment>
            <motion.div
                className='step-heading'
                sx={{
                    display: 'flex',
                    textAlign: 'center',
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                <div>
                    <motion.h2
                        {...fadeAnimations}
                        transition={{ duration: 0.4 }}
                        sx={{
                            mb: '.5rem',
                        }}
                    >
                        Applications: Explore the Data
                    </motion.h2>
                </div>
            </motion.div>

            <motion.div
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                <ExploreDataInteractiveMenu pages={menuPages} />
            </motion.div>
        </Fragment>
    )
}
export default ExploreData
