/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'
import ZoomableImage from '/components/global/ZoomableImage'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

const chart = '/images/spatial-cell-typing-chart.png'
const sigs = '/images/spatial-cell-typing-signatures.png'
const sigData = '/images/spatial-cell-typing-signature-data.png'
const sigDataUncropped =
    '/images/spatial-cell-typing-signature-data-uncropped.png'
const sigChart = '/images/spatial-cell-typing-signature-chart.svg'

const ExploreSpatialCellTypingPage = () => {
    const isPortrait = useIsPortrait()
    return isPortrait ? (
        <ExploreSpatialCellTypingPagePortrait />
    ) : (
        <ExploreSpatialCellTypingPageLandscape />
    )
}

const ExploreSpatialCellTypingPagePortrait = () => {
    return (
        <div
            className='f-col-c-100'
            sx={{
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <div className='f-none'>
                <motion.h2
                    {...fadeAnimations}
                    sx={{
                        textAlign: 'center',
                        margin: '5vh 6rem 0 6rem',
                        fontSize: '45px',
                    }}
                >
                    Spatial Cell Typing Application
                </motion.h2>
                <motion.h4
                    {...fadeAnimations}
                    sx={{
                        textAlign: 'center',
                        margin: ['0', null, '0 4rem'],
                        fontSize: '36px',
                    }}
                >
                    Single cell deconvolution quantifies relative cell abundance
                    at specific tissue locations
                </motion.h4>
            </div>

            <div
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridTemplateRows: '40% 45% 15%',
                    gap: '2rem',
                    width: '100%',
                    marginTop: '9ex',
                }}
            >
                <SigData
                    sx={{
                        gridColumn: '1',
                        gridRow: '1',
                    }}
                />
                <Sigs
                    sx={{
                        gridColumn: '2',
                        gridRow: '1',
                    }}
                />
                <SigChart
                    sx={{
                        gridColumn: '3',
                        gridRow: '1',
                        alignSelf: 'start',
                    }}
                />
                <Chart
                    sx={{
                        gridColumn: '1 / 4',
                        gridRow: '2',
                    }}
                />
                <Text
                    sx={{
                        gridColumn: '1 / 4',
                        gridRow: '3',
                    }}
                />
            </div>
        </div>
    )
}

const ExploreSpatialCellTypingPageLandscape = () => {
    return (
        <div
            className='f-col-c-100'
            sx={{
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <div className='f-none'>
                <motion.h2
                    {...fadeAnimations}
                    sx={{
                        textAlign: 'center',
                        margin: '0 6rem',
                    }}
                >
                    Spatial Cell Typing Application
                </motion.h2>
                <motion.h4
                    {...fadeAnimations}
                    sx={{
                        textAlign: 'center',
                        margin: ['0', null, '0 6rem'],
                    }}
                >
                    Single cell deconvolution quantifies relative cell abundance
                    at specific tissue locations
                </motion.h4>
            </div>
            <div
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 4fr',
                    gridTemplateRows: '40% 40% 20%',
                    gap: '0.5rem',
                    width: '100%',
                    marginTop: '2ex',
                }}
            >
                <SigData
                    sx={{
                        gridColumn: '1',
                        gridRow: '1',
                    }}
                />
                <Sigs
                    sx={{
                        gridColumn: '2',
                        gridRow: '1',
                    }}
                />
                <SigChart
                    sx={{
                        gridColumn: '1',
                        gridRow: '2 / 4',
                        alignSelf: 'end',
                    }}
                />
                <Chart
                    sx={{
                        gridColumn: '3 / -1',
                        gridRow: '1 / 3',
                    }}
                />
                <Text
                    sx={{
                        gridColumn: '2 / -1',
                        gridRow: '3',
                    }}
                />
            </div>
        </div>
    )
}
export default ExploreSpatialCellTypingPage

const SigData = (props) => (
    <motion.div
        className={props.className}
        sx={{
            fontSize: '0.7rem',
            fontWeight: 500,
            marginBottom: '2ex',
        }}
        {...fadeAnimations}
    >
        <label>Data for Cell Signature</label>
        <ZoomableImage
            preview={sigData}
            src={sigDataUncropped}
            sx={{
                width: '100%',
                height: 'unset',
                img: {
                    marginTop: '1ex',
                },
            }}
        />
    </motion.div>
)

const Sigs = (props) => (
    <motion.div
        className={props.className}
        sx={{
            fontSize: '0.7rem',
            fontWeight: 500,
            marginBottom: '2ex',
        }}
        {...fadeAnimations}
    >
        <label>Extract Cell Signatures</label>
        <ZoomableImage
            preview={sigs}
            src={sigs}
            sx={{
                height: 'unset',
                width: '100%',
                img: {
                    marginTop: '1ex',
                },
            }}
        />
    </motion.div>
)

const SigChart = (props) => (
    <motion.div
        className={props.className}
        sx={{
            fontSize: '0.8rem',
            fontWeight: 500,
            marginBottom: '2ex',
        }}
        {...fadeAnimations}
    >
        <label>Apply Cell Signature to GeoMx Data</label>
        <ZoomableImage
            preview={sigChart}
            src={sigChart}
            sx={{
                width: '100%',
                height: 'unset',
            }}
        />
    </motion.div>
)

const Chart = (props) => (
    <motion.div
        className={props.className}
        {...fadeAnimations}
        sx={{
            width: '100%',
            maxHeight: 'max(310px, calc(100vh - 60ex))',
        }}
    >
        <ZoomableImage
            preview={chart}
            src={chart}
            sx={{
                img: {
                    marginTop: '1ex',
                },
            }}
        />
    </motion.div>
)

const Text = (props) => (
    <div
        className={props.className}
        sx={{
            fontWeight: '400',
            margin: '1ex 2rem',
            fontSize: '24px',
        }}
    >
        If you have scRNA-seq data from the same tissue type as your sample, we
        can quantify the cell populations you identify with that data. If you
        are interested in immune cells in tumor samples, no scRNA-seq data is
        needed: we have pre-defined the immune cell profiles our algorithm needs
        to run.
    </div>
)
