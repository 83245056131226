export const sizePreservingAspectRatio = (
    maxWidth: string | number | null | (string | number | null)[],
    maxHeight: string | number | null | (string | number | null)[],
    innerWidth: string | number,
    innerHeight: string | number
) => {
    if (maxWidth === null || maxHeight === null) {
        return null
    }
    if (Array.isArray(maxWidth) && Array.isArray(maxHeight)) {
        console.assert(maxWidth.length === maxHeight.length)
        const result = maxWidth.map((w, i) =>
            sizePreservingAspectRatio(w, maxHeight[i], innerWidth, innerHeight)
        )
        return [
            result.map((x) => (x === null ? null : x[0])),
            result.map((x) => (x === null ? null : x[1])),
        ]
    }
    const maxWidthStr = valueToDimension(maxWidth as string | number)
    const maxHeightStr = valueToDimension(maxHeight as string | number)
    return [
        `min(${maxWidthStr}, ${maxHeightStr} * ${innerWidth} / ${innerHeight})`,
        `min(${maxHeightStr}, ${maxWidthStr} * ${innerHeight} / ${innerWidth})`,
    ]
}

export const valueToDimension = (
    v: string | number | null | (string | number | null)[]
) => {
    if (v === null) {
        return null
    }
    if (typeof v === 'number') {
        return `${v}px`
    }
    if (Array.isArray(v)) {
        return v.map((x) => valueToDimension(x))
    }
    return `(${v})`
}

export const parsePx = (v: string): number => {
    return parseInt(v.replace('px', ''))
}
