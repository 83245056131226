/** @jsx jsx */
import { Button, jsx } from 'theme-ui'
import { MouseEventHandler, ReactNode } from 'react'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import Arrow from '/assets/icons/nav-arrow.inline-svg'

interface Props {
    className?: string
    size?: 'sm'
    onClick?: MouseEventHandler
    children?: ReactNode
}

const ButtonWithArrow = (props: Props) => {
    const { className, size, onClick, children } = props
    const isPortrait = useIsPortrait()

    return (
        <Button
            variant='primary'
            className={className}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize:
                    size === 'sm'
                        ? '1em'
                        : isPortrait
                        ? ['1.1em', null, null, '24px']
                        : '1.1em',
                fontWeight: 600,
                px: isPortrait
                    ? ['1em !important', null, null, '30px !important']
                    : '1em !important',
                py: size === 'sm' ? '0.5ex !important' : '1.5ex !important',
            }}
            onClick={onClick}
        >
            {children}
            <Arrow
                sx={{
                    width: 'auto',
                    height: size === 'sm' ? '0.9em' : '1.3em',
                    '& path': {
                        fill: 'white',
                    },
                    marginLeft: size === 'sm' ? '1em' : '1.5em',
                }}
            />
        </Button>
    )
}
export default ButtonWithArrow
