/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import fadeAnimations from '~yipkos-common/animations/fade'
import { StepNodeNav } from '/components/profile/ProfileBuilder/interfaces'
import { nodes } from '/content/profile'
import { CaseStudyProps } from '/content/profile/types'

const ProfileResults = (props: StepNodeNav) => {
    const { className, availableOptions } = props

    if (!availableOptions) {
        return null
    }
    const caseStudyId = availableOptions[0]
    if (!caseStudyId) {
        return null
    }

    return (
        <ProfileResultsContent
            className={className}
            caseStudyId={caseStudyId}
        />
    )
}
export default ProfileResults

export const ProfileResultsContent = (props) => {
    const { className, caseStudyId } = props
    const isPortrait = useIsPortrait()

    const caseStudy = nodes.get(caseStudyId)
    if (!caseStudy) {
        return null
    }
    const CaseStudyMedia = caseStudy.media as React.FC<CaseStudyProps>

    return (
        <motion.div
            className={className}
            sx={{
                display: 'flex',
                flexDirection: isPortrait ? 'column' : 'row',
                width: '100%',
                height: '100%',
                overflow: 'visible',
            }}
            {...fadeAnimations}
        >
            <CaseStudyMedia sx={{ width: '100%', padding: '1rem' }} />
        </motion.div>
    )
}
