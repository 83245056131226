/** @jsx jsx */
import { Button, jsx } from 'theme-ui'
import { MouseEventHandler, ReactNode } from 'react'

import Reset from '/assets/icons/reset.inline-svg'

interface Props {
    className?: string
    size?: 'sm'
    onClick?: MouseEventHandler
    children?: ReactNode
}

const ButtonWithReset = (props: Props) => {
    const { className, size, onClick, children } = props

    return (
        <Button
            variant='open'
            className={className}
            sx={{
                fontSize: size === 'sm' ? '1em' : '1.4em',
                fontWeight: 600,
                px: '1em !important',
                py: size === 'sm' ? '0.5ex !important' : '1.5ex !important',
            }}
            onClick={onClick}
        >
            {children}
            <Reset
                sx={{
                    width: 'auto',
                    height: size === 'sm' ? '0.9em' : '1.3em',
                    '& path': {
                        fill: 'currentColor',
                    },
                    marginLeft: size === 'sm' ? '1em' : '1.5em',
                }}
            />
        </Button>
    )
}
export default ButtonWithReset
