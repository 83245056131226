// On Safari from iOS 13 (but not iOS 14), the Slider grows to
// ridiculous size in our layout.  Some GitHub issues suggest that this
// is due to flexbox.  Fix this by adding a fixed width for the slider
// container, which we compute based on the page that we're fitting this
// into.
//
// This is of course a giant hack.
export const applySliderContainerBugfix = (
    el,
    contentWidth,
    contentHeight,
    isPortrait,
    isTablet
) => {
    if (!el) {
        return
    }
    el.style.opacity = '1'

    const adjH = isPortrait ? (isTablet ? '12rem' : '32rem') : '0px'

    const w = isPortrait
        ? `calc(${contentWidth}px - 5rem)`
        : `calc(${contentWidth}px - 5rem - 228px)`
    const h = isPortrait
        ? `calc(${contentHeight}px - ${adjH} - 6rem)`
        : `calc(${contentHeight}px - ${adjH} - 5rem)`
    el.style.maxWidth = w
    el.style.maxHeight = h

    const sliderH = `calc(${contentHeight}px - ${adjH} - 6rem)`
    for (const childEl of el.querySelectorAll('.slick-slider')) {
        childEl.style.height = sliderH
        childEl.style.maxHeight = sliderH
    }
    const slideH = `calc(${contentHeight}px - ${adjH} - 10rem)`
    for (const childEl of el.querySelectorAll('.slick-slide')) {
        childEl.style.height = slideH
        childEl.style.maxHeight = slideH
    }
}
