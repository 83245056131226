/** @jsx jsx */
import { jsx } from 'theme-ui'

import ScheduleConsultForm from '/components/schedule/ScheduleConsultForm/ScheduleConsultForm'

const ScheduleConsultFormColumn = () => {
    return (
        <div
            className='f-col'
            sx={{
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                width: '100%',
                flex: 1,
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <ScheduleConsultForm isSubForm />
        </div>
    )
}
export default ScheduleConsultFormColumn
