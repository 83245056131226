/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'

import imgHighResPCs from '/content/profile/media/regions/HighResPanethCellsNew.png'
import imgPCvsEC from '/content/profile/media/regions/PCvsECVolcanoPlot.png'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

const RareCellRNAProfilingDetail = () => {
    const isPortrait = useIsPortrait()
    return isPortrait ? (
        <RareCellRNAProfilingDetailPortrait />
    ) : (
        <RareCellRNAProfilingDetailLandscape />
    )
}

const RareCellRNAProfilingDetailPortrait = () => {
    return (
        <motion.div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'hidden',
                height: '100%',
            }}
            {...fadeAnimations}
        >
            <p
                sx={{
                    padding: '3rem 0',
                    fontSize: '30px',
                    maxWidth: '63%',
                    textAlign: 'center',
                }}
            >
                {' '}
                Paneth Cells from the Colon, stained with morphology marker 5-HT{' '}
            </p>

            <HighResPCs />
            <p
                sx={{
                    padding: '3rem 0',
                    fontSize: '30px',
                    maxWidth: '63%',
                    textAlign: 'center',
                }}
            >
                {' '}
                Key markers of both Paneth Cells and Enteroendocrine cells are
                present as enriched with in 5-HT stained cells as compared to
                the general Epithelial ROIs
            </p>
            <PCvsEC />
        </motion.div>
    )
}

const RareCellRNAProfilingDetailLandscape = () => {
    return (
        <motion.div
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '5rem 1fr',
                justifyItems: 'center',
                textAlign: 'center',
                margin: '1rem 0',
            }}
            {...fadeAnimations}
        >
            <p
                sx={{
                    gridColumn: '1',
                    gridRow: '1',
                    maxWidth: '70%',
                    m: '0 auto',
                }}
            >
                {' '}
                Paneth Cells from the Colon, stained with morphology marker 5-HT{' '}
            </p>
            <HighResPCs
                sx={{
                    gridColumn: '1',
                    gridRow: '2',
                }}
            />
            <p
                sx={{
                    gridColumn: '2',
                    gridRow: '1',
                    maxWidth: '70%',
                    m: '0 auto',
                }}
            >
                Key markers of both Paneth Cells and Enterendocrine cells are
                present as enriched with in 5-HT stained cells as compared to
                the general Epithelial ROIs
            </p>
            <PCvsEC
                sx={{
                    gridColumn: '2',
                    gridRow: '2',
                }}
            />
        </motion.div>
    )
}

const HighResPCs = (props) => {
    const { className } = props
    const isPortrait = useIsPortrait()
    return (
        <motion.img
            className={className}
            alt=''
            src={imgHighResPCs}
            sx={{
                width: isPortrait
                    ? '40%'
                    : ['40%', 'null', '60%', 'null', '50%'],
                height: 'auto',
            }}
            {...fadeAnimations}
        />
    )
}

const PCvsEC = (props) => {
    const { className } = props
    return (
        <motion.img
            className={className}
            alt=''
            src={imgPCvsEC}
            sx={{
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
            }}
            {...fadeAnimations}
        />
    )
}

export default RareCellRNAProfilingDetail
