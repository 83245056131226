/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState, useContext } from 'react'
import { motion } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { StepNode } from '/content/profile/types'

import fadeAnimations from '~yipkos-common/animations/fade'
import { Context as APIContext } from '/components/APIProvider'
import { StepNodeNav } from '/components/profile/ProfileBuilder/interfaces'
import { analyticsPush } from '~nanostring-brand-elements/components/analytics'

const ChooseAnalyte: React.FC<StepNodeNav> = ({
    className,
    options,
    onChange,
}): React.ReactElement => {
    const [selectedOption, setSelectedOption] = useState<StepNode | null>(null)
    const { userInteract } = useContext(APIContext)
    const isPortrait = useIsPortrait()

    return (
        <div
            className='f-col'
            sx={{
                height: '100%',
                width: isPortrait ? ['77%', null, null, '100%'] : '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <motion.div
                className={className}
                sx={{
                    m: ['0', null, null, '0 0 10ex 0'],
                    display: 'flex',
                    flexDirection: isPortrait ? 'column' : 'row',
                    width: '100%',
                    height: 'fit-content',
                    justifyContent: 'center',
                }}
                {...fadeAnimations}
            >
                {options.map((option) => {
                    const media = option.media
                    return (
                        <div
                            key={`option-${option.id}`}
                            sx={{
                                listStyle: 'none',
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                className={
                                    selectedOption &&
                                    selectedOption.id === option.id
                                        ? 'active'
                                        : undefined
                                }
                                sx={{
                                    borderStyle: 'solid',
                                    borderColor: 'transparent',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: isPortrait
                                        ? 'column'
                                        : 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={() => {
                                    analyticsPush({
                                        eventSection: 'profileBuild',
                                        event: 'reagent',
                                        eventValue: option.id,
                                    })
                                    userInteract(
                                        'AnalyteStep0',
                                        'AnalyteButton',
                                        option.id
                                    )
                                    setSelectedOption(option)
                                    onChange(option.id)
                                }}
                            >
                                <div
                                    sx={{
                                        ':focus': { outline: 0 },
                                        cursor: 'pointer',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        borderStyle: 'dashed',
                                        borderWidth: '2px',
                                        borderColor: 'muted',
                                        borderRadius: 10,
                                        height: '100%',
                                        m: 0,
                                        transition: 'all .7s ease',
                                        overflow: 'hidden',
                                        position: 'relative',
                                        marginLeft: '1rem',
                                        '.active &': {
                                            boxShadow: (theme) =>
                                                `0 0 1em .2em ${theme.colors.secondary}`,
                                            '::hover': {
                                                '::after': {
                                                    content: '""',
                                                    backgroundColor:
                                                        'transparent',
                                                    color: 'inherit',
                                                    opacity: 0.8,
                                                },
                                            },
                                        },
                                        '::after': {
                                            opacity: 0,
                                            transition: 'all .5s ease',
                                            content: '"Choose Reagent"',
                                            backgroundColor:
                                                'rgba(18, 18, 18, 0.8)',
                                            color: '#fff',
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontSize: '1.4em',
                                            fontWeight: '500',
                                        },
                                        '&:hover': {
                                            borderColor: 'secondary',
                                            '::after': {
                                                opacity: 1.0,
                                            },
                                        },
                                    }}
                                >
                                    <h3
                                        sx={{
                                            marginTop: '1ex',
                                            textAlign: 'center',
                                            fontSize: '18px',
                                        }}
                                    >{`${option.label} reagents`}</h3>
                                    {typeof media === 'string' ? (
                                        <img
                                            alt=''
                                            sx={{
                                                width: '95%',
                                                height: 'auto',
                                                maxHeight: 'calc(100% - 4rem)',
                                                display: 'block',
                                                m: '0 auto',
                                                py: '2rem',
                                            }}
                                            src={media}
                                        />
                                    ) : undefined}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </motion.div>
        </div>
    )
}

export default ChooseAnalyte
