import {
    useBreakpointAbove as useBreakpointAboveRaw,
    useBreakpointBelow as useBreakpointBelowRaw,
} from '~yipkos-common/utils/mediaQueries'

export const breakpointMobileNarrow = 'breakpointMobileNarrow'
export const breakpointMobileWide = 'breakpointMobileWide'
export const breakpointTablet = 'breakpointTablet'
export const breakpointSmallDesktop = 'breakpointSmallDesktop'
export const breakpointDesktop = 'breakpointDesktop'

// One pixel greater than the upper boundary for the named breakpoint.
// This is because theme-ui uses min-width, so we define where the named
// breakpoint no longer takes effect.
const breakpoints: { [index: string]: number } = {
    [breakpointMobileNarrow]: 415,
    [breakpointMobileWide]: 769,
    [breakpointTablet]: 1025,
    [breakpointSmallDesktop]: 1441,
    [breakpointDesktop]: 1569,
}
Object.freeze(breakpoints)

export default breakpoints

export const useBreakpointAbove = (breakpointName: string | number) => {
    const breakpoint =
        typeof breakpointName === 'number'
            ? breakpointName
            : breakpoints[breakpointName]
    return useBreakpointAboveRaw(breakpoint)
}

export const useBreakpointBelow = (breakpointName: string | number) => {
    const breakpoint =
        typeof breakpointName === 'number'
            ? breakpointName
            : breakpoints[breakpointName]
    return useBreakpointBelowRaw(breakpoint)
}
