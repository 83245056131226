/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import NavArrowGfx from '/assets/icons/nav-arrow.inline-svg'

interface Props {
    className?: string
    onClick?: () => void
    pointLeft?: boolean
    disabled?: boolean
}

const GalleryArrowBtn: React.FC<Props> = ({
    onClick,
    className,
    pointLeft,
    disabled,
}): React.ReactElement => {
    return (
        <motion.button
            className={className}
            sx={{
                borderWidth: '.1rem',
                borderRadius: '.3rem',
                borderStyle: 'solid',
                borderColor: 'muted',
                width: '1.8rem',
                height: '1.8rem',
                m: '.2rem',
            }}
            disabled={disabled}
            onClick={onClick}
        >
            <NavArrowGfx
                sx={{
                    width: '1.8rem',
                    height: '1.8rem',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    transform: pointLeft && 'scaleX(-1)',
                    p: '.2rem',
                    path: {
                        fill: disabled && '#cccccc',
                    },
                }}
            />
        </motion.button>
    )
}

export default GalleryArrowBtn
