/** @jsx jsx */
import { jsx } from 'theme-ui'

const ROIWorkflowHeader = (props) => {
    const { analyte } = props

    return (
        <div className='f-row-center'>
            <div className='f-col'>
                <h2
                    sx={{
                        fontSize: ['28px', null, null, '36px'],
                    }}
                >
                    <span className='highlighted'>{analyte}</span>{' '}
                    <span>Workflow:</span>{' '}
                    <span className='highlighted'>How it Works</span>
                </h2>
            </div>
        </div>
    )
}
export default ROIWorkflowHeader
