import clsx from 'clsx'

import useMountAndMakeVisible from '../../utils/useMountAndMakeVisible'

export interface Props {
    className?: string
    background?: string
    backdropFilter?: string
    zIndex: number
    isOpen: boolean
    onClick?: () => void
}

const DialogBackdrop = (props: Props) => {
    const {
        className,
        background = 'rgba(255, 255, 255, 0.5)',
        backdropFilter = 'blur(1px)',
        zIndex,
        isOpen,
        onClick,
    } = props
    const [isMounted, isVisible] = useMountAndMakeVisible(isOpen)

    return (
        <div
            onClick={onClick}
            className={clsx(
                className,
                'DialogBackdrop',
                isMounted && 'DialogBackdrop-isMounted',
                isVisible && 'DialogBackdrop-isVisible'
            )}
            style={{
                background,
                backdropFilter: isMounted ? backdropFilter : 'none',
                zIndex,
            }}
        />
    )
}
export default DialogBackdrop
