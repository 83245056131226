import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
    ReactNode,
} from 'react'

interface ContextProps {
    isOn: boolean
    setIsOn: (newIsOn: boolean) => void
}

interface ProviderProps {
    isEnabled: boolean
    children: ReactNode
}

const Context = createContext<ContextProps>({
    isOn: false,
    // @ts-ignore
    setIsOn: (_) => {},
})
Context.displayName = 'screenshotMode.Context'

export const useScreenshotModeFull = () => {
    return useContext(Context)
}

export const useScreenshotMode = () => {
    // @ts-ignore
    const ctx = useScreenshotModeFull()
    return ctx.isOn
}

export const ScreenshotModeProvider = (props: ProviderProps) => {
    const { isEnabled, children } = props
    const [isOn, setIsOn] = useState(false)

    const onKeyDown = useCallback(
        (ev) => {
            if (ev.ctrlKey && ev.keyCode === 192) {
                ev.preventDefault()
                ev.stopPropagation()
                document.body.style.opacity = '0.5'
                setTimeout(() => {
                    document.body.style.opacity = '1'
                }, 500)
                console.log(`${isOn ? 'Disabled' : 'Enabled'} screenshot mode`)
                setIsOn(!isOn)
            }
        },
        [isOn, setIsOn]
    )

    useEffect(() => {
        if (isEnabled) {
            document.addEventListener('keydown', onKeyDown)
        }
        return () => {
            document.removeEventListener('keydown', onKeyDown)
        }
    }, [onKeyDown])

    const value = {
        isOn,
        setIsOn,
    }

    return <Context.Provider value={value}>{children}</Context.Provider>
}
