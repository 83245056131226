/** @jsx jsx */
import { jsx } from 'theme-ui'
import { MouseEventHandler } from 'react'

import ResetGfx from '/assets/icons/reset.inline-svg'

interface Props {
    className?: string
    onClick: MouseEventHandler
}

const BuildAnotherButton = (props: Props) => {
    return (
        <button
            {...props}
            sx={{
                color: 'primary',
                fontWeight: 500,
                fontSize: '14px',
                display: 'flex',
                width: 'fit-content',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <div>Build Another Profile</div>
            <ResetGfx
                sx={{
                    width: '1rem',
                    height: '1rem',
                    marginLeft: '0.3rem',
                }}
            />
        </button>
    )
}
export default BuildAnotherButton
