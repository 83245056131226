/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'

import {
    breakpointTablet,
    useBreakpointBelow,
} from '~nanostring-brand-elements/theme/breakpoints'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import ScheduleConsultForm from '/components/schedule/ScheduleConsultForm/ScheduleConsultForm'

import BuildAnotherButton from './BuildAnotherButton'
import ScheduleConsultFormColumn from './ScheduleConsultFormColumn'

const CaseStudyFrame = (props) => {
    const { header, children, onReset } = props
    const isPortrait = useIsPortrait()
    const isTablet = useBreakpointBelow(breakpointTablet)

    // We have the ScheduleConsultForm to the right on all landscape layouts,
    // below on large portrait, and we drop it for sub-small-desktop portrait.

    return (
        <Fragment>
            <div
                sx={{
                    display: 'grid',
                    gridTemplateColumns: isPortrait ? '1fr' : '1fr 240px',
                    gridTemplateRows: isPortrait
                        ? isTablet
                            ? '46px 1fr'
                            : '46px 1fr 300px'
                        : '46px 1fr',
                    columnGap: '14px',
                    width: 'calc(100% - 1rem)',
                    height: isPortrait ? 'calc(100% - 8ex)' : '100%', // Avoid the Back button in portrait.
                    overflow: 'hidden',
                }}
            >
                {header}
                {!isPortrait && (
                    <BuildAnotherButton
                        sx={{
                            margin: '0 auto',
                        }}
                        onClick={onReset}
                    />
                )}
                <div
                    sx={{
                        width: '100%',
                        height: isPortrait ? undefined : '100%',
                        overflow: 'hidden',
                        mt: isPortrait ? 114 : undefined,
                    }}
                >
                    {children}
                </div>
                {isPortrait ? (
                    isTablet ? null : (
                        <ScheduleConsultForm isSubForm />
                    )
                ) : (
                    <ScheduleConsultFormColumn />
                )}
            </div>
            {isPortrait && (
                <BuildAnotherButton
                    sx={{
                        // Alongside the Back button at the bottom of the frame.
                        // This is in the grid above for landscape.
                        position: 'absolute',
                        bottom: 55,
                        right: 48,
                    }}
                    onClick={onReset}
                />
            )}
        </Fragment>
    )
}
export default CaseStudyFrame
