import { useMediaQuery } from 'react-responsive'

export const useBreakpointAbove = (breakpoint: number) => {
    return useMediaQuery({
        query: `(min-width: ${breakpoint}px)`,
    })
}

export const useBreakpointBelow = (breakpoint: number) => {
    return useMediaQuery({
        query: `(max-width: ${breakpoint - 0.02}px)`,
    })
}

export const useBreakpointBetween = (min: number, max: number) => {
    return useMediaQuery({
        query: `(min-width: ${min}px) and (max-width: ${max - 0.02}px)`,
    })
}

export const useIsHoverYes = () => {
    return useMediaQuery({
        query: '(hover: hover) and (pointer: fine)',
    })
}

export const useIsHoverNo = () => {
    return useMediaQuery({
        query: 'not all and (hover: hover) and (pointer: fine)',
    })
}

export const useIsPortrait = () => {
    return useMediaQuery({
        query: '(orientation: portrait)',
    })
}
