/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'

import { useField } from 'formik'

import LabeledRadioButton from '~yipkos-common/components/forms/LabeledRadioButton'
import {
    RadioButtonGroupFormik,
    RadioButtonGroupFormikProps,
} from '~yipkos-common/components/forms/RadioButtonGroup'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

interface Props extends RadioButtonGroupFormikProps {
    label: string
    isSubPage?: boolean
}

const NSTGRadioButtonGroup = (props: Props) => {
    const { label, name, isSubPage, ...rest } = props

    // @ts-ignore
    const [_field, meta] = useField(name)

    const isError = meta.error && meta.touched
    const isPortrait = useIsPortrait()
    return (
        <Fragment>
            <label
                htmlFor={name}
                sx={{
                    fontSize: isPortrait ? '1.5rem' : undefined,
                    p: isPortrait && !isSubPage ? '3rem' : '0.3rem',
                    flexBasis: '50%',
                    color: isError ? '#ff0000' : undefined,
                    fontWeight: 500,
                }}
            >
                {label}
            </label>

            <RadioButtonGroupFormik
                name={name}
                {...rest}
                Button={LabeledRadioButton}
                sx={{
                    '& label': {
                        padding:
                            isPortrait && !isSubPage ? '0.5rem' : undefined,
                        color: 'primary',
                        marginBottom: '0.8ex',
                        width: 'fit-content',
                        fontSize:
                            isPortrait && !isSubPage ? '1.5rem' : undefined,
                    },
                    '& input': {
                        color: 'primary',
                    },
                }}
            />
        </Fragment>
    )
}
export default NSTGRadioButtonGroup
