import { StepNode, NodeTypes } from './types'
import { AnalyteIds } from './analytes'

import {
    RNAWorkflowGfx,
    ProteinWorkflowGfx,
} from './media/workflows/WorkflowAnimation'

export enum WorkflowIds {
    PROTEIN = 'protein-workflow',
    RNA = 'rna-workflow',
}

export const ProteinWorkflow: StepNode = {
    id: WorkflowIds.PROTEIN,
    type: NodeTypes.WORKFLOW,
    label: 'Protein Workflow',
    rules: [[AnalyteIds.PROTEIN]],
    media: ProteinWorkflowGfx,
}

export const RNAWorkflow: StepNode = {
    id: WorkflowIds.RNA,
    type: NodeTypes.WORKFLOW,
    label: 'RNA Workflow',
    rules: [[AnalyteIds.RNA]],
    media: RNAWorkflowGfx,
}

export const allWorkflows: Map<string, StepNode> = new Map([
    [WorkflowIds.PROTEIN, ProteinWorkflow],
    [WorkflowIds.RNA, RNAWorkflow],
])
