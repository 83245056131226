/** @jsx jsx */
import { jsx } from 'theme-ui'
import clsx from 'clsx'
import { motion } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { fieldsPersonalInfo } from '/components/forms/FieldDefinitions'

import { Props } from './ScheduleConsultForm'

const FormPage1 = (props: Props) => {
    const isPortrait = useIsPortrait()
    return isPortrait ? (
        <FormPage1Portrait {...props} />
    ) : (
        <FormPage1Landscape {...props} />
    )
}
export default FormPage1

const FormPage1Portrait = (props) => {
    const { className, isSubForm } = props
    return (
        <motion.div
            id='FormPage1Portrait'
            className={className}
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '1fr 1fr 1fr ',
                columnGap: isSubForm ? '1rem' : '2rem',
                rowGap: isSubForm ? '0ex' : '3ex',
                fontSize: '1.4rem',
                color: 'primary',
            }}
        >
            {fieldsPersonalInfo[0]}
            {fieldsPersonalInfo[1]}
            {fieldsPersonalInfo[2]}
            {fieldsPersonalInfo[3]}
            {fieldsPersonalInfo[4]}
        </motion.div>
    )
}

const FormPage1Landscape = (props) => {
    const { className, isSubForm } = props
    return (
        <motion.div
            className={clsx(isSubForm ? '' : 'g-2col', className)}
            sx={{
                rowGap: '2ex',
                columnGap: '5ex',
                color: 'primary',
            }}
        >
            {fieldsPersonalInfo[0]}
            {fieldsPersonalInfo[1]}
            {fieldsPersonalInfo[2]}
            {fieldsPersonalInfo[3]}
            {fieldsPersonalInfo[4]}
        </motion.div>
    )
}
