/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import React from 'react'
import clsx from 'clsx'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import ResearchAreaRadioButtonGroup from '/components/forms/ResearchAreaRadioButtonGroup'
import ReadoutPlatformRadioButtonGroup from '/components/forms/ReadoutPlatformRadioButtonGroup'

interface Props {
    className?: string
    isSubPage?: boolean
}

const FormPage2: React.FC<Props> = (props): React.ReactElement => {
    const { isSubPage } = props
    const isPortrait = useIsPortrait()
    return isPortrait && !isSubPage ? (
        <FormPage2Portrait {...props} />
    ) : (
        <FormPage2Landscape {...props} />
    )
}

const FormPage2Landscape = (props) => {
    const { className, isSubPage } = props
    const isPortrait = useIsPortrait()

    return (
        <div
            className={clsx(
                className,
                !isPortrait && isSubPage ? 'f-col' : 'f-row'
            )}
            sx={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                textAlign: 'left',
                overflow: 'hidden',
                '& > div': {
                    width: '100%',
                    flex: '1 1 50%',
                },
            }}
        >
            <Flex
                sx={{
                    flexDirection: 'column',
                    pb: '1rem',
                    flexBasis: '50%',
                    'label:first-of-type': {
                        mr: '1.5rem', // Avoid the Close button.
                    },
                }}
            >
                <ResearchAreaRadioButtonGroup
                    label='1. Please select your area of research:'
                    isSubPage={isSubPage}
                />
            </Flex>
            <Flex
                sx={{
                    flexDirection: 'column',
                    pb: '1rem',
                    pl: isSubPage ? undefined : '1rem',
                }}
            >
                <ReadoutPlatformRadioButtonGroup
                    label='2. Available platform for GeoMx Readout:'
                    isSubPage={isSubPage}
                />
            </Flex>
        </div>
    )
}

const FormPage2Portrait = (props) => {
    const { className, isSubPage } = props
    return (
        <div
            className={clsx(className)}
            sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                overflow: 'hidden',
                '& > div': {
                    width: '100%',
                },
            }}
        >
            <Flex
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',

                    pb: '1rem',
                    flexBasis: '50%',
                    'label:first-of-type': {
                        mr: '1.5rem', // Avoid the Close button.
                    },
                }}
            >
                <ResearchAreaRadioButtonGroup
                    label='1. Please select your area of research:'
                    isSubPage={isSubPage}
                />
            </Flex>
            <Flex
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',

                    pb: '1rem',
                }}
            >
                <ReadoutPlatformRadioButtonGroup
                    label='2. Available platform for GeoMx Readout:'
                    isSubPage={isSubPage}
                />
            </Flex>
        </div>
    )
}
export default FormPage2
