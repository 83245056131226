/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ReactNode } from 'react'

interface Props {
    innerHeight: number
    children: ReactNode
}

const FooterNav = (props: Props) => {
    const { innerHeight, children } = props

    return (
        <nav
            sx={{
                borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
                margin: '0 0 30px 0',
                p: '30px 40px 0 40px',
            }}
        >
            <ul
                sx={{
                    height: innerHeight,
                    maxWidth: ['812px', null, null, '812px', '1000px'],
                    margin: 0,
                    padding: 0,
                    listStyle: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {children}
            </ul>
        </nav>
    )
}
export default FooterNav
