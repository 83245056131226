/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'
import fadeAnimations from '~yipkos-common/animations/fade'

const ROIIcon = (props) => {
    const { roi } = props
    const icon = roi.media

    if (!icon || typeof icon !== 'string') {
        return null
    }

    return (
        <motion.img
            alt=''
            src={icon}
            sx={{
                transform: 'translateY(4px)',
                height: '3rem',
                width: '3rem',
                mr: '10px',
            }}
            {...fadeAnimations}
            transition={{ delay: 0.1 }}
        />
    )
}
export default ROIIcon
