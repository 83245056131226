import React from 'react'

const MediaImage = (props) => {
    const { media } = props
    const MediaComponent = media

    return typeof media === 'string' ? (
        <img alt='' src={media as string} />
    ) : typeof media === 'function' ? (
        <MediaComponent />
    ) : (
        media
    )
}
export default MediaImage
