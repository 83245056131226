import { StepNode, NodeTypes } from './types'
import { SampleIds } from './samples'
import { AnalyteIds } from './analytes'

import GeometricProfilingDetail from '/components/profile/DetailPanels/GeometricProfilingDetail'
import RareCellProfilingDetail from '/components/profile/DetailPanels/RareCellProfilingDetail'
import SegmentProfilingDetail from '/components/profile/DetailPanels/SegmentProfilingDetail'
import ContourProfilingDetail from '/components/profile/DetailPanels/ContourProfilingDetail'

import geoMedia from './media/regions/geo.png'
import segmentMedia from './media/regions/segment.png'
import rareMedia from './media/regions/rare.png'
import griddedMedia from './media/regions/gridded.png'
import contourMedia from './media/regions/contour.png'

export enum RegionIds {
    GEO = 'geometric-profiling',
    SEGMENT = 'segment-profiling',
    CONTOUR = 'contour-profiling',
    GRID = 'gridded-profiling',
    RARE = 'rare-cell-profiling',
}

export const GeometricProfiling: StepNode = {
    id: RegionIds.GEO,
    type: NodeTypes.REGION,
    label: 'Geometric Profiling',
    rules: [
        [AnalyteIds.PROTEIN, SampleIds.SMT],
        [AnalyteIds.RNA, SampleIds.SMT],
        [AnalyteIds.PROTEIN, SampleIds.FFPE],
        [AnalyteIds.RNA, SampleIds.FFPE],
    ],
    media: geoMedia,
    detail: GeometricProfilingDetail,
    eventValue: 'geometric',
}
export const SegmentProfiling: StepNode = {
    id: RegionIds.SEGMENT,
    type: NodeTypes.REGION,
    label: 'Segment Profiling',
    rules: [
        [AnalyteIds.RNA, SampleIds.SMT],
        [AnalyteIds.RNA, SampleIds.FFPE],
    ],
    media: segmentMedia,
    detail: SegmentProfilingDetail,
    eventValue: 'segment',
}
export const ContourProfiling: StepNode = {
    id: RegionIds.CONTOUR,
    type: NodeTypes.REGION,
    label: 'Contour Profiling',
    rules: [
        [AnalyteIds.RNA, SampleIds.SMT],
        [AnalyteIds.RNA, SampleIds.FFPE],
    ],
    media: contourMedia,
    detail: ContourProfilingDetail,
    eventValue: 'contour',
}
export const GriddedProfiling: StepNode = {
    id: RegionIds.GRID,
    type: NodeTypes.REGION,
    label: 'Gridded Profiling',
    rules: [],
    media: griddedMedia,
}
export const RareCellProfiling: StepNode = {
    id: RegionIds.RARE,
    type: NodeTypes.REGION,
    label: 'Rare Cell Profiling',
    rules: [
        [AnalyteIds.PROTEIN, SampleIds.SMT],
        [AnalyteIds.PROTEIN, SampleIds.TMA],
        [AnalyteIds.PROTEIN, SampleIds.FFPE],
        [AnalyteIds.RNA, SampleIds.FFPE],
    ],
    media: rareMedia,
    detail: RareCellProfilingDetail,
    eventValue: 'rareCell',
}

export const allRegions: Map<string, StepNode> = new Map([
    [RegionIds.RARE, RareCellProfiling],
    [RegionIds.GRID, GriddedProfiling],
    [RegionIds.CONTOUR, ContourProfiling],
    [RegionIds.SEGMENT, SegmentProfiling],
    [RegionIds.GEO, GeometricProfiling],
])
