/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import {
    CoreNeedleBiopsy,
    FreshFrozenTissue,
    SlideMountedTissue,
    TissueBlockFFPEBank,
    TissueMicroarray,
} from '/content/profile/samples'
import { Step3 } from '/content/profile/steps'

import ButtonWithArrow from '/components/global/ButtonWithArrow'
import MediaImage from '/components/global/MediaImage'

import ChooseRegionGraphic from './ChooseRegionGraphic'

import howItWorksLandscape from './assets/how-it-works-landscape.svg'
import Step1TextSvg from './assets/step1-text.inline-svg'
import Step2TextSvg from './assets/step2-text.inline-svg'
import Step3TextSvg from './assets/step3-text.inline-svg'
import ProteinSvg from './assets/protein.inline-svg'
import RnaSvg from './assets/rna.inline-svg'

// This is the same as Step2.options in /content/profile/steps, but in a
// different order, because we display them in the intro in a different order
// (for no good reason).
const step2Options = [
    SlideMountedTissue,
    TissueMicroarray,
    TissueBlockFFPEBank,
    FreshFrozenTissue,
    CoreNeedleBiopsy,
]
Object.freeze(step2Options)

interface Props {
    className?: string
    onUserStart?: () => void
}

const ProfileBuilderIntro: React.FC<Props> = (
    props: Props
): React.ReactElement => {
    const isPortrait = useIsPortrait()

    return isPortrait ? (
        <ProfileBuilderIntroPortrait {...props} />
    ) : (
        <ProfileBuilderIntroLandscape {...props} />
    )
}
export default ProfileBuilderIntro

const ProfileBuilderIntroPortrait = (props) => {
    const { className, onUserStart } = props

    return (
        <div
            className={clsx(className, 'f-col')}
            sx={{
                overflow: 'hidden',
                justifyContent: 'space-between',
            }}
        >
            <div className='f-col'>
                <motion.div
                    className='f-center'
                    {...fadeAnimations}
                    transition={{ duration: 0.1, delay: 0.5 }}
                >
                    <Step1TextSvg
                        sx={{
                            width: 'auto',
                            height: '50px',
                            maxHeight: ['3vh', null, 'unset'],
                            m: '20px auto 0 auto',
                        }}
                    />
                </motion.div>
                <motion.div
                    className='f-row'
                    {...fadeAnimations}
                    transition={{ duration: 0.1, delay: 1 }}
                    sx={{
                        justifyContent: 'space-between',
                        gap: 50,
                        m: '20px 0',
                        maxHeight: ['10vh', null, 'unset'],
                    }}
                >
                    <RnaSvg />
                    <ProteinSvg />
                </motion.div>
            </div>
            <div className='f-col'>
                <motion.div
                    className='f-center'
                    {...fadeAnimations}
                    transition={{ duration: 0.1, delay: 1.5 }}
                >
                    <Step2TextSvg
                        sx={{
                            width: 'auto',
                            height: '50px',
                            maxHeight: ['3vh', null, 'unset'],
                            m: '0 auto',
                        }}
                    />
                </motion.div>
                <motion.div
                    className='f-row'
                    {...fadeAnimations}
                    transition={{ delay: 2 }}
                    sx={{
                        justifyContent: 'space-between',
                        gap: 20,
                        m: '20px',
                    }}
                >
                    {step2Options.map((option) => {
                        return (
                            <SampleInBox key={option.id} media={option.media} />
                        )
                    })}
                </motion.div>
            </div>
            <div className='f-col'>
                <motion.div
                    className='f-center'
                    {...fadeAnimations}
                    transition={{ duration: 0.1, delay: 2.5 }}
                >
                    <Step3TextSvg
                        sx={{
                            width: 'auto',
                            height: '50px',
                            maxHeight: ['3vh', null, 'unset'],
                            m: '0 auto',
                        }}
                    />
                </motion.div>
                <motion.div
                    className='f-row'
                    {...fadeAnimations}
                    transition={{ delay: 3 }}
                    sx={{
                        justifyContent: 'space-between',
                        gap: 20,
                        m: '20px 0',
                        maxHeight: ['15vh', null, 'unset'],
                    }}
                >
                    <ChooseRegionGraphic />
                    <div
                        className='f-col'
                        sx={{
                            justifyContent: 'center',
                            fontSize: 'min(30px, 2vh)',
                            lineHeight: 1.5,
                            fontWeight: 'bold',
                            color: 'primary',
                            whiteSpace: 'nowrap',
                            paddingRight: 50,
                        }}
                    >
                        <ul>
                            {Step3.options.map((option, i) => (
                                <motion.li
                                    key={option.id}
                                    {...fadeAnimations}
                                    transition={{
                                        duration: 0.1,
                                        delay: 3.5 + 0.5 * i,
                                    }}
                                >
                                    {option.label}
                                </motion.li>
                            ))}
                        </ul>
                    </div>
                </motion.div>
            </div>
            <StartButton
                onClick={onUserStart}
                sx={{
                    m: '0 auto',
                    minWidth: '34.5vw',
                }}
            />
        </div>
    )
}

const ProfileBuilderIntroLandscape = (props) => {
    const { className, onUserStart } = props
    const stepGfx: any[] = []

    for (let i = 0; i < 3; i++) {
        for (let p = 0; p < 7; p++) {
            stepGfx.push(
                <motion.use
                    key={`step${i}-${p}`}
                    custom={1}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 0.1,
                        ease: 'easeIn',
                        delay: i + p * 0.5,
                    }}
                    href={`${howItWorksLandscape}#step${i}-${p}`}
                />
            )
        }
    }
    return (
        <motion.div
            className={clsx(className, 'f-gs-auto', 'f-col-c-100')}
            sx={{
                justifyContent: 'center',
                alignItems: 'stretch',
                overflow: 'hidden',
            }}
            {...fadeAnimations}
        >
            <motion.svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 3700 1600'
                className='f-gs-auto'
            >
                {stepGfx}
            </motion.svg>
            <StartButton
                className='f-none'
                sx={{
                    alignSelf: 'center',
                }}
                onClick={onUserStart}
            />
        </motion.div>
    )
}

const StartButton = (props) => {
    return <ButtonWithArrow {...props}>Start Your Profile</ButtonWithArrow>
}

const SampleInBox = (props) => {
    const { media } = props

    return (
        <div
            className='f-center'
            sx={{
                display: 'flex',
                borderStyle: 'dashed',
                borderWidth: '2px',
                borderColor: 'muted',
                borderRadius: 10,
                p: 10,

                svg: {
                    textRendering: 'geometricPrecision',
                    width: '100%',
                    height: 'auto',
                    maxHeight: ['7vh', null, 'unset'],
                },
            }}
        >
            <MediaImage media={media} />
        </div>
    )
}
