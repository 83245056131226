/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext } from 'react'
import { motion } from 'framer-motion'

import { StepNode } from '/content/profile/types'

import fadeAnimations from '~yipkos-common/animations/fade'
import { Context as APIContext } from '/components/APIProvider'
import { analyticsPush } from '~nanostring-brand-elements/components/analytics'

export const regionMenu = '/images/regions-menu.svg'
export const regionMenuCenterImg = '/images/regions-menu-centerimg.png'

interface Props {
    className?: string
    options?: StepNode[]
    availableOptions?: string[]
    onChange: (newValue: string) => void
}

const ChooseRegionInteractiveMenu: React.FC<Props> = ({
    className,
    onChange,
    options,
    availableOptions,
}) => {
    if (!availableOptions || !options) {
        return null
    }

    return (
        <motion.div
            sx={{
                position: 'relative',
                display: 'flex',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            className={className}
            {...fadeAnimations}
        >
            <svg
                sx={{
                    width: '100%',
                    height: '100%',
                }}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 2788 2068'
            >
                <motion.use
                    key='centeroutline'
                    href={`${regionMenu}#centeroutline`}
                />
                <motion.use key='centerimg' href={`${regionMenu}#centerimg`} />
                {options.map((option, i: number) => (
                    <OptionButton
                        key={i}
                        availableOptions={availableOptions}
                        option={option}
                        i={i}
                        onChange={onChange}
                    />
                ))}
            </svg>
        </motion.div>
    )
}
export default ChooseRegionInteractiveMenu

const OptionButton = (props) => {
    const { availableOptions, option, i, onChange } = props
    const optionId = option.id
    const isComingSoon = availableOptions.indexOf(optionId) === -1

    const { userInteract } = useContext(APIContext)

    const onClick = isComingSoon
        ? undefined
        : () => {
              analyticsPush({
                  eventSection: 'profileBuild',
                  event: 'roi',
                  eventValue: option.eventValue,
              })
              if (userInteract) {
                  userInteract('ChooseROIStep2', 'ROIButton', optionId)
              }
              onChange(optionId)
          }

    return (
        <a
            onClick={onClick}
            key={`btn-${i + 1}`}
            sx={{
                '&.disabled': {
                    pointerEvents: 'none',
                },
                '&:hover': {},
            }}
        >
            <motion.use
                key={`btnbg-${i + 1}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    duration: 0.5,
                    ease: 'easeIn',
                    delay: 0.5 + i * 0.3,
                }}
                href={`${regionMenu}#option${i + 1}bg`}
            />

            {isComingSoon ? (
                <motion.use
                    key={`rollover${i + 1}`}
                    href={`${regionMenu}#option${i + 1}comingsoon`}
                    sx={{
                        opacity: 0,
                        '&:hover': {
                            opacity: 1,
                        },
                        transition: 'all ease .3s',
                    }}
                />
            ) : (
                <motion.use
                    key={`btn${i}`}
                    href={`${regionMenu}#option${i + 1}rollover`}
                    sx={{
                        opacity: 0,
                        '&:hover': {
                            opacity: 1,
                        },
                        transition: 'all ease .3s',
                    }}
                />
            )}
        </a>
    )
}
