import { Step } from '/content/profile/types'

// Use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum ActionTypes {
    SET_STEP_INDEX = '@@profile/SET_STEP_INDEX',
    SET_IS_SHOWING_WORKFLOW = '@@profile/SET_IS_SHOWING_WORKFLOW',
    SET_STEP_AND_MAX_INDEX = '@@profile/SET_STEP_AND_MAX_INDEX',
    SET_STEP_SELECTION = '@@profile/SET_STEP_SELECTION',
    RESET = '@@profile/RESET',
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface State {
    readonly stepIndex: number
    readonly maxIndex: number
    readonly steps: Step[]
    readonly selections: string[]
    readonly isShowingWorkflow: boolean
}
