import type {
    ComponentType,
    CSSProperties,
    ForwardRefExoticComponent,
    ReactNode,
} from 'react'
import clsx from 'clsx'

import { Field, FieldProps, useField } from 'formik'

import FieldErrorMessage from './FieldErrorMessage'

interface Props<V = any> {
    id: string
    className?: string
    label: ReactNode
    withLabelColon?: boolean // true to add a colon to the end of the label, but not the placeholder.
    type: string
    autoComplete?: string
    isRequired?: boolean
    placeholder?: string
    errorMessage?: string
    style?: CSSProperties
    component?:
        | string
        | ComponentType<FieldProps<V>>
        | ComponentType
        | ForwardRefExoticComponent<any>
}

const LabeledInput = (props: Props) => {
    const {
        id,
        className,
        label,
        isRequired,
        withLabelColon,
        placeholder = label,
        errorMessage,
        style,
        ...rest
    } = props
    const [, meta] = useField(id)

    const isError = meta.error && meta.touched

    return (
        <div
            className={clsx(
                'f-col',
                'formField',
                className,
                isError ? 'isError' : undefined,
                isRequired ? 'isRequired' : undefined
            )}
            style={{
                ...style,
                marginBottom: '8px',
            }}
        >
            <label htmlFor={id}>
                {label}
                {withLabelColon ? ':' : ''}
            </label>
            <Field {...rest} id={id} placeholder={placeholder} name={id} />
            {errorMessage && (
                <FieldErrorMessage name={id}>{errorMessage}</FieldErrorMessage>
            )}
        </div>
    )
}
export default LabeledInput
