/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment, ReactNode } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { nodes } from '/content/profile'
import { StepNode } from '/content/profile/types'

import fadeAnimations from '~yipkos-common/animations/fade'

import ROIHeader from '../ROIHeader'
import ROIWorkflowHeader from '../ROIWorkflowHeader'

interface Props {
    className?: string
    stepIndex: number
    selections: string[]
    isShowingWorkflow: boolean
    stepper: ReactNode | null // Only used in portrait (otherwise ProfileBuilder places this on the left using fixed positioning).
}

const instructionsHeadline = 'Customize Your GeoMx Solution'

const step123Headline = () => (
    <motion.span>Build Your GeoMx Profile</motion.span>
)

const step1Subhead = () => (
    <motion.span {...fadeAnimations}>
        Choose your <span className='highlighted'>RNA</span> or{' '}
        <span className='highlighted'>Protein</span> Reagent
    </motion.span>
)

const step2Subhead = (analyte: string) => (
    <motion.span {...fadeAnimations}>
        Choose your <span className='highlighted'>{analyte}</span> Sample
    </motion.span>
)

export const step3Subhead = (analyte: string) => (
    <motion.span {...fadeAnimations}>
        Choose your <span className='highlighted'>{analyte}</span> ROI
    </motion.span>
)

const step5Headline = (analyte: string) => (
    <motion.span {...fadeAnimations}>
        Your <span className='highlighted'>{analyte}</span> Results
    </motion.span>
)

const step6Headline = (analyte: string) => (
    <motion.span>
        Your <span className='highlighted'>{analyte}</span> Case Study
    </motion.span>
)

const renderSubhead = (stepIndex: number, analyte: string | undefined) => {
    switch (stepIndex) {
        case 0:
            return step1Subhead()
        case 1:
            return step2Subhead(analyte || '')
        case 2:
            return step3Subhead(analyte || '')
        case 3:
        case 4:
        case 5:
        default:
            return null
    }
}

const renderHeadline = (
    stepIndex: number,
    analyte: string | undefined,
    roi: StepNode | undefined,
    isShowingWorkflow: boolean
) => {
    switch (stepIndex) {
        case -1:
            return instructionsHeadline
        case 0:
        case 1:
        case 2:
            return step123Headline()
        case 3:
            return isShowingWorkflow ? (
                <ROIWorkflowHeader analyte={analyte || ''} />
            ) : (
                <ROIHeader roi={roi} />
            )
        case 4:
            return step5Headline(analyte || '')
        case 5:
            return step6Headline(analyte || '')
        default:
            return null
    }
}

const ProfileBuilderHeader = (props: Props) => {
    const {
        className,
        stepIndex,
        selections,
        stepper,
        isShowingWorkflow,
    } = props
    const analyte = nodes.get(selections[0])
    const roi = selections.length > 2 ? nodes.get(selections[2]) : undefined
    const isPortrait = useIsPortrait()

    const headline = renderHeadline(
        stepIndex,
        analyte?.label,
        roi,
        isShowingWorkflow
    )
    const subhead = renderSubhead(stepIndex, analyte?.label)

    const isStep2Or3 = stepIndex === 1 || stepIndex === 2

    const subheadStep2 = isStep2Or3 ? (
        renderSubhead(1, analyte?.label)
    ) : (
        <Fragment />
    )
    const subheadStep3 =
        stepIndex === 2 ? renderSubhead(2, analyte?.label) : <Fragment />

    if (!headline && !subhead) {
        return null
    }

    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div
                className={className}
                sx={{
                    textAlign: 'center',
                    width: '100%',
                    flexDirection: 'column',
                    flex: '0 0 auto',
                    marginBottom: 12,
                }}
                {...fadeAnimations}
                transition={{ duration: 0.4 }}
            >
                <h2
                    sx={{
                        ml: stepIndex === 4 ? '16rem' : undefined,
                    }}
                >
                    {headline}
                </h2>
                {stepper && (
                    <div
                        sx={{
                            mt: '-2rem',
                            ml: stepIndex === 4 ? '16rem' : undefined,
                        }}
                    >
                        {stepper}
                    </div>
                )}
                {isStep2Or3 &&
                    (isPortrait ? (
                        <SubheadH4
                            sx={{
                                mt: [0, null, '20px', '50px'],
                            }}
                        >
                            <motion.span>Step 2: </motion.span>
                            {subheadStep2}
                        </SubheadH4>
                    ) : (
                        <div
                            className='f-row'
                            sx={{
                                width: 'calc(100% - 10rem)',
                                margin: '3ex 4rem 0 6rem',
                                justifyContent: 'space-between',
                            }}
                        >
                            <SubheadH4>
                                <motion.span>Step 2: </motion.span>
                                {subheadStep2}
                            </SubheadH4>
                            {stepIndex === 2 && (
                                <SubheadH4>
                                    <motion.span>Step 3: </motion.span>
                                    {subheadStep3}
                                </SubheadH4>
                            )}
                        </div>
                    ))}
                {!isStep2Or3 && subhead && (
                    <SubheadH4
                        sx={{
                            mt: [0, null, '20px', '50px'],
                        }}
                    >
                        {stepIndex !== 4 &&
                            stepIndex !== -1 &&
                            stepIndex !== 3 &&
                            stepIndex !== 5 && (
                                <motion.span>
                                    Step {stepIndex + 1}:{' '}
                                </motion.span>
                            )}
                        {subhead}
                    </SubheadH4>
                )}
            </motion.div>
        </AnimatePresence>
    )
}
export default ProfileBuilderHeader

export const SubheadH4 = (props) => {
    return (
        <motion.h4
            {...fadeAnimations}
            {...props}
            sx={{
                fontSize: [null, null, null, '2em'],
            }}
        />
    )
}
