/** @jsx jsx */
import { jsx, Flex, Button } from 'theme-ui'
import React, { Fragment, useContext, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import fadeAnimations from '~yipkos-common/animations/fade'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import CloseBtn from '~nanostring-brand-elements/components/CloseBtn'
import { analyticsPush } from '~nanostring-brand-elements/components/analytics'

import { Context as APIContext } from '/components/APIProvider'
import {
    initialValuesOther,
    initialValuesPersonalInfo,
    validationSchemaOther,
    validationSchemaPersonalInfo,
    ValuesOther,
    ValuesPersonalInfo,
} from '/components/forms/FieldDefinitions'
import { readoutPlatformOther } from '/components/forms/ReadoutPlatformRadioButtonGroup'

import FormPage1 from './FormPage1'
import FormPage2 from './FormPage2'
import ThankYouDialog from './ThankYouDialog'

export interface Props {
    className?: string
    isSubForm?: boolean
}

const validationSchema = Yup.object().shape({
    ...validationSchemaPersonalInfo,
    ...validationSchemaOther,
})

const validationSchemaPage1 = Yup.object().shape({
    ...validationSchemaPersonalInfo,
})

const initialValues = {
    ...initialValuesPersonalInfo,
    ...initialValuesOther,
}
Object.freeze(initialValues)

interface FormValues extends ValuesPersonalInfo, ValuesOther {}

const ScheduleConsultForm: React.FC<Props> = ({
    className,
    isSubForm,
}): React.ReactElement => {
    const isPortrait = useIsPortrait()
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [page2IsOpen, setPage2IsOpen] = useState(false)
    const { submitDemoForm, state } = useContext(APIContext)
    const populatedValues: FormValues = {
        ...initialValues,
    }
    const location = useLocation()
    const { pathname } = location

    let eventAction = ''
    switch (pathname) {
        case '/profile':
            eventAction = 'scheduleConsultationProfile'
            break
        case '/analyze':
            eventAction = 'scheduleConsultationAnalyze'
            break
        case '/demo':
            eventAction = 'scheduleConsultationFull'
            break
        default:
            break
    }
    const onSubmit = async (data, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        analyticsPush({
            eventSection: 'consultation',
            event: eventAction,
            eventValue: 'submit',
        })

        // Move readoutPlatformTextfield to readoutPlatform if readoutPlatform
        // is set to "Other".
        if (data.readoutPlatform === readoutPlatformOther) {
            data.readoutPlatform =
                data.readoutPlatformTextfield || readoutPlatformOther
            delete data.readoutPlatformTextfield
        }

        await submitDemoForm({
            ...data,
            attendeeID: state.attendeeID,
        })

        setSubmitting(false)

        setPage2IsOpen(false)
        setFormSubmitted(true)
        resetForm()
    }

    return (
        <Fragment>
            <motion.div
                className={className}
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    width: isSubForm ? '100%' : '80%',
                    input: isSubForm
                        ? {
                              p: '8px 10px',
                              borderRadius: '4px',
                              maxWidth: isPortrait ? undefined : '194px',
                              fontSize: '13px',
                              mx: '8px',
                          }
                        : {},
                }}
                {...fadeAnimations}
            >
                {!isSubForm && <FormHeading />}
                <Formik
                    initialValues={populatedValues}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                    validateOnMount={false}
                    validateOnChange={true}
                    validationSchema={validationSchema}
                >
                    {(formikProps) => (
                        <FormContent
                            formikProps={formikProps}
                            isSubForm={isSubForm}
                            page2IsOpen={page2IsOpen}
                            setPage2IsOpen={setPage2IsOpen}
                            eventAction={eventAction}
                        />
                    )}
                </Formik>
            </motion.div>
            <ThankYouDialog
                isOpen={formSubmitted}
                onClose={() => setFormSubmitted(false)}
            />
        </Fragment>
    )
}

export default ScheduleConsultForm

const FormContent = (props) => {
    const {
        formikProps,
        isSubForm,
        page2IsOpen,
        setPage2IsOpen,
        eventAction,
    } = props
    const { isSubmitting } = formikProps
    const isPortrait = useIsPortrait()

    const onClickGoToPage2 = (ev) => {
        ev.preventDefault()
        if (validationSchemaPage1.isValidSync(formikProps.values)) {
            analyticsPush({
                eventSection: 'consultation',
                event: eventAction,
                eventValue: 'partial',
            })
            formikProps.setErrors({})
            setPage2IsOpen(true)
        } else {
            // Trigger the errors to show up.
            formikProps.handleSubmit()
        }
    }

    return (
        <Form
            sx={{
                position: 'relative',
                backgroundColor: 'mutedBackground',
                overflowY: 'auto',
                height: isPortrait ? undefined : '100%',
                padding: isPortrait
                    ? isSubForm
                        ? '0 1rem 1ex 1rem'
                        : '1ex 1rem 3ex 1rem'
                    : undefined,
            }}
        >
            {isSubForm && <FormHeading isSubForm />}
            {!isSubForm && <Disclaimer />}
            <Flex
                sx={{
                    flexDirection: isPortrait ? 'column' : 'row',
                    width: '97%',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}
            >
                <Flex
                    sx={{
                        flexBasis: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <FormPage1
                        isSubForm={isSubForm}
                        sx={{
                            margin: isSubForm
                                ? '0 0 1ex 0'
                                : isPortrait
                                ? '2ex 2rem'
                                : '2ex 2rem 1ex 4rem',
                            '& label': {
                                opacity: isSubForm ? '0' : undefined,
                                lineHeight: '13px',
                                fontWeight: '700',
                                whiteSpace: isSubForm ? 'nowrap' : undefined,
                            },
                        }}
                    />
                    {!isSubForm && (
                        <FormPage2
                            sx={{
                                margin: '0 2rem 0 4rem',
                            }}
                        />
                    )}
                </Flex>
                {!isSubForm && (
                    <Flex
                        sx={{
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            m: '1rem',
                        }}
                    >
                        <Button
                            variant='primary'
                            type='submit'
                            disabled={isSubmitting}
                            sx={{
                                fontSize: '1.5rem',
                            }}
                        >
                            Schedule Consultation
                        </Button>
                    </Flex>
                )}
                {isSubForm && <SubFormButton onClick={onClickGoToPage2} />}
            </Flex>
            <AnimatePresence>
                {isSubForm && page2IsOpen && (
                    <motion.div
                        {...fadeAnimations}
                        sx={{
                            backgroundColor: 'mutedBackground',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            padding: isPortrait
                                ? '3ex 1rem 3ex 1rem'
                                : '2ex 0.5rem 0 0.5rem',
                            '.formField-radioButton.hasOtherField': {
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '100%',
                                'input[type=text]': {
                                    width: 'calc(100% - 7.7rem)',
                                    m: '0 0 0 2rem',
                                },
                            },
                        }}
                    >
                        <CloseBtn
                            sx={{
                                position: 'absolute',
                                top: '.5rem',
                                right: '.5rem',
                            }}
                            onClick={(ev) => {
                                ev.preventDefault()
                                setPage2IsOpen(false)
                            }}
                        />
                        <FormPage2
                            {...formikProps}
                            isSubPage
                            sx={{
                                width: '100%',
                                label: {
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                },
                                'div[role=group] label': {
                                    fontSize: '10px',
                                    lineHeight: '24px',
                                },
                            }}
                        />
                        <div className='f-gs-auto' sx={{ lineHeight: 0 }}>
                            &nbsp;
                        </div>
                        <SubFormButton type='submit' />
                    </motion.div>
                )}
            </AnimatePresence>
        </Form>
    )
}

const FormHeading = (props) => {
    const { isSubForm } = props
    const isPortrait = useIsPortrait()

    return (
        <h2
            sx={{
                fontSize: isPortrait
                    ? isSubForm
                        ? ['18px', null, null, '24px']
                        : '45px'
                    : isSubForm
                    ? '18px'
                    : '24px',
                lineHeight: !isPortrait && isSubForm ? '18px' : undefined,
                textAlign: 'center',
                m: isSubForm
                    ? ['10px 0 0 0', null, null, '10px 8px 0 8px']
                    : isPortrait
                    ? '4ex 0 3ex 0'
                    : '0 0 1ex 0',
            }}
        >
            Schedule a <span className='highlighted'>Live</span> Software Demo
        </h2>
    )
}

const SubFormButton = (props) => {
    const isPortrait = useIsPortrait()
    return (
        <Button
            variant='primary'
            {...props}
            sx={{
                p: '8px !important',
                m: isPortrait ? '8px auto !important' : '8px !important',
                fontSize: '14px',
                width: isPortrait ? 'fit-content' : '100%',
            }}
        >
            Schedule Consultation
        </Button>
    )
}

const Disclaimer = (props) => {
    const { className } = props
    const isPortrait = useIsPortrait()
    return (
        <div
            className={className}
            sx={{
                margin: isPortrait ? '1rem 0 0 2rem' : '1.5rem 0 0 4rem',
                color: 'black',
                fontSize: '0.9rem',
                fontWeight: 700,
            }}
        >
            *All Fields Required
        </div>
    )
}
