/** @jsx jsx */
import { jsx } from 'theme-ui'

const BgImg = (props) => {
    const { className, src } = props
    return (
        <img
            src={src}
            className={className}
            alt=''
            sx={{
                position: 'absolute',
                zIndex: 1,
                objectFit: 'cover',
                opacity: 0.3,
            }}
        />
    )
}
export default BgImg
