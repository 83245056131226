/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ReactNode } from 'react'

import theme from '../theme'

import BgImg from './BgImg'

import bgTr from '../assets/images/backgrounds/bg-tr.png'
import bgBl from '../assets/images/backgrounds/bg-bl.png'
import bgBr from '../assets/images/backgrounds/bg-br.png'

interface Props {
    children: ReactNode
}

const LayoutFrame = (props: Props) => {
    const { children } = props

    return (
        <div
            className='f-col'
            sx={{
                position: 'relative',
                backgroundColor: 'background',
                backgroundPosition: '0 0',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <GradientSpot
                sx={{
                    left: 0,
                    top: 0,
                    transform: 'translate(-50%, -50%)',
                }}
            />
            <GradientSpot
                sx={{
                    left: '70%',
                    top: 0,
                    transform: 'translate(-50%, -50%)',
                }}
            />
            <GradientSpot
                sx={{
                    left: 0,
                    top: '85%',
                    transform: 'translate(-40%, -50%)',
                }}
            />
            <BgImg
                src={bgTr}
                sx={{
                    top: 0,
                    right: 0,
                    width: '84%',
                    height: 'auto',
                    transform: 'translate(50%, -30%)',
                }}
            />
            <BgImg
                src={bgBl}
                sx={{
                    bottom: 0,
                    left: 0,
                    width: '81.5%',
                    height: 'auto',
                    transform: 'translate(-30%, 30%)',
                }}
            />
            <BgImg
                src={bgBr}
                sx={{
                    bottom: 0,
                    right: 0,
                    width: '71.5%',
                    height: 'auto',
                    transform: 'translate(40%, 60%)',
                }}
            />

            {children}
        </div>
    )
}
export default LayoutFrame

const GradientSpot = (props) => {
    const { className } = props
    return (
        <div
            className={className}
            sx={{
                position: 'absolute',
                backgroundColor: 'transparent',
                background: `radial-gradient(100% 100%,${theme.colors.backgroundGradient} 10%, transparent 50%)`,
                backgroundPosition: '0 0',
                width: '100vw',
                height: '100vw',
            }}
        />
    )
}
