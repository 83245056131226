export interface BodyScrollOptions {
    reserveScrollBarGap?: boolean
    allowTouchMove?: (el: any) => boolean
}

interface Lock {
    targetId: string
    options: BodyScrollOptions
}

let locks: Array<Lock> = []
let previousBodyOverflowSetting
let previousBodyPaddingRight

const setOverflowHidden = (options?: BodyScrollOptions) => {
    if (previousBodyPaddingRight === undefined) {
        const reserveScrollBarGap =
            !!options && options.reserveScrollBarGap === true
        const scrollBarGap =
            window.innerWidth - document.documentElement.clientWidth

        if (reserveScrollBarGap && scrollBarGap > 0) {
            previousBodyPaddingRight = document.body.style.paddingRight
            document.body.style.paddingRight = `${scrollBarGap}px`
        }
    }

    if (previousBodyOverflowSetting === undefined) {
        previousBodyOverflowSetting = document.body.style.overflow
        document.body.style.overflow = 'hidden'
    }
}

const restoreOverflowSetting = () => {
    if (previousBodyPaddingRight !== undefined) {
        document.body.style.paddingRight = previousBodyPaddingRight
        previousBodyPaddingRight = undefined
    }

    if (previousBodyOverflowSetting !== undefined) {
        document.body.style.overflow = previousBodyOverflowSetting
        previousBodyOverflowSetting = undefined
    }
}

export const disableBodyScroll = (
    targetId: string,
    options?: BodyScrollOptions
): void => {
    setOverflowHidden(options)
    const lock = {
        targetId,
        options: options || {},
    }

    locks = [...locks, lock]
}

export const enableBodyScroll = (targetId: string) => {
    locks = locks.filter((lock) => lock.targetId !== targetId)
    if (!locks.length) {
        restoreOverflowSetting()
    }
}
