/** @jsx jsx */
import { jsx } from 'theme-ui'

import { motion } from 'framer-motion'
import fadeAnimations from '~yipkos-common/animations/fade'
import { Step3 } from '/content/profile/steps'

const regionMenu = '/images/regions-menu.svg'

const ChooseRegionGraphic = (props) => {
    const { className } = props

    return (
        <motion.div
            sx={{
                position: 'relative',
                display: 'flex',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            className={className}
            {...fadeAnimations}
        >
            <svg
                sx={{
                    width: '100%',
                    height: '100%',
                    '& path.option-text': {
                        display: 'none',
                    },
                }}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 2788 2068'
            >
                <motion.use
                    key='centeroutline'
                    href={`${regionMenu}#centeroutline`}
                />
                <motion.use key='centerimg' href={`${regionMenu}#centerimg`} />
                {Step3.options.map((_, i: number) => (
                    <OptionButton key={i} i={i} />
                ))}
            </svg>
        </motion.div>
    )
}
export default ChooseRegionGraphic

const OptionButton = (props) => {
    const { i } = props

    return (
        <motion.use
            key={`btnbg-${i + 1}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.5,
                ease: 'easeIn',
                delay: 0.5 + i * 0.3,
            }}
            href={`${regionMenu}#option${i + 1}bgimg`}
        />
    )
}
