import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { useField } from 'formik'

export interface Props {
    name: string
    className?: string
    children: ReactNode
}

const FieldErrorMessage = (props: Props) => {
    const { name, className, children } = props
    const [, meta] = useField(name)

    const isError = meta.error && meta.touched

    return (
        <div
            className={clsx(
                'formErrorMessage',
                className,
                isError && 'isError'
            )}
        >
            {children}
        </div>
    )
}
export default FieldErrorMessage
