/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { default as HeaderBase } from '~nanostring-brand-elements/components/Header'

import { reset } from '/store/profile'
import { zIndexHeader } from '/z-indexes'

import geoMxLogo from '~nanostring-brand-elements/assets/images/logos/GeoMx-DSP-logo.png'

interface Props {
    className?: string
    height: number
}

const Header = (props: Props) => {
    const { className, height } = props
    const dispatch = useDispatch()
    const location = useLocation()
    const pathname = location.pathname
    const isProfile = pathname.startsWith('/profile')

    return (
        <HeaderBase
            className={className}
            height={height}
            logoAlt='GeoMx(R) Digital Spatial Profiler'
            logoSrc={geoMxLogo}
            sx={{
                width: '100%',
                height: height,
                flex: `0 0 ${height}px`,
                zIndex: zIndexHeader,
            }}
            onClickLogo={
                isProfile
                    ? () => {
                          dispatch(reset())
                      }
                    : undefined
            }
        />
    )
}
export default Header
