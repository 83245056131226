/** @jsx jsx */
import { jsx } from 'theme-ui'

import NSTGRadioButtonGroup from '/components/forms/NSTGRadioButtonGroup'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

interface Props {
    label: string
    isSubPage?: boolean
}

const ResearchAreaRadioButtonGroup = (props: Props) => {
    const fieldName = 'researchArea'
    const isPortrait = useIsPortrait()
    return (
        <NSTGRadioButtonGroup
            {...props}
            name={fieldName}
            options={[
                {
                    id: 'immunology',
                    buttonProps: {
                        label: 'Immunology',
                    },
                },
                {
                    id: 'oncology',
                    buttonProps: {
                        label: 'Oncology',
                    },
                },
                {
                    id: 'neuroscience',
                    buttonProps: {
                        label: 'Neuroscience',
                    },
                },
                {
                    id: 'infectious-disease',
                    buttonProps: {
                        label: 'Infectious Disease',
                    },
                },
            ]}
            sx={{
                minWidth: '22ch',
                ml: isPortrait ? undefined : '14px',
            }}
        />
    )
}
export default ResearchAreaRadioButtonGroup
