/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import animation from './assets/analyze-data-slides.svg'

const layersBySlideIdx = [
    ['path-plus', 'path-equals'],
    ['path-biologically-directed', 'path-region-of-interest'],
    ['path-expansive', 'path-analysis', 'path-expansive-and'],
    ['path-faster', 'path-time-to'],
]

const AnalyzeDataAnimation = () => {
    const [slide, setSlide] = useState(0)

    useEffect(() => {
        const timer = setTimeout(() => {
            const nextSlide = slide + 1
            if (nextSlide >= layersBySlideIdx.length)
                return (): void => clearTimeout(timer)
            setSlide(nextSlide)
            return () => clearTimeout(timer)
        }, 1000)
        return () => clearTimeout(timer)
    }, [slide])

    const selectedSlides: string[] = []
    for (let i = 0; i <= slide; i++) {
        selectedSlides.push(...layersBySlideIdx[i])
    }
    const isPortrait = useIsPortrait()
    return (
        <svg
            sx={{
                width: isPortrait ? ['75%', null, null, '100%'] : '100%',
                height: '100%',
            }}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='80 170 1650 250'
        >
            <AnimatePresence>
                {selectedSlides.map((slide) => (
                    <motion.use
                        key={`slide${slide}`}
                        exit={{ opacity: 0 }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, ease: 'easeIn' }}
                        href={`${animation}#${slide}`}
                    />
                ))}
            </AnimatePresence>
        </svg>
    )
}

export default AnalyzeDataAnimation
