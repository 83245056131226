/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ReactNode } from 'react'
import { useIsPortrait } from '~yipkos-common/utils/mediaQueries'

import { analyticsPush, eventButtonClick } from './analytics'
import NavLink from './NavLink'

interface Props {
    className?: string
    label: ReactNode
    path: string
    isActive: boolean
    eventAction: string
    onClick?: () => void
}

const FooterNavButton = (props: Props) => {
    const { className, label, path, isActive, eventAction, onClick } = props
    const isPortrait = useIsPortrait()
    return (
        <li
            className={className}
            sx={{
                margin: 0,
                padding: 0,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                lineHeight: '1',
            }}
        >
            <NavLink
                href={path}
                onClick={() => {
                    analyticsPush({
                        eventSection: 'global',
                        event: eventAction,
                        eventValue: eventButtonClick,
                    })
                    if (onClick) {
                        onClick()
                    }
                }}
                sx={{
                    width: isPortrait ? '90%' : '100%',
                    height: '100%',
                    px: ['15px', null, null, null, '20px'],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: '500',
                    fontSize: ['16px', null, null, null, '24px'],
                    textDecoration: 'none',
                    color: 'white',
                    backgroundColor: 'transparent',
                    transition: 'all .2s ease',
                    '&.active': { color: 'primary', backgroundColor: 'white' },
                    '&:hover': {
                        color: 'primary',
                        backgroundColor: 'white',
                        '@media (hover: none)': {
                            backgroundColor: 'transparent',
                            color: 'white',
                            '&.active': {
                                color: 'primary',
                                backgroundColor: 'white',
                            },
                        },
                    },
                }}
                className={isActive ? 'active' : undefined}
            >
                {label}
            </NavLink>
        </li>
    )
}
export default FooterNavButton
